import React, { useEffect, useState, useCallback } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "./firebaseConfig";
import HomePage from "./pages/HomePage";
import MainPage from "./pages/MainPage";
import Register from "./Register";
import Login from "./Login";
import SettingsPage from "./pages/SettingsPage";
import AdminPage from "./pages/AdminPage";
import ProfilePage from "./pages/ProfilePage";
import "./App.css";
import { doc, getDoc } from "firebase/firestore";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { isAdminState, handleLogoutState } from "./state/appState";
import DropdownMenu from "./grid-components/DropDownMenu";
import GridLayout from "./grid-components/GridLayout";
import AdminIssueReports from "./db/admin/AdminIssueReports";
import ReportIssueFormModal from "./grid-components/ReportIssueFormModal";
import { WeatherProvider } from "./widgets/WeatherContext";
import UrlCollector from './components/UrlCollector';
import { useUrlCollector } from './hooks/useUrlCollector';
import AddItemForm from './grid-components/AddItemForm';
import BuildInfo from './components/BuildInfo';
import updateTimeTrackingWidget from './db/updateTimeTrackingWidget';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isWeatherWidgetInstalled, setIsWeatherWidgetInstalled] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const setIsAdmin = useSetRecoilState(isAdminState);
  const setHandleLogout = useSetRecoilState(handleLogoutState);
  const isAdmin = useRecoilValue(isAdminState);
  const [showReportIssueModal, setShowReportIssueModal] = useState(false);

  const toggleReportIssueModal = () => {
    setShowReportIssueModal((prev) => !prev);
  };

  // Fetch user role and check if the weather widget is installed
  const fetchRoleAndWidgetStatus = useCallback(
    async (currentUser) => {
      try {
        if (currentUser) {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsAdmin(userData.role === "admin");
            setIsWeatherWidgetInstalled(userData.isWeatherWidgetInstalled || false);
          }
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error fetching role or widget status:", error);
      }
    },
    [setIsAdmin]
  );

  // Listen for auth state changes and fetch user data
  useEffect(() => {
    let isSubscribed = true;
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        if (!isSubscribed) return;
        
        if (currentUser) {
          // Only update state if the component is still mounted
          setUser(currentUser);
          await fetchRoleAndWidgetStatus(currentUser);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Auth state change error:', error);
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    });

    // Cleanup function
    return () => {
      isSubscribed = false;
      unsubscribe();
    };
  }, [fetchRoleAndWidgetStatus]);

  const handleLogout = useCallback(async () => {
    try {
      setLoading(true);
      
      // First sign out from Firebase without any state changes
      await signOut(auth).catch(error => {
        console.error('Firebase signOut error:', error);
        // Continue with cleanup even if Firebase signOut fails
      });

      // Then do local cleanup
      setUser(null);
      setIsAdmin(false);
      
      // Finally navigate
      navigate("/");
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setLoading(false);
    }
  }, [navigate, setIsAdmin]);

  useEffect(() => {
    setHandleLogout(() => handleLogout);
  }, [setHandleLogout, handleLogout]);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await updateTimeTrackingWidget();
      } catch (error) {
        console.error('Error updating Time Tracking widget:', error);
      }
    };
    initializeApp();
  }, []);

  // Add URL collector hook
  const { showAddItemForm, setShowAddItemForm, initialUrl } = useUrlCollector();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <WeatherProvider>
      <div className="App">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <UrlCollector />
            <DropdownMenu
              currentPath={location.pathname}
              toggleReportIssueModal={toggleReportIssueModal}
            />

            {showReportIssueModal && (
              <ReportIssueFormModal
                onClose={toggleReportIssueModal}
                isOpen={showReportIssueModal}
              />
            )}
            <BuildInfo />
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/settings" element={user ? <SettingsPage /> : <Navigate to="/login" />} />
              <Route path="/profile" element={user ? <ProfilePage /> : <Navigate to="/login" />} />
              <Route
                path="/grid"
                element={
                  user ? (
                    <GridLayout user={user} isAdmin={isAdmin} handleLogout={handleLogout} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/admin"
                element={user && isAdmin ? <AdminPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/issue-reports"
                element={isAdmin ? <AdminIssueReports /> : <Navigate to="/" />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </>
        )}
      </div>
    </WeatherProvider>
  );
};

export default App;
// change to trigger build...
