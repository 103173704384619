import React from "react";

const WidgetTable = ({ items, handleChange, handleDelete }) => {
  return (
    <div className="desktop-widgets-list">
      <div className="flexRow">
        <h4>Widgets</h4>
      </div>

      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>Title</th>
            <th>Description</th>
            <th>Activation Code</th>
            <th>Icon Code</th>
            <th>All Grids</th>
            <th>Grid X</th>
            <th>Grid Y</th>
            <th>Screen X</th>
            <th>Screen Y</th>
            <th>Main ID</th>
            <th>Common ID</th>
            <th>Folder ID</th>
            <th>State</th>
            <th>Visible</th>
            <th>Widget Visible</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>
                <p className="id">{item.id}</p>
              </td>
              <td>
                <input type="text" style={{ width: "100px" }} value={item.title || ""} onChange={(e) => handleChange(item.id, "title", e.target.value)} />
              </td>
              <td>
                <input
                  type="text"
                  style={{ width: "200px" }}
                  value={item.description || ""}
                  onChange={(e) => handleChange(item.id, "description", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={{ width: "150px" }}
                  value={item.activationCode || ""}
                  onChange={(e) => handleChange(item.id, "activationCode", e.target.value)}
                />
              </td>
              <td>
                <input type="text" style={{ width: "150px" }} value={item.iconCode || ""} onChange={(e) => handleChange(item.id, "iconCode", e.target.value)} />
              </td>
              <td>
                <input type="checkbox" checked={item.allgrids} onChange={(e) => handleChange(item.id, "allgrids", e.target.checked)} />
              </td>
              <td>
                <input type="number" value={item.gridX || 0} style={{ width: "40px" }} onChange={(e) => handleChange(item.id, "gridX", e.target.value)} />
              </td>
              <td>
                <input type="number" value={item.gridY || 0} style={{ width: "40px" }} onChange={(e) => handleChange(item.id, "gridY", e.target.value)} />
              </td>
              <td>
                <input type="number" value={item.screenX || 0} style={{ width: "80px" }} onChange={(e) => handleChange(item.id, "screenX", e.target.value)} />
              </td>
              <td>
                <input type="number" value={item.screenY || 0} style={{ width: "80px" }} onChange={(e) => handleChange(item.id, "screenY", e.target.value)} />
              </td>
              <td>
                <input
                  type="number"
                  value={item.main_id || 0}
                  style={{ width: "40px", backgroundColor: "lightblue" }}
                  onChange={(e) => handleChange(item.id, "main_id", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={item.common_id || 0}
                  style={{ width: "50px", backgroundColor: "lightgreen" }}
                  onChange={(e) => handleChange(item.id, "common_id", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={item.folder_id || 0}
                  style={{ width: "20px" }}
                  onChange={(e) => handleChange(item.id, "folder_id", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={{ width: "80px", fontSize: "12px" }}
                  value={item.state || "active"}
                  onChange={(e) => handleChange(item.id, "state", e.target.value)}
                />
              </td>
              <td>
                <select value={item.visible} onChange={(e) => handleChange(item.id, "visible", e.target.value === "true")}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </td>
              <td>
                <select value={item.widgetVisible} onChange={(e) => handleChange(item.id, "widgetVisible", e.target.value === "true")}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </td>
              <td>
                <input type="text" value={item.type || "widget"} style={{ width: "50px" }} onChange={(e) => handleChange(item.id, "type", e.target.value)} />
              </td>
              <td>
                <button className="delete-button" onClick={() => handleDelete(item.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WidgetTable;
