import React from "react";
import default_folder_icon from "../images/app-buttons/folder.svg";
import default_button_icon from "../images/app-buttons/upload.gif";
import default_widget_icon from "../images/app-buttons/widget-icon.svg";

const FolderItem = ({ item, folderItems, handleIconClick }) => {
  const isEmpty = folderItems.length === 0;

  const renderFolderItem = (folderItem) => {
    // console.log('Rendering folder item:', {
    //   iconOption: folderItem.iconOption,
    //   backgroundColor: folderItem.backgroundColor,
    //   badgeText: folderItem.badgeText,
    //   icon: folderItem.icon,
    //   type: folderItem.type
    // });

    // First check if this is a text badge item
    if (folderItem.iconOption === "textBadge") {
      // console.log('Rendering as text badge');
      return (
        <div
          style={{
            backgroundColor: folderItem.backgroundColor || "#FFFFFF",
            color: folderItem.iconColor || "#000000",
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            fontSize: "10px",
            fontWeight: "bold"
          }}
          className="folder-small-item"
        >
          {folderItem.icon ? folderItem.icon.charAt(0) : ""}
        </div>
      );
    }
    
    // If not a text badge, check if it's a special type
    if (folderItem.type === "folder") {
      return <img src={default_folder_icon} alt={folderItem.title} className="folder-small-item" />;
    }
    
    if (folderItem.type === "widget") {
      return <img src={default_widget_icon} alt={folderItem.title} className="folder-small-item" />;
    }
    
    // For regular items with an icon
    if (folderItem.icon) {
      return <img src={folderItem.icon} alt={folderItem.title} className="folder-small-item" />;
    }
    
    // Default fallback
    return <img src={default_button_icon} alt={folderItem.title} className="folder-small-item" />;
  };

  return (
    <div className={`folder-item-container ${isEmpty ? "empty" : "not-empty"}`}>
      <div className="folder-item-content">
        {folderItems.length > 0 ? (
          folderItems.map((folderItem, index) => (
            <React.Fragment key={index}>
              {renderFolderItem(folderItem)}
            </React.Fragment>
          ))
        ) : (
          <img src={default_folder_icon} alt={item.title} className="empty-folder-icon" />
        )}
      </div>
      <div className="folder-item-title">{item.title}</div>
    </div>
  );
};

export default FolderItem;
// changing somthing so i can upload to vercel

