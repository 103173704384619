import React from "react";
import "../css/WidgetContainer.css";
import closeButton from "../images/app-buttons/close.png";

const DraggableCloseableHeader = ({ onMouseDown, onClose, title }) => {
  return (
    <div className="widget-header" onMouseDown={onMouseDown}>
      <div className="drag-handle">☰</div>
      <div className="title">{title}</div>
      <div className="controls">
        <button type="button" onClick={onClose} className="btn-close">
          <img src={closeButton} alt="Close" />
        </button>
      </div>
    </div>
  );
};

export default DraggableCloseableHeader;
