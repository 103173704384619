import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import GridLayout from "../grid-components/GridLayout";
import db from "../db/db";
import { backgroundImageUrlState, imageBlurState, imageFadeState, backgroundSourceState } from "../state/state";
import { authState, userState } from "../state/appState";
import RandomImage from "../grid-components/RandomImage";
import chatIcon from "../images/app-buttons/chat-icon.png";
import "../css/MainPage.css";

const MainPage = () => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useRecoilState(backgroundImageUrlState);
  const blur = useRecoilValue(imageBlurState);
  const fade = useRecoilValue(imageFadeState);
  const backgroundSource = useRecoilValue(backgroundSourceState);
  const [, setReceiverId] = useState("");
  const [, setIsChatVisible] = useState(false);
  const [auth] = useRecoilState(authState);
  const [user] = useRecoilState(userState);
  const [loading, setLoading] = useState(true);

  // BroadcastChannel setup - only create when needed for sync
  useEffect(() => {
    let channel;
    
    const handleSync = async () => {
      // Only create channel if we have pending changes to sync
      const pendingChanges = await db.syncQueue.count();
      if (pendingChanges > 0) {
        channel = new BroadcastChannel("myhomepage-channel");
        
        channel.onmessage = (event) => {
          // Only handle specific sync events
          if (event.data.type === "manual-sync" && event.data.data?.hasChanges) {
            console.log("Processing sync data:", event.data.data);
          }
        };
      }
    };

    // Only set up channel if user is logged in
    if (auth && user) {
      handleSync();
    }

    return () => {
      if (channel) {
        channel.close();
      }
    };
  }, [auth, user]);

  // Load background image only when needed
  useEffect(() => {
    const loadBackgroundImage = async () => {
      setLoading(true);
      try {
        // Only load if we don't already have one
        if (!backgroundImageUrl) {
          const storedUrl = await db.settings.get('backgroundImageUrl');
          if (storedUrl) {
            setBackgroundImageUrl(storedUrl);
          }
        }
      } catch (error) {
        console.error('Error loading background:', error);
      } finally {
        setLoading(false);
      }
    };

    loadBackgroundImage();
  }, []);

  useEffect(() => {
    const fetchBackgroundImage = async () => {
      try {
        const imageUrl = await db.backgroundImageSelections.getBackgroundImageUrl();
        setBackgroundImageUrl(imageUrl || "/static/media/bg-artboard-18.7e4b626297accfcc0981.jpg");
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    if (backgroundSource === "standard") {
      fetchBackgroundImage();
    } else {
      setLoading(false);
    }
  }, [setBackgroundImageUrl, backgroundSource]);

  const startChatWithUser = (userId) => {
    setReceiverId(userId);
    setIsChatVisible(true);
  };

  if (auth?.loading || loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {backgroundSource === "unsplash" ? (
        <RandomImage />
      ) : (
        <div
          className="main-background-container"
          style={{
            backgroundImage: `url(${backgroundImageUrl || "/static/media/bg-artboard-18.7e4b626297accfcc0981.jpg"})`, // Fallback to default
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: `blur(${blur}px)`,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            position: "fixed",
            zIndex: -1
          }}
        />
      )}
      <div
        className="background-overlay"
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          opacity: fade,
          top: 0,
          left: 0,
          zIndex: 0,
          backgroundColor: "rgba(255, 255, 255, 0.5)"
        }}
      />

      <GridLayout user={user} />

      {auth?.user && (
        <>
          <button className="btn-chat" onClick={() => startChatWithUser("otherUserId")}>
            <img src={chatIcon} alt="Chat" className="chat-icon" />
          </button>

          {/* <MovableChat receiverId={receiverId} isVisible={isChatVisible} onClose={toggleChat} /> */}
        </>
      )}
    </>
  );
};

export default MainPage;
