import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db as firebaseDB } from "../firebaseConfig";
import db from "../db/db"; // Import the IndexedDB instance
import ButtonTable from "../db/admin/ButtonTable";
import WidgetTable from "../db/admin/WidgetTable";

import DownloadCSVButton from "../db/admin/DownloadCSVButton";
import UserTable from "../db/firebaseAdmin/UserTable";
import ItemTable from "../db/firebaseAdmin/ItemTable";
import ManageWidgets from "../db/firebaseAdmin/ManageWidgets";
import ManageButtons from "../db/firebaseAdmin/ManageButtons";
import CSVUploadItems from "../db/admin/CSVUploadItems";
import "../css/AdminPage.css";

const AdminPage = () => {
  const [buttonItems, setButtonItems] = useState([]);
  const [widgetItems, setWidgetItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [tempItem, setTempItem] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const buttonItemsList = await db.items.where("type").equals("button").toArray();
        const widgetItemsList = await db.items.where("type").equals("widget").toArray();

        setButtonItems(buttonItemsList);
        setWidgetItems(widgetItemsList);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Add new button item
  const handleAddButton = () => {
    const newButton = { id: Date.now(), title: "", type: "button" };
    setButtonItems([...buttonItems, newButton]);
    setEditingItem(newButton.id);
    setTempItem(newButton);
  };

  // Add new widget item
  const handleAddWidget = () => {
    const newWidget = { id: Date.now(), title: "", type: "widget" };
    setWidgetItems([...widgetItems, newWidget]);
    setEditingItem(newWidget.id);
    setTempItem(newWidget);
  };

  // Edit an item
  const handleEditClick = (item) => {
    setEditingItem(item.id);
    setTempItem(item);
  };

  // Handle field changes
  const handleFieldChange = (field, value) => {
    setTempItem({ ...tempItem, [field]: value });
  };

  // Save changes to an item
  const handleSaveChanges = async () => {
    const updatedItems = buttonItems.map((item) => (item.id === tempItem.id ? tempItem : item));
    setButtonItems(updatedItems);
    setEditingItem(null);
    // Optionally, update the database here
    await db.items.put(tempItem);
  };

  // Delete an item
  const handleDeleteItem = async (id) => {
    setButtonItems(buttonItems.filter((item) => item.id !== id));
    await db.items.delete(id);
  };

  // Update item in Dexie
  const handleUpdateDexie = async (id) => {
    const item = buttonItems.find((item) => item.id === id);
    if (item) {
      await db.items.put(item);
    }
  };

  // Add item to Dexie
  const handleAddToDexie = async (id) => {
    const item = buttonItems.find((item) => item.id === id);
    if (item) {
      await db.items.add(item);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="admin-page" style={{ paddingTop: "20px" }}>
      <div className="admin-header">
        <h2>Admin - Manage Users</h2>
      </div>
      <hr />

      <div className="user-table-container" style={{ marginTop: "20px" }}>
        <UserTable />
      </div>

      <hr />

      <div style={{ width: "100%", marginTop: "0px" }}>
        <ButtonTable
          items={buttonItems}
          handleAddButton={handleAddButton}
          handleFieldChange={handleFieldChange}
          handleDeleteItem={handleDeleteItem}
          handleUpdateDexie={handleUpdateDexie}
          handleAddToDexie={handleAddToDexie}
          handleEditClick={handleEditClick}
          handleSaveChanges={handleSaveChanges}
          editingItem={editingItem}
          tempItem={tempItem}
          setEditingItem={setEditingItem}
        />
        <CSVUploadItems />
      </div>

      <div style={{ width: "100%", marginTop: "20px" }}>
        <WidgetTable
          items={widgetItems}
          handleAddWidget={handleAddWidget}
          handleFieldChange={handleFieldChange}
          handleDeleteItem={handleDeleteItem}
          handleUpdateDexie={handleUpdateDexie}
          handleAddToDexie={handleAddToDexie}
          handleEditClick={handleEditClick}
          handleSaveChanges={handleSaveChanges}
          editingItem={editingItem}
          tempItem={tempItem}
          setEditingItem={setEditingItem}
        />
      </div>

      <hr />
      <div className="server-color">
        <h4 className="alignCenter">
          Manage server data for users to add to their grid. Changes below are saved in real time.
        </h4>
        <div style={{ marginTop: "20px" }}>
          <ManageWidgets />
        </div>

        <div style={{ marginTop: "20px" }}>
          <ManageButtons />
        </div>
      </div>
      <hr />
      {/* Expirement to add items */}
      <div style={{ marginTop: "20px" }}>
        <ItemTable />
      </div>
    </div>
  );
};

export default AdminPage;
