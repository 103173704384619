import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { auth, db } from "./firebaseConfig";
import { signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { userState } from "./state/appState";

import appIcon from "./images/brand/icon128.png";
import googleIcon from "./icons-app/Google.svg";

import "./css/RegisterLogin.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);

  // Fetch user permissions from Firestore
  const fetchUserPermissions = async (user) => {
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log("User document found:", userData);

      // Set user state with additional permissions
      setUser({
        ...user,
        isWeatherWidgetInstalled: userData.isWeatherWidgetInstalled || false,
        canAccessWeatherWidget: userData.canAccessWeatherWidget || false,
        canAccessChatbot: userData.canAccessChatbot || false,
        isPayingClient: userData.isPayingClient || false
      });
    } else {
      console.error("No document found for user:", user.uid);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Attempting to log in with:", email);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("User logged in:", user.uid);

      await fetchUserPermissions(user); // Fetch and set user permissions

      navigate("/");
    } catch (error) {
      console.error("Login error:", error.message);
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User signed in with Google:", user);

      await fetchUserPermissions(user); // Fetch and set user permissions

      navigate("/");
    } catch (error) {
      console.error("Google Sign-In error:", error.message);
      setError(error.message);
    }
  };

  const handlePasswordReset = async () => {
    setError("");
    setMessage(""); // Clear any previous messages
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Please check your inbox.");
      console.log("Password reset email sent");
    } catch (error) {
      setError(error.message);
      console.error("Error during password reset:", error);
    }
  };

  return (
    <div className="login-container">
      <div className="app-icon-container">
        <img src={appIcon} alt="App Icon" className="appIcon" />
      </div>

      <h1>Login to MyHomePage.ai</h1>

      <form onSubmit={handleLogin}>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />

        <button type="submit">Login</button>

        <div className="google-row">
          <img src={googleIcon} alt="Google Icon" className="googleIcon" />
          <button type="button" onClick={handleGoogleSignIn}>
            Sign in with Google
          </button>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {message && <p style={{ color: "green" }}>{message}</p>}
        <br />
        <button type="button" onClick={handlePasswordReset}>
          Forgot Password?
        </button>
      </form>
    </div>
  );
};

export default Login;
