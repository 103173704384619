import React, { useState, useEffect, useRef } from "react";
import "../css/SettingsPanel.css";
import { useRecoilState } from "recoil";
import { activeTabState } from "../state/state";
// import { isAdminState } from "../state/appState";
import BackgroundSettings from "./BackgroundSettings";
import Settings from "./Settings";
// import { saveUserPreference } from "../db/db";
import closeButton from "../images/app-buttons/close.png";

const SettingsPanel = ({ onClose, isDragging, toggleDragging, openEditButtonsModal }) => {
  const selectorRef = useRef(null); // Reference to the settings panel for dragging
  const [activeTab] = useRecoilState(activeTabState); // State for active tab
  // const [, setBackgroundSource] = useRecoilState(backgroundSourceState); // State for background source
  // const [, setImageType] = useRecoilState(imageTypeState); // State for image type
  // const [customImageType, setCustomImageType] = useState(""); // State for custom image type

  // const isAdmin = useRecoilValue(isAdminState); // Get isAdmin state

  // // Handle tab click
  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  // Handle click outside to close the settings panel
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const [isDraggingState, setIsDraggingState] = useState(false); // State for dragging
  const [origin] = useState({ x: 0, y: 0 }); // Origin for dragging

  // Handle mouse move for dragging
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDraggingState) return;

      const newX = e.clientX - origin.x;
      const newY = e.clientY - origin.y;

      if (selectorRef.current) {
        selectorRef.current.style.left = `${newX}px`;
        selectorRef.current.style.top = `${newY}px`;
      }
    };

    const handleMouseUp = () => {
      setIsDraggingState(false);
    };

    if (isDraggingState) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDraggingState, origin]);

  // Handle mouse down to start dragging
  // const handleMouseDown = (e) => {
  //   setIsDraggingState(true);
  //   setOrigin({
  //     x: e.clientX - selectorRef.current.offsetLeft,
  //     y: e.clientY - selectorRef.current.offsetTop
  //   });
  // };

  // Toggle background source between standard and Unsplash
  // const toggleBackgroundSource = (checked) => {
  //   const newSource = checked ? "unsplash" : "standard";
  //   setBackgroundSource(newSource);
  //   saveUserPreference("backgroundSource", newSource); // Save to IndexedDB
  // };

  // Handle image type change
  // const handleImageTypeChange = (e) => {
  //   const newType = e.target.value;
  //   setImageType(newType);
  //   saveUserPreference("imageType", newType); // Save to IndexedDB
  // };

  // Handle custom image type change
  // const handleCustomImageTypeChange = (e) => {
  //   const newType = e.target.value;
  //   setCustomImageType(newType);
  // };

  // // Apply custom image type
  // const applyCustomImageType = () => {
  //   setImageType(customImageType);
  //   saveUserPreference("imageType", customImageType); // Save to IndexedDB
  // };

  return (
    <div className="settings-dialog" ref={selectorRef}>
      <div className="app-header">
        <h3>Background Settings</h3>
        <button type="button" onClick={onClose} className="btn-close">
          <img src={closeButton} alt="Close" />
        </button>
      </div>

      <div className="settings-panel">
        <div className="tabs">
          {/* <button onClick={() => handleTabClick("background")} className={activeTab === "background" ? "active" : ""}>
            Background
          </button> */}

          {/* <button onClick={openEditButtonsModal} className="edit-buttons-button">
            Edit Buttons
          </button> */}

          {/* {isAdmin && (
            <button onClick={() => handleTabClick("settings")} className={activeTab === "settings" ? "active" : ""}>
              Settings
            </button>
          )} */}
        </div>

        <div className="tab-content">
          {activeTab === "background" && <BackgroundSettings />}
          {activeTab === "settings" && <Settings isDragging={isDragging} toggleDragging={toggleDragging} />}
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;
