import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db as firebaseDB } from "../../firebaseConfig";
import EditableTable from "./EditableTable";
import "../../css/ManageButtons.css";

const ManageButtons = () => {
  const [buttons, setButtons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataSnapshot = await getDocs(collection(firebaseDB, "managedData"));
        const itemsList = dataSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setButtons(itemsList.filter((item) => item.type === "button"));
      } catch (error) {
        console.error("Error fetching buttons: ", error);
        setError("Error fetching buttons");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddButton = async () => {
    const newButton = {
      id: "", // Added field ID
      title: "New Button",
      url: "",
      gridX: 0,
      gridY: 0,
      main_id: 0,
      folder_id: 0,
      common_id: 0,
      newTab: true,
      icon: "",
      iconFile: "",
      iconOption: "defaultIcon",
      backgroundColor: "#000000",
      type: "button",
      visible: true,
      state: "active",
    };

    try {
      const docRef = await addDoc(collection(firebaseDB, "managedData"), newButton);
      newButton.id = docRef.id;
      setButtons([...buttons, newButton]);
    } catch (error) {
      console.error("Error adding button: ", error);
      setError("Error adding button");
    }
  };

  const handleUpdateButton = async (id, updatedButton) => {
    try {
      const itemRef = doc(firebaseDB, "managedData", id);
      await updateDoc(itemRef, updatedButton);
      setButtons(buttons.map((button) => (button.id === id ? { ...updatedButton, id } : button)));
    } catch (error) {
      console.error("Error updating button: ", error);
      setError("Error updating button");
    }
  };

  const handleDeleteButton = async (id) => {
    try {
      const itemRef = doc(firebaseDB, "managedData", id);
      await deleteDoc(itemRef);
      setButtons(buttons.filter((button) => button.id !== id));
    } catch (error) {
      console.error("Error deleting button: ", error);
      setError("Error deleting button");
    }
  };

  const buttonColumns = [
    { field: "id", label: "ID", width: "50px", editable: false },
    { field: "title", label: "Title", width: "110px", editable: true },
    { field: "gridX", label: "Grid X", width: "50px", type: "number", editable: true },
    { field: "gridY", label: "Grid Y", width: "50px", type: "number", editable: true },
    { field: "main_id", label: "Main ID", width: "60px", type: "number", editable: true },
    { field: "folder_id", label: "Folder ID", width: "60px", type: "number", editable: true },
    { field: "common_id", label: "Common ID", width: "60px", type: "number", editable: true },
    { field: "url", label: "URL", width: "200px", editable: true },
    { field: "newTab", label: "New Tab", width: "50px", type: "checkbox", editable: true },
    { field: "icon", label: "Icon", width: "120px", editable: true },
    { field: "backgroundColor", label: "Background Color", width: "80px", editable: true },
    { field: "visible", label: "Visible", width: "50px", type: "checkbox", editable: true },
    { field: "initialLoad", label: "Allow Load", width: "50px", type: "checkbox", editable: true },
    { field: "isPaid", label: "Is Paid", width: "50px", type: "checkbox", editable: true },
    { field: "subscription", label: "Subscription", width: "50px", editable: true },
  ];

  return (
    <div>
      <div className="flexRow">
        <h3>Server Buttons</h3>
        <button className="handle-add-button" onClick={handleAddButton}>
          Add New Button
        </button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="admin-edit-widget-table">
          <EditableTable
            data={buttons}
            columns={buttonColumns}
            onUpdate={handleUpdateButton}
            onDelete={handleDeleteButton}
          />
        </div>
      )}
      {error && <div>{error}</div>}
    </div>
  );
};

export default ManageButtons;
