import React from "react";
import { useRecoilState } from "recoil";
import { itemsState } from "../state/state";
import DroppableContainer from "./DroppableContainer";
import DraggableItem from "./DraggableItem";
import PlaceholderDropZone from "./PlaceholderDropZone";

const CommonGridItems = ({ items, handleOpenFolder, isDragging, handleEdit, handleIconClick, isDeleteMode, handleDelete, user }) => {
  const [, setItems] = useRecoilState(itemsState);

  const renderCommonGridItems = () => {
    const itemsInCommonGrid = items.filter((item) => item.common_id?.toString() === "1" && item.main_id?.toString() === "0");
    const placeholders = [];

    // Create a 10x1 grid with placeholders
    for (let x = 0; x < 8; x++) {
      const item = itemsInCommonGrid.find((item) => item.gridX === x && item.gridY === 0);
      if (item) {
        placeholders.push(
          <DroppableContainer
            key={`item-${item.id}`}
            id={`item-${item.id}`}
            className={`grid-item ${isDragging ? "dragging" : ""}`}
            gridX={x}
            gridY={0}
            gridId={"common"}
            main_id={0}
            common_id={1}
            folder_id={0}
            type={item.type}
          >
            <DraggableItem 
              item={item} 
              onOpenFolder={handleOpenFolder} 
              onEdit={handleEdit} 
              onClick={handleIconClick}
              isDeleteMode={isDeleteMode}
              onDelete={handleDelete}
            />
          </DroppableContainer>
        );
      } else {
        placeholders.push(
          <DroppableContainer
            key={`common-placeholder-${x}-0`}
            id={`common-placeholder-${x}-0`}
            className={`grid-item placeholder ${isDragging ? "dragging" : ""}`}
            gridX={x}
            gridY={0}
            gridId={"common"}
            main_id={0}
            common_id={1}
            folder_id={0}
          >
            <PlaceholderDropZone />
          </DroppableContainer>
        );
      }
    }

    return placeholders;
  };

  return <>{renderCommonGridItems()}</>;
};

export default CommonGridItems;
