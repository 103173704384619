import React from 'react';
import { useUrlCollector } from '../hooks/useUrlCollector';
import AddItemForm from '../grid-components/AddItemForm';

export const UrlCollector = () => {
  const { showAddItemForm, setShowAddItemForm, initialUrl, initialName } = useUrlCollector();

  if (!showAddItemForm) return null;

  return (
    <AddItemForm
      showForm={showAddItemForm}
      setShowForm={setShowAddItemForm}
      initialUrl={initialUrl}
      initialName={initialName}
    />
  );
};

export default UrlCollector;
