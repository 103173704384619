import React, { useState } from "react";
import Papa from "papaparse";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const CSVUploadItems = () => {
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;
        let newItems = [];
        let errorCount = 0;

        console.log("Parsed data from CSV:", data); // Log the parsed data

        for (const item of data) {
          try {
            const isWidget = item.type.toLowerCase() === "widget";
            console.log("Processing item:", item);

            const newItemData = {
              title: item.title || "",
              gridX: Number(item.gridX) || 0,
              gridY: Number(item.gridY) || 0,
              main_id: Number(item.main_id) || 0,
              folder_id: Number(item.folder_id) || 0,
              common_id: Number(item.common_id) || 0,
              visible: item.visible === "true",
              state: item.state || "active",
              type: item.type.toLowerCase() || "button",
              ...(isWidget && {
                description: item.description || "",
                activationCode: item.activationCode || "",
                iconCode: item.iconCode || "",
                allgrids: item.allgrids === "true",
                screenX: Number(item.screenX) || 0,
                screenY: Number(item.screenY) || 0
              }),
              ...(!isWidget && {
                url: item.url || "",
                newTab: item.newTab === "true",
                icon: item.icon || "",
                iconFile: item.iconFile || "",
                iconOption: item.iconOption || "defaultIcon",
                backgroundColor: item.backgroundColor || "#000000",
                style: item.style || "default",
                svg: item.svg || null,
                icon_code: item.icon_code || null,
                icon_file_name: item.icon_file_name || null
              })
            };

            console.log("Prepared newItemData:", newItemData);

            const docRef = await addDoc(collection(db, "managedData"), newItemData);
            newItemData.id = docRef.id; // Assign the new Firestore-generated ID to newItemData
            console.log(`Item successfully added with ID: ${newItemData.id}`);
            newItems.push(newItemData);
          } catch (error) {
            console.error("Error adding new item:", error);
            errorCount++;
          }
        }

        console.log(`Total items processed: ${data.length}`);
        console.log(`Total items uploaded: ${newItems.length}`);
        console.log(`Total errors encountered: ${errorCount}`);

        setUploadStatus(`Uploaded ${newItems.length} items successfully. ${errorCount > 0 ? `Failed to upload ${errorCount} items.` : ""}`);
      }
    });
  };

  return (
    <div id="csv-upload-widget">
      <input type="file" accept=".csv" onChange={handleFileChange} />
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default CSVUploadItems;
