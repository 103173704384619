import React from "react";
import Papa from "papaparse";

function CSVDownloadWidget({ selectedButtons, buttons }) {
  const handleDownload = () => {
    const csvData = buttons
      .filter((button) => selectedButtons.includes(button.id))
      .map(({ title, url, newTab, gridX, gridY, main_id, folder_id, common_id, backgroundColor, icon }) => ({
        title,
        url,
        newTab,
        gridX,
        gridY,
        main_id,
        folder_id,
        common_id,
        backgroundColor,
        icon
      }));

    console.log("CSV Data:", csvData);

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "buttons.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="csv-download-widget">
      <button className="edit-download-button" onClick={handleDownload}>
        Download CSV
      </button>
    </div>
  );
}

export default CSVDownloadWidget;
