/* global chrome */
import { useEffect, useCallback, useState } from 'react';

export const useUrlCollector = () => {
  // console.log('useUrlCollector initialized');
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [collectedUrl, setCollectedUrl] = useState('');
  const [collectedName, setCollectedName] = useState('');

  const processNewUrl = useCallback((urlData) => {
    console.log('Processing new URL:', urlData);
    if (!urlData?.url) {
      console.log('Invalid URL data:', urlData);
      return;
    }

    // console.log('Setting URL and showing form:', urlData.url);
    setCollectedUrl(urlData.url);
    setCollectedName(urlData.name || '');
    setShowAddItemForm(true);
  }, []);

  useEffect(() => {
    console.log('Setting up message listener');
    let mounted = true;

    // Function to handle messages from the extension via content script
    const handleMessage = (event) => {
      // console.log('Received message event:', event);

      // Make sure the message is from our extension
      if (!event.data?.type) {
        // console.log('No message type:', event.data);
        return;
      }

      if (event.data.type !== 'FROM_CHROME_EXTENSION') {
        // console.log('Not from our extension:', event.data);
        return;
      }
      
      const message = event.data.message;
      console.log('React app received message:', message);

      if (!message?.action) {
        console.log('No message action:', message);
        return;
      }

      if (message.action === 'urlClicked' && mounted) {
        // console.log('URL clicked in extension:', {
        //   url: message.url,
        //   name: message.name
        // });

        processNewUrl({ url: message.url, name: message.name });
      }
    };

    // Add window message listener
    window.addEventListener('message', handleMessage);
    console.log('Message listener added');

    return () => {
      mounted = false;
      window.removeEventListener('message', handleMessage);
      console.log('Message listener removed');
    };
  }, [processNewUrl]);

  return {
    showAddItemForm,
    setShowAddItemForm,
    initialUrl: collectedUrl,
    initialName: collectedName
  };
};
