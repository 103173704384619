// New UserTable Component (UserTable.js)
import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db as firebaseDB } from "../../firebaseConfig";

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(firebaseDB, "users"));
        const usersList = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users: ", error);
        setError("Error fetching users");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Function to handle role changes
  const handleRoleChange = async (id, newRole) => {
    try {
      await updateDoc(doc(firebaseDB, "users", id), { role: newRole });
      setUsers(users.map((user) => (user.id === id ? { ...user, role: newRole } : user)));
    } catch (error) {
      console.error("Error updating user role: ", error);
      setError("Error updating user role");
    }
  };

  // Function to handle permissions like chat access, weather widget, or paying client status
  const handlePermissionChange = async (id, permission, value) => {
    try {
      await updateDoc(doc(firebaseDB, "users", id), { [permission]: value });
      setUsers(users.map((user) => (user.id === id ? { ...user, [permission]: value } : user)));
    } catch (error) {
      console.error(`Error updating ${permission}: `, error);
      setError(`Error updating ${permission}`);
    }
  };

  // Function to delete user
  const handleDeleteUser = async (id) => {
    try {
      await deleteDoc(doc(firebaseDB, "users", id));
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error("Error deleting user: ", error);
      setError("Error deleting user");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Role</th>
          <th>Chat Access</th>
          <th>Weather Access</th>
          <th>Paying Client</th>
          <th>Phone</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.length === 0 ? (
          <tr>
            <td colSpan="7">No users available</td>
          </tr>
        ) : (
          users.map((user) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>
                <select
                  value={user.role}
                  onChange={(e) => handleRoleChange(user.id, e.target.value)}
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={user.chatAccess}
                  onChange={(e) => handlePermissionChange(user.id, "chatAccess", e.target.checked)}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={user.canAccessWeatherWidget}
                  onChange={(e) =>
                    handlePermissionChange(user.id, "canAccessWeatherWidget", e.target.checked)
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={user.isPayingClient}
                  onChange={(e) =>
                    handlePermissionChange(user.id, "isPayingClient", e.target.checked)
                  }
                />
              </td>
              <td>{user.phone || "N/A"}</td>
              <td>
                <button className="btn-delete" onClick={() => handleDeleteUser(user.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default UserTable;
