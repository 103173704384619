import React, { useState } from "react";
import "../../css/ButtonTable.css";

const ButtonTable = ({
  items,
  handleAddButton,
  handleFieldChange,
  handleDeleteItem,
  handleUpdateDexie,
  handleAddToDexie,
  handleEditClick,
  handleSaveChanges,
  editingItem,
  tempItem,
  setEditingItem,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = [...items].sort((a, b) => {
    if (sortConfig.key === null) {
      return 0;
    }

    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  return (
    <div className="table-container-abc">
      <div className="flexRow alignCenter">
        <h2>Button items in the grid</h2>
        <button style={{ maxWidth: "150px" }} onClick={handleAddButton}>
          Add New Button
        </button>
      </div>

      <div className="table-wrapper">
        <table className="button-table">
          <thead>
            <tr>
              <th onClick={() => handleSort("id")}>ID</th>
              <th onClick={() => handleSort("title")}>Title</th>
              <th className="gridXY" onClick={() => handleSort("gridX")}>
                Grid X
              </th>
              <th className="gridXY" onClick={() => handleSort("gridY")}>
                Grid Y
              </th>
              <th className="gridID" onClick={() => handleSort("main_id")}>
                Main ID
              </th>
              <th className="gridID" onClick={() => handleSort("folder_id")}>
                Folder ID
              </th>
              <th className="gridID" onClick={() => handleSort("common_id")}>
                Common ID
              </th>
              <th onClick={() => handleSort("url")}>URL</th>
              <th onClick={() => handleSort("newTab")}>New Tab</th>
              <th onClick={() => handleSort("icon")}>Icon</th>
              <th onClick={() => handleSort("iconOption")}>Icon Option</th>
              <th onClick={() => handleSort("backgroundColor")}>Background Color</th>
              <th onClick={() => handleSort("visible")}>Visible</th>
              <th onClick={() => handleSort("state")}>State</th>
              <th onClick={() => handleSort("initialLoad")}>Initial Load</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedItems
              .filter((item) => item.type === "button")
              .map((item) => (
                <tr key={item.id}>
                  <td className="input-id width50" style={{ maxWidth: "50px" }}>
                    {editingItem === item.id ? (
                      <input
                        className="width50"
                        type="text"
                        value={tempItem.id}
                        onChange={(e) => handleFieldChange("id", e.target.value)}
                      />
                    ) : (
                      item.id
                    )}
                  </td>
                  <td style={{ maxWidth: "150px" }}>
                    <input
                      className="input-title"
                      type="text"
                      value={editingItem === item.id ? tempItem.title : item.title}
                      onChange={(e) => handleFieldChange("title", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "50px" }}>
                    <input
                      className="input-gridX gridXY"
                      type="number"
                      value={editingItem === item.id ? tempItem.gridX : item.gridX}
                      onChange={(e) => handleFieldChange("gridX", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "50px" }}>
                    <input
                      className="input-gridY gridXY"
                      type="number"
                      value={editingItem === item.id ? tempItem.gridY : item.gridY}
                      onChange={(e) => handleFieldChange("gridY", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "40px" }}>
                    <input
                      className="input-main_id gridID"
                      type="number"
                      value={editingItem === item.id ? tempItem.main_id : item.main_id}
                      onChange={(e) => handleFieldChange("main_id", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "40px" }}>
                    <input
                      className="input-folder_id gridID"
                      type="number"
                      value={editingItem === item.id ? tempItem.folder_id : item.folder_id}
                      onChange={(e) => handleFieldChange("folder_id", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "40px" }}>
                    <input
                      className="input-common_id gridID"
                      type="number"
                      value={editingItem === item.id ? tempItem.common_id : item.common_id}
                      onChange={(e) => handleFieldChange("common_id", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "200px" }}>
                    <input
                      className="input-url"
                      type="text"
                      value={editingItem === item.id ? tempItem.url : item.url}
                      onChange={(e) => handleFieldChange("url", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "50px" }}>
                    <input
                      className="input-newTab"
                      type="checkbox"
                      checked={editingItem === item.id ? tempItem.newTab : item.newTab}
                      onChange={(e) => handleFieldChange("newTab", e.target.checked)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      className="input-icon width200"
                      type="text"
                      value={editingItem === item.id ? tempItem.icon : item.icon}
                      onChange={(e) => handleFieldChange("icon", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "100px" }}>
                    <select
                      className="select-iconOption"
                      value={editingItem === item.id ? tempItem.iconOption : item.iconOption}
                      onChange={(e) => handleFieldChange("iconOption", e.target.value)}
                      disabled={editingItem !== item.id}
                    >
                      <option value="defaultIcon">Default Icon</option>
                      <option value="customIcon">Custom Icon</option>
                    </select>
                  </td>
                  <td style={{ maxWidth: "100px" }}>
                    <input
                      className="input-backgroundColor"
                      type="text"
                      value={
                        editingItem === item.id ? tempItem.backgroundColor : item.backgroundColor
                      }
                      onChange={(e) => handleFieldChange("backgroundColor", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "50px" }}>
                    <input
                      className="input-visible"
                      type="checkbox"
                      checked={editingItem === item.id ? tempItem.visible : item.visible}
                      onChange={(e) => handleFieldChange("visible", e.target.checked)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "50px" }}>
                    <input
                      className="input-state"
                      type="text"
                      value={editingItem === item.id ? tempItem.state : item.state}
                      onChange={(e) => handleFieldChange("state", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td style={{ maxWidth: "50px" }}>
                    <input
                      className="input-initialLoad"
                      type="checkbox"
                      checked={editingItem === item.id ? tempItem.initialLoad : item.initialLoad}
                      onChange={(e) => handleFieldChange("initialLoad", e.target.checked)}
                      disabled={editingItem !== item.id}
                    />
                  </td>

                  <td style={{ whiteSpace: "nowrap", width: "180px", padding: "5px" }}>
                    {editingItem === item.id ? (
                      <button
                        className="button-2"
                        style={{ width: "60px" }}
                        onClick={handleSaveChanges}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        className="button-2"
                        style={{ width: "60px" }}
                        onClick={() => handleEditClick(item)}
                      >
                        Edit
                      </button>
                    )}
                    {/* <button
                      className="btn-update button-2"
                      onClick={() => handleUpdateDexie(item.id)}
                    >
                      Update
                    </button>
                    <button className="btn-add button-2" onClick={() => handleAddToDexie(item.id)}>
                      Add
                    </button> */}
                    <button
                      style={{ width: "80px" }}
                      className="btn-delete button-2"
                      onClick={() => handleDeleteItem(item.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ButtonTable;
