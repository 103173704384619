import React, { useState } from "react";

const EditableTable = ({ data, columns, onUpdate, onDelete, showDelete = false }) => {
  const [sortConfig, setSortConfig] = useState([]);

  const handleChange = (e, field, row) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const updatedRow = { ...row, [field]: value };
    onUpdate(row.id, updatedRow);
  };

  const handleSort = (col) => {
    setSortConfig((prevConfig) => {
      const existingSort = prevConfig.find((config) => config.key === col.field);
      if (existingSort) {
        return prevConfig.map((config) =>
          config.key === col.field
            ? {
                ...config,
                direction: config.direction === "ascending" ? "descending" : "ascending",
              }
            : config
        );
      } else {
        return [...prevConfig, { key: col.field, direction: "ascending" }];
      }
    });
  };

  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => {
      for (let config of sortConfig) {
        if (a[config.key] < b[config.key]) {
          return config.direction === "ascending" ? -1 : 1;
        }
        if (a[config.key] > b[config.key]) {
          return config.direction === "ascending" ? 1 : -1;
        }
      }
      return 0;
    });
  }, [data, sortConfig]);

  return (
    <table>
      <thead>
        <tr>
          {columns.map((col) => (
            <th
              key={col.field}
              style={{ width: col.width, cursor: "pointer" }}
              onClick={() => handleSort(col)}
            >
              {col.label}{" "}
              {sortConfig.find((config) => config.key === col.field) && (
                <span>
                  {sortConfig.find((config) => config.key === col.field).direction ===
                  "ascending"
                    ? " ↑"
                    : " ↓"}
                </span>
              )}
            </th>
          ))}
          {showDelete && <th style={{ width: "60px" }}>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row) => (
          <tr key={row.id} className={row.isBuiltIn ? "built-in-row" : ""}>
            {columns.map((col) => (
              <td key={`${row.id}-${col.field}`}>
                {col.editable ? (
                  col.type === "checkbox" ? (
                    <input
                      type="checkbox"
                      checked={row[col.field] || false}
                      onChange={(e) => handleChange(e, col.field, row)}
                    />
                  ) : (
                    <input
                      type={col.type || "text"}
                      value={row[col.field] || ""}
                      onChange={(e) => handleChange(e, col.field, row)}
                      style={{ width: "100%" }}
                      disabled={row.isBuiltIn}
                    />
                  )
                ) : (
                  row[col.field]
                )}
              </td>
            ))}
            {showDelete && (
              <td>
                <button onClick={() => onDelete(row.id)}>Delete</button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EditableTable;
