import React, { useState, useEffect } from "react";
import db from "../db/db";
import { SyncService } from "../services/SyncService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faSpinner, faCrown } from "@fortawesome/free-solid-svg-icons";
import "../css/SyncButton.css";

const SyncButton = ({ user, setSyncStatus }) => {
  const [isSyncing, setIsSyncing] = useState(false);

  const handleUpgradeClick = () => {
    window.location.href = 'https://www.myhomepage.ai/upgrade';
  };

  const handleSyncToCloud = async () => {
    if (!user?.isPayingClient) {
      console.log('❌ Sync attempted but user is not a premium user');
      setSyncStatus("✨ Sync is a premium feature. Upgrade to enable cloud sync!");
      return;
    }

    if (isSyncing) {
      console.log('⏳ Sync already in progress');
      return;
    }

    try {
      console.log('🔄 Starting sync process...');
      setIsSyncing(true);
      setSyncStatus("Preparing to sync...");

      // Check if there are any items in the sync queue
      const queueItems = await db.syncQueue.toArray();
      console.log(`📊 Found ${queueItems.length} items in sync queue`);
      
      if (queueItems.length === 0) {
        console.log('✅ No changes to sync');
        setSyncStatus("No changes to sync!");
        setIsSyncing(false);
        return;
      }

      // Process deleted items first
      console.log('🗑️ Processing deleted items...');
      await SyncService.handleDeletedItems(user);
      setSyncStatus("Syncing changes...");

      // Sync all changes
      console.log('🔄 Starting full sync...');
      await SyncService.syncAll(user);
      
      console.log('✅ Sync completed successfully');
      setSyncStatus("All changes synced successfully!");
    } catch (error) {
      console.error("❌ Sync failed:", error);
      setSyncStatus("Sync failed. Changes will be synced when you're back online.");
      
      // If we're offline, changes will be queued automatically
      if (!navigator.onLine) {
        setSyncStatus("You're offline. Changes will sync when you're back online.");
      }
    } finally {
      setIsSyncing(false);
    }
  };

  // Add online/offline event listeners
  useEffect(() => {
    const handleOnline = async () => {
      // Only attempt sync if there are items in the queue
      const queueItems = await db.syncQueue.toArray();
      if (queueItems.length > 0 && user?.isPayingClient) {
        handleSyncToCloud();
      }
    };

    window.addEventListener('online', handleOnline);
    return () => window.removeEventListener('online', handleOnline);
  }, [user]);

  return user?.isPayingClient ? (
    <button 
      onClick={handleSyncToCloud}
      className="menu-item"
      disabled={isSyncing}
    >
      <FontAwesomeIcon 
        icon={isSyncing ? faSpinner : faCloudArrowUp} 
        className={isSyncing ? 'fa-spin' : ''}
      />
      <span>{isSyncing ? 'Syncing...' : 'Sync'}</span>
    </button>
  ) : (
    <button onClick={handleUpgradeClick} className="menu-item">
      <FontAwesomeIcon icon={faCrown} />
      <span>Upgrade for Sync</span>
    </button>
  );
};

export default SyncButton;
