// DNDProvider.js
import React, { useState } from "react";
import { DndContext, useSensor, useSensors, MouseSensor, TouchSensor, KeyboardSensor, DragOverlay } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from "@dnd-kit/sortable";
import { doc, setDoc } from "firebase/firestore";
import { db as firebaseDB } from "../firebaseConfig";
import db, { addItemWithSync } from "../db/db";
import DraggableItem from "./DraggableItem";
import TransientMessage from "../components/TransientMessage";
import { SyncService } from "../services/SyncService";


export const resolveConflicts = (items, maxAttempts = 45) => {
  const gridPositions = new Map();
  const resolvedItems = [...items];

  // Define grid dimensions for each context
  const gridDimensions = {
    folder: { width: 6, height: 4 },
    common: { width: 8, height: 1 },
    main: { width: 9, height: 5 }
  };

  resolvedItems.forEach((item, index) => {
    let attempts = 0;
    let currentX = item.gridX;
    let currentY = item.gridY;

    // Determine grid context and dimensions
    let dimensions;
    if (item.folder_id) {
      dimensions = gridDimensions.folder;
    } else if (item.common_id) {
      dimensions = gridDimensions.common;
    } else {
      dimensions = gridDimensions.main;
    }

    // Create a unique key that includes the context
    let contextKey = item.folder_id ? `folder-${item.folder_id}` : 
                    item.common_id ? `common-${item.common_id}` : 
                    `main-${item.main_id}`;
    let key = `${contextKey}-${currentX}-${currentY}`;

    // Keep trying new positions until we find an empty spot or hit max attempts
    while (gridPositions.has(key) && attempts < maxAttempts) {
      // Move to next position within grid bounds
      currentX++;
      if (currentX >= dimensions.width) {
        currentX = 0;
        currentY++;
        if (currentY >= dimensions.height) {
          currentY = 0; // Wrap back to top if we exceed height
        }
      }
      
      key = `${contextKey}-${currentX}-${currentY}`;
      attempts++;
    }

    // Ensure we stay within grid bounds
    currentX = Math.min(currentX, dimensions.width - 1);
    currentY = Math.min(currentY, dimensions.height - 1);

    // Update item with new position
    resolvedItems[index] = {
      ...item,
      gridX: currentX,
      gridY: currentY
    };

    // Mark position as taken
    gridPositions.set(key, index);
  });

  return resolvedItems;
};

const DndProvider = ({ 
  items, 
  setItems, 
  handleNextGridDragOver, 
  handlePreviousGridDragOver, 
  setIsDragging, 
  handleIconClick, 
  children, 
  user,
  onShowMessage 
}) => {
  const [activeDragItem, setActiveDragItem] = useState(null);
  const [activeDragData, setActiveDragData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [originalPosition, setOriginalPosition] = useState(null);  

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveDragItem(active);
    setActiveDragData(active.data.current);
    setIsDragging(true);

    // Add sync tracking
    const draggedItem = items.find(item => item.id === active.id);
    if (draggedItem) {
      console.log('Drag started:', { itemId: active.id, position: { x: draggedItem.gridX, y: draggedItem.gridY } });
      setOriginalPosition({ x: draggedItem.gridX, y: draggedItem.gridY });
    }
  };

  const handleDragOver = (event) => {
    const { active, over } = event;
    if (!over) return;

    const isMovingForward = over.id === "next-grid";
    const isMovingBackward = over.id === "previous-grid";

    if (isMovingForward) handleNextGridDragOver();
    if (isMovingBackward) handlePreviousGridDragOver();
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    setIsDragging(false);
    if (!over) {
      setActiveDragItem(null);
      setActiveDragData(null);
      return;
    }

    console.log("Drag End Debug:", {
      active: {
        id: active.id,
        data: active.data.current,
        type: active.data.current.type
      },
      over: {
        id: over.id,
        data: over.data.current,
        type: over.data.current.type
      }
    });

    const activeCurrent = activeDragData || active.data.current;
    const overCurrent = over.data.current;

    // Prevent folder from being dropped into itself
    if (activeCurrent.type === "folder") {
      const draggedFolderId = activeCurrent.id;
      const targetFolderId = overCurrent.folder_id;
  
      if (draggedFolderId?.toString() === targetFolderId?.toString()) {
        console.warn("Prevented folder from being dropped into itself");
        if (onShowMessage) {
          onShowMessage("Cannot move a folder into itself");
        }
        setActiveDragItem(null);
        setActiveDragData(null);
        return;
      }
    }

    const sourceGridId = activeCurrent.common_id
      ? "common"
      : activeCurrent.main_id
      ? `main-${activeCurrent.main_id}`
      : activeCurrent.folder_id
      ? `folder-${activeCurrent.folder_id}`
      : null;

    const targetGridId =
      overCurrent.folder_id && overCurrent.folder_id !== 0
        ? `folder-${overCurrent.folder_id}`
        : overCurrent.main_id
        ? `main-${overCurrent.main_id}`
        : overCurrent.common_id
        ? "common"
        : null;

    if (!sourceGridId || !targetGridId) {
      setActiveDragItem(null);
      setActiveDragData(null);
      return;
    }

    const isButtonOverButton = activeCurrent.type === "button" && overCurrent.type === "button";
    const isButtonOverFolder = activeCurrent.type === "button" && overCurrent.type === "folder";
    const isButtonOverMainGrid = activeCurrent.type === "button" && targetGridId.startsWith("main-");
    const isButtonOverFolderGrid = activeCurrent.type === "button" && targetGridId.startsWith("folder-");

    let updatedItems = items.map((item) => ({ ...item }));
    const isItemOverFolder =
      (activeCurrent.type === "button" || activeCurrent.type === "folder" || activeCurrent.type === "widget") && overCurrent.type === "folder";

    if (isButtonOverButton) {
      if (activeCurrent.id.toString() === overCurrent.id.split("-")[1]) {
        setActiveDragItem(null);
        setActiveDragData(null);
        return;
      }

      const newFolder = createFolderItem(targetGridId, overCurrent);
      updatedItems.push(newFolder);

      const positions = [
        { gridX: 0, gridY: 0 },
        { gridX: 0, gridY: 1 }
      ];
      let positionIndex = 0;

      updatedItems = updatedItems.map((item) => {
        if (item.id === activeCurrent.id || item.id === parseInt(overCurrent.id.split("-")[1])) {
          item.folder_id = newFolder.id;
          item.main_id = 0;
          item.common_id = 0;
          if (positionIndex < positions.length) {
            item.gridX = positions[positionIndex].gridX;
            item.gridY = positions[positionIndex].gridY;
            positionIndex++;
          }
        }
        return item;
      });
    } else if (isItemOverFolder) {
      const folderId = overCurrent.id.split("-")[1];
      if (!folderId) {
        setActiveDragItem(null);
        setActiveDragData(null);
        return;
      }

      const nextPosition = findNextAvailablePosition(updatedItems, folderId);

      updatedItems = updatedItems.map((item) => {
        if (item.id === activeCurrent.id) {
          item.folder_id = folderId;
          item.main_id = 0;
          item.common_id = 0;
          item.gridX = nextPosition.gridX;
          item.gridY = nextPosition.gridY;
        }
        return item;
      });
    } else if (isButtonOverMainGrid || isButtonOverFolderGrid) {
      updatedItems = updatedItems.map((item) => {
        if (item.id === activeCurrent.id) {
          const newMainId = targetGridId.startsWith("main-") ? targetGridId.split("-")[1] : 0;
          const newFolderId = targetGridId.startsWith("folder-") ? targetGridId.split("-")[1] : 0;
          item.gridX = overCurrent.gridX;
          item.gridY = overCurrent.gridY;
          item.folder_id = newFolderId || 0;
          item.main_id = newMainId || 0;
          item.common_id = 0;
        }
        return item;
      });
    } else {
      updatedItems = updatedItems.map((item) => {
        if (item.id === activeCurrent.id) {
          if (targetGridId.startsWith("main-")) {
            const newMainId = targetGridId.split("-")[1];
            return { ...item, main_id: newMainId.toString(), common_id: 0, folder_id: 0, gridX: overCurrent.gridX, gridY: overCurrent.gridY };
          } else if (targetGridId === "common") {
            return { ...item, main_id: 0, common_id: 1, folder_id: 0, gridX: overCurrent.gridX, gridY: overCurrent.gridY };
          } else if (targetGridId.startsWith("folder-")) {
            const newFolderId = targetGridId.split("-")[1];
            return { ...item, main_id: 0, common_id: 0, folder_id: newFolderId.toString(), gridX: overCurrent.gridX, gridY: overCurrent.gridY };
          }
        }
        return item;
      });
    }

    // Update local state first
    setItems(updatedItems);

    try {
      // Save to IndexedDB
      await db.items.bulkPut(updatedItems);

      // Add sync attempt for premium users
      const changedItem = updatedItems.find(item => item.id === activeCurrent.id);
      if (changedItem) {
        console.log('Attempting sync with user:', { 
          uid: user?.uid, 
          isPayingClient: user?.isPayingClient,
          item: changedItem
        });

        try {
          // Only sync position-related changes
          const positionUpdate = {
            gridX: changedItem.gridX,
            gridY: changedItem.gridY,
            main_id: changedItem.main_id,
            common_id: changedItem.common_id,
            folder_id: changedItem.folder_id,
            lastModified: new Date().toISOString()
          };

          await SyncService.addToSyncQueue(
            'update',
            changedItem.id,
            positionUpdate,
            user
          );
          
          console.log('Drag end sync:', { 
            itemId: changedItem.id, 
            update: positionUpdate,
            oldPosition: originalPosition 
          });
        } catch (error) {
          console.error('Sync error:', error);
          // Only show message if it's not a premium-related error
          // if (!error.message.includes('premium')) {
          //   onShowMessage?.('Changes saved locally');
          // }
        }
      }
    } catch (error) {
      console.error("Failed to save updated items:", error);
      onShowMessage?.('Failed to save changes');
    }

    setActiveDragItem(null);
    setActiveDragData(null);
  };

  const createFolderItem = (targetGridId, targetGridData) => {
    const newFolderId = Date.now();
    const folderItem = {
      id: newFolderId,
      title: `New Folder`,
      type: "folder",
      visible: true,
      main_id: targetGridId.startsWith("main-") ? targetGridId.split("-")[1] : 0,
      common_id: targetGridId === "common" ? 1 : 0,
      folder_id: targetGridId.startsWith("folder-") ? targetGridId.split("-")[1] : 0,
      gridX: targetGridData.gridX,
      gridY: targetGridData.gridY,
      lastModified: new Date().toISOString()
    };
    
    // Add to IndexedDB and sync to Firebase if user is premium
    if (user?.isPayingClient) {
      addItemWithSync(folderItem, user).catch(error => {
        console.error("Error syncing folder:", error);
        onShowMessage?.("Error syncing folder to cloud. Changes saved locally.");
      });
    }
    
    return folderItem;
  };

  const findNextAvailablePosition = (items, folderId) => {
    if (!folderId) {
      return { gridX: 0, gridY: 0 };
    }

    const folderItems = items.filter((item) => item.folder_id?.toString() === folderId.toString());
    const positions = new Set(folderItems.map((item) => `${item.gridX}-${item.gridY}`));

    for (let y = 0; y < 4; y++) {
      for (let x = 0; x < 6; x++) {
        if (!positions.has(`${x}-${y}`)) {
          return { gridX: x, gridY: y };
        }
      }
    }

    return { gridX: 0, gridY: 0 };
  };

  const snapCenterToCursor = ({ transform }) => ({
    ...transform,
    x: Math.round(transform.x),
    y: Math.round(transform.y)
  });

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={items.map((item) => item.id)}
        strategy={rectSortingStrategy}
      >
        {children}
        <DragOverlay
          dropAnimation={null}
        >
          {activeDragItem ? (
            <DraggableItem 
              item={activeDragItem.data.current} 
              onClick={handleIconClick}
              isDragging 
            />
          ) : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
};

export default DndProvider;
