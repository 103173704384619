import React from "react";
import "../../css/WidgetTable.css";

const WidgetTable = ({
  items,
  handleAddWidget,
  handleFieldChange,
  handleDeleteItem,
  handleUpdateDexie,
  handleAddToDexie,
  handleEditClick,
  handleSaveChanges,
  editingItem,
  tempItem,
  setEditingItem,
}) => {
  return (
    <div className="widget-table-container">
      <div className="widget-table-flexRow">
        <h2>Widget data for items in the grid</h2>
        <button className="widget-table-addButton" onClick={handleAddWidget}>
          Add New Widget
        </button>
      </div>
      <div className="widget-table-wrapper">
        <table className="widget-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>Widget Code</th>
              <th>Widget Icon Code</th>
              <th className="gridXY">Grid X</th>
              <th className="gridXY">Grid Y</th>
              <th className="gridID">Main ID</th>
              <th className="gridID">Folder ID</th>
              <th className="gridID">Common ID</th>
              <th>Visible</th>
              <th>State</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items
              .filter((item) => item.type === "widget")
              .map((item) => (
                <tr key={item.id}>
                  <td className="widget-table-cell-id width50">
                    {editingItem === item.id ? (
                      <input
                        className="widget-table-input-id width50"
                        type="text"
                        value={tempItem.id}
                        onChange={(e) => handleFieldChange("id", e.target.value)}
                      />
                    ) : (
                      item.id
                    )}
                  </td>
                  <td className="widget-table-cell-title width150">
                    <input
                      className="widget-table-input-title width150"
                      type="text"
                      value={editingItem === item.id ? tempItem.title : item.title}
                      onChange={(e) => handleFieldChange("title", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-icon-description width200">
                    <input
                      className="widget-table-input-description"
                      type="text"
                      value={editingItem === item.id ? tempItem.description : item.description}
                      onChange={(e) => handleFieldChange("description", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-icon-activationCode width200">
                    <input
                      className="widget-table-input-activationCode"
                      type="text"
                      value={
                        editingItem === item.id ? tempItem.activationCode : item.activationCode
                      }
                      onChange={(e) => handleFieldChange("activationCode", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-icon-iconCode  width200">
                    <input
                      className="widget-table-input-iconCode"
                      type="text"
                      value={editingItem === item.id ? tempItem.iconCode : item.iconCode}
                      onChange={(e) => handleFieldChange("iconCode", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-cell-gridX gridXY">
                    <input
                      className="widget-table-input-gridX"
                      type="number"
                      value={editingItem === item.id ? tempItem.gridX : item.gridX}
                      onChange={(e) => handleFieldChange("gridX", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-cell-gridY gridXY">
                    <input
                      className="widget-table-input-gridY"
                      type="number"
                      value={editingItem === item.id ? tempItem.gridY : item.gridY}
                      onChange={(e) => handleFieldChange("gridY", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-cell-main_id gridID">
                    <input
                      className="widget-table-input-main_id"
                      type="number"
                      value={editingItem === item.id ? tempItem.main_id : item.main_id}
                      onChange={(e) => handleFieldChange("main_id", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-cell-folder_id gridID">
                    <input
                      className="widget-table-input-folder_id"
                      type="number"
                      value={editingItem === item.id ? tempItem.folder_id : item.folder_id}
                      onChange={(e) => handleFieldChange("folder_id", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-cell-common_id gridID">
                    <input
                      className="widget-table-input-common_id"
                      type="number"
                      value={editingItem === item.id ? tempItem.common_id : item.common_id}
                      onChange={(e) => handleFieldChange("common_id", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-cell-visible">
                    <input
                      className="widget-table-input-visible"
                      type="checkbox"
                      checked={editingItem === item.id ? tempItem.visible : item.visible}
                      onChange={(e) => handleFieldChange("visible", e.target.checked)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td className="widget-table-cell-state">
                    <input
                      className="widget-table-input-state"
                      type="text"
                      value={editingItem === item.id ? tempItem.state : item.state}
                      onChange={(e) => handleFieldChange("state", e.target.value)}
                      disabled={editingItem !== item.id}
                    />
                  </td>
                  <td
                    style={{ width: "10%", padding: "0px" }}
                    className="widget-table-cell-actions"
                  >
                    {editingItem === item.id ? (
                      <button className="widget-table-btn-save" onClick={handleSaveChanges}>
                        Save
                      </button>
                    ) : (
                      <button
                        className="widget-table-btn-edit"
                        onClick={() => handleEditClick(item)}
                      >
                        Edit
                      </button>
                    )}
                    {/* <button
                      className="widget-table-btn-update"
                      onClick={() => handleUpdateDexie(item.id)}
                    >
                      Update
                    </button>
                    <button
                      className="widget-table-btn-add"
                      onClick={() => handleAddToDexie(item.id)}
                    >
                      Add
                    </button> */}
                    <button
                      className="widget-table-delete-button"
                      onClick={() => handleDeleteItem(item.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WidgetTable;
