import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import AuthListener from "./state/AuthListener";
import "./index.css";

// Get the root container
const container = document.getElementById("root");

// Create a root and render the React app
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <Router>
      <AuthListener />
      <App />
    </Router>
  </RecoilRoot>
);

// Unregister service workers to prevent caching issues
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => registration.unregister());
  });
}
