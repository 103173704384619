import React, { useState, useEffect } from "react";
import uploadButton from "../images/app-buttons/upload.gif";

const DynamicIcon = ({ iconCode }) => {
  const [IconComponent, setIconComponent] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (iconCode) {
      const loadIcon = async () => {
        try {
          // const iconPath = `../icons/${iconCode}.js`;
          const importedIcon = await import(`../icons/${iconCode}.js`);
          setIconComponent(() => importedIcon.default);
        } catch (err) {
          console.error("Error loading icon:", err);
          setError(true);
        }
      };

      loadIcon();
    }
  }, [iconCode]);

  if (error) {
    return <img src={uploadButton} alt="Default Icon" />;
  }

  if (!IconComponent) {
    return <div>Loading...</div>;
  }

  return <IconComponent />;
};

export default DynamicIcon;
