import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { useRecoilValue } from "recoil";
import { userState } from "../state/appState";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  doc,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";

import appIcon from "../images/brand/icon128.png";
import "../css/ReportIssueModal.css";

const ReportIssueFormModal = ({ showModal, onClose }) => {
  const currentUser = useRecoilValue(userState);
  const [description, setDescription] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [newReply, setNewReply] = useState({}); // Store new replies for each report
  const [reports, setReports] = useState([]);
  const [activeTab, setActiveTab] = useState("report"); // State for active tab

  useEffect(() => {
    const fetchReports = async () => {
      const querySnapshot = await getDocs(collection(db, "issueReports"));
      const reportsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        showDetails: false,
      }));
      setReports(reportsData);
    };

    if (showModal) {
      fetchReports();
    }
  }, [showModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!description) {
      setError("Please describe the issue.");
      return;
    }

    try {
      const issueRef = await addDoc(collection(db, "issueReports"), {
        userId: currentUser?.uid || "anonymous",
        email: currentUser?.email || "anonymous",
        description,
        title: "Issue Report",
        userEmail: currentUser?.email || "anonymous",
        createdAt: new Date(),
        replies: [],
      });

      setSuccess(`Issue reported successfully! Your issue ID: ${issueRef.id}.`);
      setDescription("");
    } catch (err) {
      setError("Error reporting the issue. Please try again later.");
    }
  };

  const handleReplySubmit = async (reportId) => {
    if (!newReply[reportId] || !newReply[reportId].trim()) return;

    try {
      const reportRef = doc(db, "issueReports", reportId);
      const newReplyData = {
        message: newReply[reportId],
        timestamp: Timestamp.now(),
        userType: currentUser?.isAdmin ? "admin" : "user",
        userName: currentUser?.email || "Anonymous",
      };

      await updateDoc(reportRef, {
        replies: arrayUnion(newReplyData),
      });

      setReports((prevReports) =>
        prevReports.map((report) =>
          report.id === reportId
            ? { ...report, replies: [...(report.replies || []), newReplyData] }
            : report
        )
      );

      setNewReply((prevReplies) => ({ ...prevReplies, [reportId]: "" }));
    } catch (err) {
      console.error("Error submitting reply:", err);
    }
  };

  const handleToggleDetails = (reportId) => {
    setReports((prevReports) =>
      prevReports.map((report) =>
        report.id === reportId ? { ...report, showDetails: !report.showDetails } : report
      )
    );
  };

  if (!showModal) return null;

  return (
    <div className="report-issue-modal-overlay">
      <div className="report-issue-modal-content">
        <div className="tabs">
          <button
            className={activeTab === "report" ? "active-tab" : ""}
            onClick={() => setActiveTab("report")}
          >
            Provide Feedback or Report an Issue
          </button>
          <button
            className={activeTab === "manage" ? "active-tab" : ""}
            onClick={() => setActiveTab("manage")}
          >
            Manage Your Reports
          </button>
        </div>
        {activeTab === "report" && (
          <div className="tab-content">
            <form onSubmit={handleSubmit}>
              {error && <p style={{ color: "red" }}>{error}</p>}
              {success && <p style={{ color: "green" }}>{success}</p>}

              <textarea
                placeholder="Describe the issue"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={4}
                className="issue-description"
                required
              />
              <div className="flexRow">
                <button className="submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
        {activeTab === "manage" && (
          <div className="tab-content">
            {reports.length > 0 ? (
              <div className="report-table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Latest Response</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports.map((report) => (
                      <React.Fragment key={report.id}>
                        <tr>
                          <td>
                            {report.createdAt && report.createdAt.seconds
                              ? new Date(report.createdAt.seconds * 1000).toLocaleString()
                              : "Unknown Date"}
                          </td>

                          <td>{report.description}</td>
                          <td>
                            {report.replies && report.replies.length > 0
                              ? report.replies[report.replies.length - 1].message
                              : "No response yet"}
                          </td>
                          <td>
                            <button
                              className="hide-view-btn"
                              onClick={() => handleToggleDetails(report.id)}
                            >
                              {report.showDetails ? "Hide" : "View"}
                            </button>
                          </td>
                        </tr>

                        {report.showDetails && (
                          <tr>
                            <td colSpan={4}>
                              <div className="reply-history">
                                <h4>Replies</h4>
                                <table className="reply-history-table">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Reply</th>
                                      <th>From</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {report.replies &&
                                      report.replies
                                        .sort(
                                          (a, b) =>
                                            new Date(a.timestamp.seconds * 1000) -
                                            new Date(b.timestamp.seconds * 1000)
                                        )
                                        .map((reply, index) => (
                                          <tr key={index}>
                                            <td>
                                              {new Date(
                                                reply.timestamp.seconds * 1000
                                              ).toLocaleString()}
                                            </td>
                                            <td>{reply.message}</td>
                                            <td>
                                              {reply.userType === "admin"
                                                ? "Admin"
                                                : reply.userName}
                                            </td>
                                          </tr>
                                        ))}
                                  </tbody>
                                </table>

                                {/* User Reply Section */}
                                <textarea
                                  value={newReply[report.id] || ""}
                                  onChange={(e) =>
                                    setNewReply((prevReplies) => ({
                                      ...prevReplies,
                                      [report.id]: e.target.value,
                                    }))
                                  }
                                  placeholder="Write a reply..."
                                  rows={4}
                                />
                                <button
                                  className="reply-btn"
                                  onClick={() => handleReplySubmit(report.id)}
                                >
                                  Submit Reply
                                </button>
                                <hr />
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No reports found.</p>
            )}
          </div>
        )}{" "}
        <button type="button" className="modal-close" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ReportIssueFormModal;
