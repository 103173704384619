import Dexie from "dexie";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db as firebaseDB } from "../firebaseConfig";
import { SyncService } from "../services/SyncService";
import {
  initialDesktopGrids,
  initialCommonGrids,
  initialEffects,
  initialItems,
  initialWebsiteCategories,
} from "./InitializeData";

Dexie.debug = true;

const db = new Dexie("DesktopDB3");

db.version(9).stores({
  desktopGrids: "++gridId, title, sizeX, sizeY, parent, style, visible, state, settings, type",
  items:
    "++id, category, title, description, activationCode, iconOption, iconCode, allgrids, gridX, gridY, main_id, folder_id, common_id, [main_id+common_id+folder_id], url, newTab, icon, iconFile, iconImage, iconSvg, iconColor, style, widgetVisible, visible, state, type, width, height, screenX, screenY, start_code, initialLoad, isPaid, subscription, date_deleted, lastModified, syncStatus",
  deletedItems: "++id, originalId, date_deleted, syncStatus",
  commonGrids: "++id, title, sizeX, sizeY, style, visible, state, settings, type",
  backgroundImageSelections: "++bgImageid, imageUrl",
  settings: "++id, name, value",
  effectPreferences: "++id, name, description, cssClass",
  userEffectPreferences: "++id, effectPreferenceId, effectDuration",
  notes:
    "++id, content, createdAt, updatedAt, order, pinned, gridD, Xpos, Ypos, width, height, color, style, visible, state, type",
  stickyLinks: "++id, widgetId, title, url, newTab, order",
  weatherData: "++id, city, weatherData, forecastData, timestamp",
  apiAccessCount: "++id, count",
  contacts: "++id, name, email, phone, address, city, state, zip, country, notes",
  messages: "++id, sender, text, timestamp",
  apiKeys: "++id, apiKey, provider, timestamp",
  sessionData: "++id, sessionId, data, timestamp",
  syncQueue: "++id, operation, itemId, data, timestamp, retries",
  lastSync: "++id, timestamp",
  websiteCategories: "++id, firebaseId, name, order, websites",
  timeTrackingClients: "++id, name, createdAt",
  timeTrackingCategories: "++id, name, createdAt",
  timeTrackingEntries: "++id, client, category, description, duration, isBillable, datetime, createdAt",
}).upgrade(tx => {
  // Create tables if they don't exist
  return Promise.all([
    tx.table('timeTrackingClients').toArray(),
    tx.table('timeTrackingCategories').toArray(),
    tx.table('timeTrackingEntries').toArray()
  ]).catch(error => {
    console.log('Creating time tracking tables:', error);
  });
});

// Populate initial data
db.on("populate", async () => {
  try {
    console.log("Populating initial data...");

    console.log("Populating desktopGrids...");
    await db.desktopGrids.bulkAdd(initialDesktopGrids);
    console.log("desktopGrids populated.");

    console.log("Populating commonGrids...");
    await db.commonGrids.bulkAdd(initialCommonGrids);
    console.log("commonGrids populated.");

    console.log("Populating initial items...");
    await db.items.bulkAdd(initialItems);
    console.log("items populated.");

    console.log("Populating website categories...");
    await db.websiteCategories.bulkAdd(initialWebsiteCategories);
    console.log("website categories populated.");

    console.log("Initial data population complete.");
  } catch (error) {
    console.error("Error populating initial data:", error);
  }
});

// Function to fetch an image as a Blob
export const fetchImageAsBlob = async (url) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "no-cors",
      cache: "no-store",
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error in fetchImageAsBlob:", error);
    throw error;
  }
};

// Ensure the database is open
export async function ensureDbOpen() {
  if (!db.isOpen()) {
    await db.open();
  }
}

// Add item with sync tracking
export const addItemWithSync = async (item, user) => {
  const now = new Date().toISOString();
  
  // Add ID if not present
  if (!item.id) {
    item.id = Date.now();
  }
  
  // Add timestamps
  item.created_at = now;
  item.updated_at = now;
  
  // Add to IndexedDB
  await db.items.add(item);
  
  // Add to sync queue if we have user context
  if (user) {
    console.log('Adding create operation to sync queue:', { itemId: item.id, userId: user.uid });
    await db.syncQueue.add({
      operation: "create",
      itemId: item.id,
      data: item,
      timestamp: now,
      retries: 0
    });
    
    // Process sync queue immediately
    try {
      await SyncService.processSyncQueue(user);
    } catch (error) {
      console.error('Failed to process sync queue after create:', error);
      // Don't throw - we've already saved locally
    }
  }
  
  return item;
};

// Update item with sync tracking
export const updateItemWithSync = async (id, changes) => {
  const now = new Date().toISOString();
  const updatedItem = {
    ...changes,
    lastModified: now,
    syncStatus: "pending",
  };

  await db.items.update(id, updatedItem);
  await db.syncQueue.add({
    operation: "update",
    itemId: id,
    data: updatedItem,
    timestamp: now,
    retries: 0,
  });
};

// Delete item with sync tracking
export const deleteItemWithSync = async (id, user) => {
  const now = new Date().toISOString();
  const item = await db.items.get(id);

  if (item) {
    await db.deletedItems.add({
      originalId: id,
      date_deleted: now,
      syncStatus: "pending",
    });
    await db.items.delete(id);

    // Only add to sync queue if we have user context
    if (user) {
      console.log('Adding delete operation to sync queue:', { itemId: id, userId: user.uid });
      await db.syncQueue.add({
        operation: "delete",
        itemId: id,
        data: { id, date_deleted: now },
        timestamp: now,
        retries: 0
      });

      // Process sync queue immediately
      try {
        await SyncService.processSyncQueue(user);
      } catch (error) {
        console.error('Failed to process sync queue after delete:', error);
        // Don't throw - we've already deleted locally
      }
    }
  }
};

// Chat message functions
export const saveMessage = async (sender, text) => {
  await ensureDbOpen();
  const id = await db.messages.add({ sender, text, timestamp: new Date() });
  return id;
};

export const getAllMessages = async () => {
  await ensureDbOpen();
  return await db.messages.toArray();
};

// User preferences functions
export const saveUserPreference = async (key, value) => {
  await ensureDbOpen();
  try {
    await db.userEffectPreferences.put({ id: key, value });
  } catch (error) {
    console.error("Error saving user preference:", error);
  }
};

export const getUserPreference = async (key) => {
  await ensureDbOpen();
  try {
    const result = await db.userEffectPreferences.get(key);
    return result ? result.value : null;
  } catch (error) {
    console.error("Error getting user preference:", error);
  }
};

// Weather functions
export const getWeatherData = async (city) => {
  await ensureDbOpen();
  return db.weatherData.where({ city }).first();
};

export const saveWeatherData = async (data) => {
  await ensureDbOpen();
  return db.weatherData.put(data);
};

export const incrementApiAccessCount = async () => {
  await ensureDbOpen();
  const record = await db.apiAccessCount.get(1);
  if (record) {
    record.count += 1;
    await db.apiAccessCount.put(record);
  } else {
    await db.apiAccessCount.add({ id: 1, count: 1 });
  }
};

export const clearOldWeatherData = async () => {
  await ensureDbOpen();
  const now = new Date().getTime();
  const oneDayAgo = now - 24 * 60 * 60 * 1000;

  try {
    await db.transaction("rw", db.weatherData, async () => {
      const oldEntries = await db.weatherData.where("timestamp").below(oneDayAgo).toArray();
      for (const entry of oldEntries) {
        await db.weatherData.delete(entry.id);
      }
    });
    console.log("Old weather data cleared successfully.");
  } catch (error) {
    console.error("Error clearing old weather data:", error);
  }
};

db.backgroundImageSelections.getBackgroundImageUrl = async function () {
  const entry = await this.toCollection().first();
  return entry ? entry.imageUrl : null;
};

db.backgroundImageSelections.setBackgroundImageUrl = async function (imageUrl) {
  await this.clear();
  await this.add({ imageUrl });
};

export default db;
