import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { imageBlurState, imageFadeState, backgroundImageUrlState, backgroundSourceState, imageTypeState } from "../state/state";
import Switch from "./Switch";
import { saveUserPreference } from "../db/db";
import db from "../db/db";
import "../css/BackgroundSettings.css";

const BackgroundSettings = () => {
  const [blur, setBlur] = useRecoilState(imageBlurState);
  const [fade, setFade] = useRecoilState(imageFadeState);
  const setBackgroundImageUrl = useSetRecoilState(backgroundImageUrlState);
  const [backgroundSource, setBackgroundSource] = useRecoilState(backgroundSourceState);
  const [imageType, setImageType] = useRecoilState(imageTypeState);
  const [customImageType, setCustomImageType] = useState("");
  const [images, setImages] = useState([]);

  async function saveSetting(name, value) {
    const existing = await db.settings.where({ name }).first();
    if (existing) {
      await db.settings.update(existing.id, { value });
    } else {
      await db.settings.add({ name, value });
    }
  }

  async function getSetting(name) {
    const setting = await db.settings.where({ name }).first();
    return setting ? setting.value : null;
  }

  useEffect(() => {
    async function initializeSettings() {
      const blurIntensityValue = await getSetting("blurIntensity");
      const fadeIntensityValue = await getSetting("fadeIntensity");
      setBlur(Number(blurIntensityValue || 0));
      setFade(Number(fadeIntensityValue || 0) / 100);
    }
    initializeSettings();
  }, [setBlur, setFade]);

  const handleBlurIntensityChange = async (e) => {
    const newBlurValue = Number(e.target.value);
    setBlur(newBlurValue);
    await saveSetting("blurIntensity", newBlurValue);
  };

  const handleFadeIntensityChange = async (e) => {
    const newFadeValue = (e.target.value / 100) * 0.75;
    setFade(newFadeValue);
    await saveSetting("fadeIntensity", e.target.value);
  };

  const handleImageSelect = async (imageUrl) => {
    console.log("Selected Image URL:", imageUrl); // Log the selected image URL
    setBackgroundImageUrl(imageUrl);
    await db.backgroundImageSelections.setBackgroundImageUrl(imageUrl);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setBackgroundImageUrl(imageUrl);
      await db.backgroundImageSelections.setBackgroundImageUrl(imageUrl);
    }
  };

  const toggleBackgroundSource = (checked) => {
    const newSource = checked ? "unsplash" : "standard";
    setBackgroundSource(newSource);
    saveUserPreference("backgroundSource", newSource); // Save to IndexedDB
  };

  // const handleImageTypeChange = (e) => {
  //   const newType = e.target.value;
  //   setImageType(newType);
  //   saveUserPreference("imageType", newType); // Save to IndexedDB
  // };

  const handleCustomImageTypeChange = (e) => {
    const newType = e.target.value;
    setCustomImageType(newType);
  };

  const applyCustomImageType = () => {
    setImageType(customImageType);
    saveUserPreference("imageType", customImageType); // Save to IndexedDB
  };

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const imagesContext = require.context("../images/background", false, /\.(png|jpe?g|svg|webp)$/);
    const images = importAll(imagesContext);
    setImages(images);

    // const importAll = (r) => r.keys().map(r);
    // const imagesContext = require.context("../images/background", false, /\.(png|jpe?g|svg|webp)$/);
    // const images = importAll(imagesContext);
    // setImages(images);
  }, []);

  return (
    <div>
      <div className="settings-switch-container group">
        <label htmlFor="backgroundSourceSwitch">Custom Background</label>
        <Switch isChecked={backgroundSource === "unsplash"} onChange={toggleBackgroundSource} />
        <label htmlFor="backgroundSourceSwitch">Unsplash Background</label>
      </div>
      <hr />
      {backgroundSource === "unsplash" ? (
        <div className="effect-control unsplash">
          <h3 className="section-title">Choose a background image type...</h3>
          <div className="effect-item flexRow group">
            <div>
              <label htmlFor="natureSwitch">Nature</label>
              <Switch id="natureSwitch" isChecked={imageType === "nature"} onChange={() => setImageType("nature")} className="switch" />
            </div>
            <div>
              <label htmlFor="technologySwitch">Technology</label>
              <Switch id="technologySwitch" isChecked={imageType === "technology"} onChange={() => setImageType("technology")} className="switch" />
            </div>
            <div>
              <label htmlFor="architectureSwitch">Architecture</label>
              <Switch id="architectureSwitch" isChecked={imageType === "architecture"} onChange={() => setImageType("architecture")} className="switch" />
            </div>
            <div>
              <label htmlFor="peopleSwitch">People</label>
              <Switch id="peopleSwitch" isChecked={imageType === "people"} onChange={() => setImageType("people")} className="switch" />
            </div>
            <div>
              <label htmlFor="animalsSwitch">Animals</label>
              <Switch id="animalsSwitch" isChecked={imageType === "animals"} onChange={() => setImageType("animals")} className="switch" />
            </div>
          </div>
          <h3 className="section-title">Enter a custom filter (dog's, water, ocean)</h3>
          <div className="flex-row-not-centered group">
            {/* <h3>Custom Filter</h3> */}

            <div className="flexRow">
              <input id="customImageType" type="text" value={customImageType} onChange={handleCustomImageTypeChange} className="customImageType" />
              <button onClick={applyCustomImageType}>Apply</button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h3 className="section-title">Upload your own background image...</h3>
          <div className="upload-container ">
            <div className="upload-input-container">
              <input className="upload-input" id="uploadInput" type="file" onChange={handleImageUpload} />
            </div>
          </div>

          <h4 className="section-title">Choose a background image...</h4>
          <div className="background-images-container">
            {images.map((image, index) => (
              <img key={index} src={image} alt={`Background ${index}`} onClick={() => handleImageSelect(image)} />
            ))}
          </div>
        </>
      )}

      <h3 className="section-title">Choose a background image effect...</h3>
      <div className="effect-control">
        <div className="effect-item group">
          <label>Blur</label>
          <input type="range" min="0" max="5" step="0.1" value={blur} onChange={handleBlurIntensityChange} onMouseDown={(e) => e.stopPropagation()} />
        </div>
        <div className="effect-item group">
          <label>Fade</label>
          <input type="range" min="0" max="100" value={(fade / 0.75) * 100} onChange={handleFadeIntensityChange} onMouseDown={(e) => e.stopPropagation()} />
        </div>
      </div>
    </div>
  );
};

export default BackgroundSettings;
