import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuItem = ({ path, label, onClick, className, icon, width }) => {
  const style = {
    width: width || "auto",
  };

  const content = (
    <>
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{label}</span>
    </>
  );

  return path ? (
    <Link to={path} className={className} style={style}>
      {content}
    </Link>
  ) : (
    <button className={className} onClick={onClick} style={style}>
      {content}
    </button>
  );
};

export default MenuItem;
