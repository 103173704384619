// utils/importImages.js
export function importAllImages(r) {
  const images = r.keys().map((fileName) => {
    try {
      const src = fileName.replace("./", "");
      const name = fileName.replace("./", "").replace(/\.(webp|png|jpe?g|svg)$/, "");
      const path = r(fileName); // Get the actual webpack path
      // console.log('Importing image:', { fileName, src, name, path });
      return { src, name, path };
    } catch (error) {
      // console.error('Error importing image:', fileName, error);
      return null;
    }
  }).filter(Boolean);
  return images;
}

// Helper to get actual image path
export function getImagePath(imageName) {
  if (!imageName) return null;
  if (imageName.startsWith('data:') || imageName.startsWith('blob:')) return imageName;
  if (imageName.startsWith('/static/media/')) return imageName;
  
  try {
    // Try to get the image from the new-button-images directory
    return require(`../images/new-button-images/${imageName}`);
  } catch (error) {
    // console.warn(`Failed to load image: ${imageName}`);
    return null;
  }
}
