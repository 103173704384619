import React from "react";

const ButtonTable = ({ items, handleChange, handleDelete }) => {
  return (
    <div>
      <div className="flexRow" style={{ paddingLeft: "40px" }}>
        {/* <h4>Buttons</h4> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          paddingTop: "10px",
        }}
      >
        <table style={{ marginLeft: "20px" }}>
          <thead>
            <tr>
              <th>Id</th>
              <th>Title</th>
              <th>Grid X</th>
              <th>Grid Y</th>
              <th>Main ID</th>
              <th>Common ID</th>
              <th>Folder ID</th>

              <th>Url</th>
              <th>NewTab</th>
              <th>IconOption</th>
              <th>Icon</th>
              <th>IconFile</th>
              <th>Color</th>
              <th>State</th>
              <th>Visible</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td>
                  <p className="id">{item.id}</p>
                </td>
                <td>
                  <input
                    type="text"
                    style={{ width: "100px" }}
                    value={item.title || ""}
                    onChange={(e) => handleChange(item.id, "title", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.gridX || 0}
                    style={{ width: "40px" }}
                    onChange={(e) => handleChange(item.id, "gridX", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.gridY || 0}
                    style={{ width: "40px" }}
                    onChange={(e) => handleChange(item.id, "gridY", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.main_id || 0}
                    style={{ width: "40px", backgroundColor: "lightblue" }}
                    onChange={(e) => handleChange(item.id, "main_id", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.common_id || 0}
                    style={{ width: "55px", backgroundColor: "lightgreen" }}
                    onChange={(e) => handleChange(item.id, "common_id", e.target.value)}
                  />
                </td>{" "}
                <td>
                  <input
                    type="number"
                    value={item.folder_id || 0}
                    style={{ width: "55px" }}
                    onChange={(e) => handleChange(item.id, "folder_id", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.url || ""}
                    onChange={(e) => handleChange(item.id, "url", e.target.value)}
                    style={{ width: "230px", fontSize: "12px" }}
                  />
                </td>
                <td>
                  <select
                    value={item.newTab}
                    onChange={(e) => handleChange(item.id, "newTab", e.target.value === "true")}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    value={item.iconOption || ""}
                    onChange={(e) => handleChange(item.id, "iconOption", e.target.value)}
                    style={{ width: "80px", fontSize: "12px" }}
                  />
                </td>
                <td>
                  <input
                    type="url"
                    value={item.icon || ""}
                    onChange={(e) => handleChange(item.id, "icon", e.target.value)}
                    style={{ width: "200px", fontSize: "12px" }}
                  />
                </td>
                <td>
                  <input
                    type="url"
                    value={item.iconFile || ""}
                    onChange={(e) => handleChange(item.id, "iconFile", e.target.value)}
                    style={{ width: "180px", fontSize: "12px" }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    style={{ width: "80px", fontSize: "12px" }}
                    value={item.backgroundColor || ""}
                    onChange={(e) => handleChange(item.id, "backgroundColor", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    style={{ width: "80px", fontSize: "12px" }}
                    value={item.state || "active"}
                    onChange={(e) => handleChange(item.id, "state", e.target.value)}
                  />
                </td>
                <td>
                  <select
                    value={item.visible}
                    onChange={(e) => handleChange(item.id, "visible", e.target.value === "true")}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    value={item.type || "button"}
                    style={{ width: "50px" }}
                    onChange={(e) => handleChange(item.id, "type", e.target.value)}
                  />
                </td>
                <td style={{ width: "80px" }}>
                  <button className="delete-button" onClick={() => handleDelete(item.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ButtonTable;
