import { useRecoilCallback } from 'recoil';
import { itemsState } from '../state/state';
import db from '../db/db'; // Assuming db is imported from another file

// Grid dimensions
const GRID_DIMENSIONS = {
  main: { rows: 5, cols: 9 },
  folder: { rows: 4, cols: 6 },
  common: { rows: 1, cols: 8 }
};

// Check if position is valid for given grid type
const isValidPosition = (x, y, gridType) => {
  const dims = GRID_DIMENSIONS[gridType];
  return x >= 0 && x < dims.cols && y >= 0 && y < dims.rows;
};

// Find first available position in grid
const findFirstAvailable = (items, gridId, gridType) => {
  const dims = GRID_DIMENSIONS[gridType];
  if (!dims) {
    return { x: 0, y: 0 }; // Default to top-left if invalid grid type
  }
  
  // Create occupation matrix with proper dimensions
  const occupied = Array(dims.rows).fill(null).map(() => 
    Array(dims.cols).fill(false)
  );
  
  // Mark occupied positions
  items.forEach(item => {
    if (typeof item.gridX === 'number' && typeof item.gridY === 'number') {
      // Only mark if position is within bounds
      if (item.gridY < dims.rows && item.gridX < dims.cols) {
        if (
          (gridType === 'main' && Number(item.main_id) === Number(gridId)) ||
          (gridType === 'folder' && Number(item.folder_id) === Number(gridId)) ||
          (gridType === 'common' && Number(item.common_id) === Number(gridId))
        ) {
          occupied[item.gridY][item.gridX] = true;
        }
      }
    }
  });
  
  // Find first free position
  for (let y = 0; y < dims.rows; y++) {
    for (let x = 0; x < dims.cols; x++) {
      if (!occupied[y][x]) return { x, y };
    }
  }
  
  return null; // Grid is full
};

// Validate and correct item position
const validateItemPosition = (item, items) => {
  let gridType, gridId;
  
  // Determine grid type and ensure gridId is a number
  if (Number(item.folder_id) > 0) {
    gridType = 'folder';
    gridId = Number(item.folder_id);
  } else if (Number(item.common_id) > 0) {
    gridType = 'common';
    gridId = Number(item.common_id);
  } else {
    gridType = 'main';
    gridId = Number(item.main_id) || 1; // Default to grid 1 if invalid
  }
  
  // Ensure coordinates are numbers
  const x = Number(item.gridX);
  const y = Number(item.gridY);

  // Check if current position is valid
  if (!isNaN(x) && !isNaN(y) && isValidPosition(x, y, gridType)) {
    return item; // Position is valid
  }
  
  // Find new position in current grid
  let newPos = findFirstAvailable(items, gridId, gridType);
  
  // If current grid is full, handle overflow
  if (!newPos) {
    if (gridType === 'folder' || gridType === 'common') {
      // Move to main grid
      gridType = 'main';
      gridId = 1;
      newPos = findFirstAvailable(items, gridId, 'main');
    } else if (gridType === 'main') {
      // Try next main grid (up to 4)
      for (let nextGrid = gridId + 1; nextGrid <= 4; nextGrid++) {
        newPos = findFirstAvailable(items, nextGrid, 'main');
        if (newPos) {
          gridId = nextGrid;
          break;
        }
      }
    }
  }
  
  // If still no position found, use default position
  if (!newPos) {
    newPos = { x: 0, y: 0 };
  }
  
  return {
    ...item,
    gridX: newPos.x,
    gridY: newPos.y,
    main_id: gridType === 'main' ? gridId : 0,
    folder_id: gridType === 'folder' ? gridId : 0,
    common_id: gridType === 'common' ? gridId : 0
  };
};

// Custom hook for background validation
const useGridValidation = () => {
  return useRecoilCallback(({set, snapshot}) => async () => {
    const items = await snapshot.getPromise(itemsState);
    let hasChanges = false;
    const validatedItems = items.map(item => {
      try {
        const validatedItem = validateItemPosition(item, items);
        if (JSON.stringify(validatedItem) !== JSON.stringify(item)) {
          hasChanges = true;
          // Update in IndexedDB
          db.items.update(item.id, validatedItem).catch(() => {});
        }
        return validatedItem;
      } catch (error) {
        return item;
      }
    });
    
    if (hasChanges) {
      set(itemsState, validatedItems);
    }
  });
};

export {
  validateItemPosition,
  useGridValidation,
  isValidPosition,
  findFirstAvailable
};
