import { doc, setDoc, getDocs, collection, deleteDoc, writeBatch, query, where, orderBy } from "firebase/firestore";
import db from "../db/db";
import { db as firebaseDB } from "../firebaseConfig";
import { validateItemPosition } from "../utils/gridValidation";

// Sync status constants
const SYNC_STATUS = {
  PENDING: 'pending',
  SYNCED: 'synced',
  FAILED: 'failed'
};

// Clean data before syncing
function cleanData(item) {
  const cleanedItem = { ...item };
  Object.keys(cleanedItem).forEach((key) => {
    if (cleanedItem[key] === undefined) {
      cleanedItem[key] = null;
    }
  });
  return cleanedItem;
}

export const SyncService = {
  // Track if sync is in progress
  isSyncing: false,
  pendingSync: false,

  // Check if user can sync
  canUserSync(user) {
    console.log('Payment check for user:', user?.uid, 'Status:', user?.isPayingClient);
    return user?.isPayingClient === true;
  },

  updateLastSynced: async (timestamp) => {
    try {
      console.log("Updating last synced timestamp:", timestamp);
      await db.settings.put({ key: 'lastSynced', value: timestamp });
    } catch (error) {
      console.error("Failed to update last synced timestamp:", error);
    }
  },
  
  // Add an operation to the sync queue
  async addToSyncQueue(operation, itemId, data, user) {
    console.log('Adding to sync queue:', { operation, itemId });
    
    if (!this.canUserSync(user)) {
      console.log('Free user attempted sync operation');
      throw new Error("Sync is only available for premium users");
    }

    const queueItem = {
      operation,
      itemId,
      data: cleanData(data),
      timestamp: new Date().toISOString(),
      retries: 0
    };

    console.log('Queue item details:', queueItem);
    
    await db.syncQueue.add(queueItem);
    return this.processSyncQueue(user);
  },

  // Process the sync queue
  async processSyncQueue(user) {
    if (!this.canUserSync(user)) {
      console.error('Non-paying user attempted to process sync queue');
      throw new Error("Sync is only available for premium users");
    }

    if (this.isSyncing) {
      this.pendingSync = true;
      console.log('🔄 Sync already in progress, queuing request');
      return;
    }

    try {
      this.isSyncing = true;
      const queue = await db.syncQueue.toArray();
      
      if (queue.length === 0) {
        console.log('✅ No items in sync queue');
        return;
      }

      const operations = {
        create: [],
        update: [],
        delete: []
      };

      queue.forEach(item => {
        operations[item.operation].push(item);
      });

      let batch = writeBatch(firebaseDB);
      let batchCount = 0;

      for (const op of [...operations.create, ...operations.update]) {
        if (batchCount >= 500) {
          await batch.commit();
          batch = writeBatch(firebaseDB);
          batchCount = 0;
        }
        const ref = doc(firebaseDB, `users/${user.uid}/items/${op.itemId}`);
        batch.set(ref, cleanData(op.data), { merge: true });
        batchCount++;
      }

      for (const op of operations.delete) {
        if (batchCount >= 500) {
          await batch.commit();
          batch = writeBatch(firebaseDB);
          batchCount = 0;
        }
        const ref = doc(firebaseDB, `users/${user.uid}/items/${op.itemId}`);
        batch.delete(ref);
        batchCount++;
      }

      if (batchCount > 0) {
        await batch.commit();
      }

      await db.syncQueue.clear();

    } catch (error) {
      console.error('❌ Error processing sync queue:', error);
      throw error;
    } finally {
      this.isSyncing = false;
      if (this.pendingSync) {
        this.pendingSync = false;
        await this.processSyncQueue(user);
      }
    }
  },

  // Sync on login - new function
  async syncOnLogin(user) {
    if (!this.canUserSync(user)) return;
    
    console.log('🔄 Starting login sync...');
    
    try {
      // First process any pending local changes
      await this.processSyncQueue(user);
      
      // Get remote data
      const remoteRef = collection(firebaseDB, `users/${user.uid}/items`);
      const remoteSnapshot = await getDocs(remoteRef);
      const remoteItems = remoteSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      console.log(`📥 Found ${remoteItems.length} items in Firebase`);
      
      // Get local items
      const localItems = await db.items.toArray();
      console.log(`📦 Found ${localItems.length} items locally`);
      
      // Merge changes, preferring remote data on login
      const mergedItems = this.resolveConflicts(localItems, remoteItems, 'preferRemote');
      console.log(`🔄 Merged into ${mergedItems.length} items`);
      
      // Update local database in a single transaction
      await db.transaction('rw', db.items, async () => {
        await db.items.clear();
        await db.items.bulkAdd(mergedItems);
      });
      
      await this.updateLastSynced(new Date().toISOString());
      console.log('✅ Login sync completed successfully');
      
    } catch (error) {
      console.error('❌ Error during login sync:', error);
      throw error;
    }
  },

  // Modified conflict resolution
  resolveConflicts(localItems, remoteItems, preference = 'preferLocal') {
    const merged = new Map();
    
    const addToMap = (items, isRemote) => {
      items.forEach(item => {
        const existing = merged.get(item.id);
        if (!existing) {
          merged.set(item.id, item);
        } else if (
          preference === 'preferRemote' ? 
            isRemote || new Date(item.lastModified) > new Date(existing.lastModified) :
            !isRemote || new Date(item.lastModified) > new Date(existing.lastModified)
        ) {
          merged.set(item.id, item);
        }
      });
    };

    if (preference === 'preferRemote') {
      addToMap(remoteItems, true);
      addToMap(localItems, false);
    } else {
      addToMap(localItems, false);
      addToMap(remoteItems, true);
    }

    // Get merged items array
    const mergedItems = Array.from(merged.values());
    
    // Validate positions for all items
    const validatedItems = mergedItems.map(item => {
      // Skip validation for deleted items
      if (item.deleted) return item;
      return validateItemPosition(item, mergedItems);
    });

    return validatedItems;
  },

  // Keep existing syncAll for the sync button
  async syncAll(user) {
    console.log('🔄 Starting full sync operation...');
    try {
      await this.syncOnLogin(user); // Reuse login sync logic
      console.log('✅ Full sync completed successfully');
    } catch (error) {
      console.error('❌ Error during sync:', error);
      throw error;
    }
  },

  // Handle deleted items
  async handleDeletedItems(user) {
    const deletedItems = await db.deletedItems
      .where('syncStatus')
      .equals(SYNC_STATUS.PENDING)
      .toArray();

    for (const item of deletedItems) {
      try {
        await deleteDoc(doc(firebaseDB, `users/${user.uid}/items/${item.originalId}`));
        await db.deletedItems.update(item.id, { syncStatus: SYNC_STATUS.SYNCED });
      } catch (error) {
        console.error(`Failed to delete item ${item.originalId}:`, error);
        await db.deletedItems.update(item.id, { syncStatus: SYNC_STATUS.FAILED });
      }
    }
  }
};

export default SyncService;
