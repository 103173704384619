// commenGrid.js
import React, { useState, useEffect } from "react";
import db from "../db";
// import "../css/DesktopButtonsList.css";

function CommonGridList() {
  const [commonGrid, setCommonGrid] = useState([]);

  useEffect(() => {
    loadGrids();
  }, []);

  const loadGrids = async () => {
    try {
      const loadedGrids = await db.commonGrids.toArray();
      console.log("Loaded Grids:", loadedGrids);
      setCommonGrid(loadedGrids);
    } catch (error) {
      console.error("Error loading grids:", error);
    }
  };

  const handleAddGrid = async () => {
    try {
      await db.commonGrids.add({
        title: "common",
        sizeX: 10,
        sizeY: 1,
        type: "commonGrid",
        style: "",
        visible: true,
        state: "active",
        settings: ""
      });
      loadGrids();
    } catch (error) {
      console.error("Error adding new Grid:", error);
    }
  };

  const handleChange = async (id, field, value) => {
    if (typeof id === "undefined" || id === null) {
      console.error("Invalid ID provided for update:", id);
      return;
    }

    console.log("Updating:", field, "with:", value, "for ID:", id);

    const isGridField = field === "gridX" || field === "gridY" || field === "gridD";
    const updatedValue = isGridField ? Number(value) : value;

    try {
      await db.commonGrids.update(id, { [field]: updatedValue });
      loadGrids(); // Reload desktop grids to reflect the changes
    } catch (error) {
      console.error("Error updating record:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await db.commonGrids.delete(id);
      loadGrids(); // Reload desktop Grids to reflect the deletion
    } catch (error) {
      console.error("Error deleting grid:", error);
    }
  };

  return (
    <div className="desktop-grids-list">
      <div className="flexRow">
        <h4>Common Grids</h4>
        <button className="button" onClick={handleAddGrid}>
          Add Grid
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>Title</th>
            <th>Size X</th>
            <th>Size Y</th>
            <th>Parent</th>
            <th>style</th>
            <th>Visible</th>
            <th>State</th>
            <th>Settings</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {commonGrid.map((commonGrid) => (
            <tr key={commonGrid.id}>
              <td>
                <p className="id">{commonGrid.id}</p>
              </td>
              <td>
                <input type="text" value={commonGrid.title || "new grid"} onChange={(e) => handleChange(commonGrid.id, "title", e.target.value)} />
              </td>
              <td>
                <input
                  type="number"
                  value={commonGrid.sizeX || 8}
                  style={{ width: "50px" }}
                  onChange={(e) => handleChange(commonGrid.id, "sizeX", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={commonGrid.sizeY || 6}
                  style={{ width: "50px" }}
                  onChange={(e) => handleChange(commonGrid.id, "sizeY", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={commonGrid.parent || ""}
                  style={{ width: "100px" }}
                  onChange={(e) => handleChange(commonGrid.id, "parent", e.target.value)}
                />
              </td>
              <td>
                <input type="text" value={commonGrid.style || ""} onChange={(e) => handleChange(commonGrid.id, "style", e.target.value)} />
              </td>
              <td>
                <input
                  type="text"
                  value={commonGrid.visible || "visible"}
                  style={{ width: "50px" }}
                  onChange={(e) => handleChange(commonGrid.id, "visible", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={commonGrid.state || "active"}
                  style={{ width: "50px" }}
                  onChange={(e) => handleChange(commonGrid.id, "state", e.target.value)}
                />
              </td>
              <td>
                <input type="text" value={commonGrid.settings || ""} onChange={(e) => handleChange(commonGrid.id, "settings", e.target.value)} />
              </td>
              <td>
                <input
                  type="text"
                  value={commonGrid.type || "grid"}
                  style={{ width: "50px" }}
                  onChange={(e) => handleChange(commonGrid.id, "type", e.target.value)}
                />
              </td>
              <td>
                <button className="button" onClick={() => handleDelete(commonGrid.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CommonGridList;
