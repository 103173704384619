import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";
import { useRecoilValue, useRecoilState } from "recoil";
import { isAdminState, userState, handleLogoutState, deleteMode } from "../state/appState";
import "../css/DropDownMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faBars, 
  faTrash, 
  faPlus, 
  faCog, 
  faHome,
  faSignInAlt,
  faUserPlus,
  faSignOutAlt,
  faImage,
  faBug,
  faSync
} from "@fortawesome/free-solid-svg-icons";
import { collection, getDocs, query } from "firebase/firestore";
import { db as firebaseDB } from "../firebaseConfig";
import db from "../db/db";
import AddItemForm from "./AddItemForm";
import PropTypes from "prop-types";
import SyncButton from "./SyncButton";
import NotLoggedInPopup from "./NotLoggedInPopup";

const DropdownMenu = ({
  currentPath,
  toggleReportIssueModal,
  toggleSettings,
  openEditButtonsModal,
  openEditWidgetsModal,
  toggleScreenSizeDisplay,
  reloadItems,
  showAddItemForm,
  setShowAddItemForm,
  handleAddItem
}) => {
  const isAdmin = useRecoilValue(isAdminState);
  const user = useRecoilValue(userState);
  const handleLogout = useRecoilValue(handleLogoutState);
  const [isDeleteMode, setIsDeleteMode] = useRecoilState(deleteMode);
  const [syncStatus, setSyncStatus] = useState("");
  const [showNotLoggedInPopup, setShowNotLoggedInPopup] = useState(false);

  const resolvedPath = currentPath || window.location.pathname;

  const handleReportIssueClick = () => {
    if (!user) {
      setShowNotLoggedInPopup(true);
    } else {
      toggleReportIssueModal();
    }
  };

  const closeNotLoggedInPopup = () => {
    setShowNotLoggedInPopup(false);
  };

  const handleExternalRedirect = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="dropdown">
      <button className="menu-button">
        <FontAwesomeIcon icon={faBars} className="menu-icon" />
      </button>
      {isDeleteMode && (
        <button 
          className="done-button" 
          onClick={() => setIsDeleteMode(false)}
        >
          Done
        </button>
      )}
      <div className="dropdown-content">
        <div className="menu-section">
          <h3>Navigation</h3>
          {resolvedPath !== "/" && (
            <MenuItem className="menu-item" path="/" label="Grid" icon={faCog} />
          )}
          {resolvedPath !== "/home" && (
            <MenuItem
              className="menu-item"
              label="Home"
              icon={faHome}
              onClick={() => handleExternalRedirect("https://www.myhomepage.ai")}
            />
          )}
          {!user && resolvedPath !== "/register" && (
            <MenuItem className="menu-item" path="/register" label="Register" icon={faUserPlus} />
          )}
          {!user && resolvedPath !== "/login" && (
            <MenuItem className="menu-item" path="/login" label="Login" icon={faSignInAlt} />
          )}
          {user && (
            <MenuItem className="menu-item" label="Logout" onClick={handleLogout} icon={faSignOutAlt} />
          )}
        </div>

        {/* Button Management Section */}
        {resolvedPath === "/" && (
          <div className="menu-section button-management">
            <h3>Button Management</h3>
            <MenuItem
              className="menu-item"
              icon={faPlus}
              label="Add Buttons"
              onClick={handleAddItem}
            />
            <MenuItem
              className="menu-item"
              icon={faCog}
              label="Manage Buttons"
              onClick={openEditButtonsModal}
            />
            <MenuItem
              className="menu-item"
              icon={faCog}
              label="Manage Widgets"
              onClick={openEditWidgetsModal}
            />
            <MenuItem
              icon={faTrash}
              label={isDeleteMode ? "Exit Delete Mode" : "Delete Buttons"}
              onClick={() => setIsDeleteMode(!isDeleteMode)}
              className={isDeleteMode ? "menu-item active" : "menu-item"}
            />
          </div>
        )}

        {/* Background Section */}
        {resolvedPath === "/" && (
          <div className="menu-section">
            <h3>Background</h3>
            <MenuItem
              className="menu-item"
              label="Background..."
              onClick={toggleSettings}
              icon={faImage}
            />
          </div>
        )}

        {/* Report Section */}
        <div className="menu-section">
          <h3>Report</h3>
          <MenuItem
            className="menu-item"
            label="Report an Issue"
            onClick={handleReportIssueClick}
            icon={faBug}
          />
        </div>

        {/* Sync Section */}
        <div className="menu-section">
          <h3>Sync</h3>
          <SyncButton user={user} setSyncStatus={setSyncStatus} icon={faSync} />
        </div>

        {/* Admin Section */}
        {isAdmin && (
          <div className="menu-section admin-section">
            <h3>Admin</h3>
            <MenuItem className="menu-item" path="/admin" label="Admin" />
            <MenuItem className="menu-item" path="/issue-reports" label="Reports" />
          </div>
        )}
      </div>
      {showNotLoggedInPopup && <NotLoggedInPopup onClose={closeNotLoggedInPopup} />}
      {showAddItemForm && (
        <AddItemForm
          showForm={showAddItemForm}
          setShowForm={setShowAddItemForm}
          onClose={() => setShowAddItemForm(false)}
          onCreateItem={reloadItems}
          position={null}
        />
      )}
    </div>
  );
};

// Prop validation
DropdownMenu.propTypes = {
  currentPath: PropTypes.string.isRequired,
  toggleReportIssueModal: PropTypes.func,
  toggleSettings: PropTypes.func,
  openEditButtonsModal: PropTypes.func,
  openEditWidgetsModal: PropTypes.func,
  toggleScreenSizeDisplay: PropTypes.func,
  reloadItems: PropTypes.func,
  showAddItemForm: PropTypes.bool,
  setShowAddItemForm: PropTypes.func,
  handleAddItem: PropTypes.func
};

export default DropdownMenu;
