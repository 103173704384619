import React from "react";
import "../css/Switch.css";

function Switch({ isChecked, onChange }) {
  return (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={(e) => onChange(e.target.checked)} />
      <span className="slider round" />
    </label>
  );
}

export default Switch;
