import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { deleteMode } from "../state/appState";
import { itemsState } from "../state/state";
import DroppableContainer from "./DroppableContainer";
import DraggableItem from "./DraggableItem";
import PlaceholderDropZone from "./PlaceholderDropZone";
import db, { deleteItemWithSync } from "../db/db";
import { validateItemPosition, useGridValidation } from '../utils/gridValidation';

const GridItems = ({ items, gridId, handleOpenFolder, onEdit, isDragging, handleIconClick, showTransientMessage, user }) => {
  const isDeleteMode = useRecoilValue(deleteMode);
  const [, setItems] = useRecoilState(itemsState);
  const runBackgroundValidation = useGridValidation();

  // Run validation on mount and when items change
  useEffect(() => {
    if (items?.length > 0) {
      runBackgroundValidation();
    }
  }, [items, runBackgroundValidation]);

  const handleDelete = async (item) => {
    try {
      // Check if it's a folder with items
      if (item.type === "folder") {
        const folderItems = await db.items.where("folder_id").equals(item.id).toArray();
        if (folderItems.length > 0) {
          showTransientMessage({ id: item.id, message: "Remove all items before deleting folder" });
          return;
        }
      }

      // Delete with sync
      await deleteItemWithSync(item.id, user);
      
      // Update the Recoil state to remove the item
      setItems(currentItems => currentItems.filter(i => i.id !== item.id));
    } catch (error) {
      console.error("Error deleting item:", error);
      showTransientMessage({ id: item.id, message: "Error deleting item" });
    }
  };

  const renderGridItems = () => {
    const itemsInGrid = items.filter(
      (item) => item.main_id?.toString() === gridId.toString() && 
                item.common_id?.toString() === "0" && 
                item.folder_id?.toString() === "0" && 
                item.visible
    ).map(item => {
      try {
        return validateItemPosition(item, items);
      } catch (error) {
        console.error(`Validation error for item ${item.id}:`, error);
        return item;
      }
    });

    const placeholders = [];

    for (let y = 0; y < 5; y++) {
      for (let x = 0; x < 9; x++) {
        const item = itemsInGrid.find((item) => item.gridX === x && item.gridY === y);
        if (item) {
          if (item.type === "link") {
            placeholders.push(
              <DroppableContainer
                key={`item-${item.id}`}
                id={`item-${item.id}`}
                className="grid-item"
                gridX={x}
                gridY={y}
                gridId={"main"}
                main_id={gridId}
                common_id={0}
                folder_id={0}
                type={item.type}
              >
                {/* <Link to={item.link}>{item.text}</Link> */}
                <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>
              </DroppableContainer>
            );
          } else if (item.type === "folder" || item.type === "widget" || item.type === "button") {
            placeholders.push(
              <DroppableContainer
                key={`item-${item.id}`}
                id={`item-${item.id}`}
                className="grid-item"
                gridX={x}
                gridY={y}
                gridId={"main"}
                main_id={gridId}
                common_id={0}
                folder_id={0}
                type={item.type}
              >
                <div>
                  <DraggableItem 
                    item={item} 
                    onOpenFolder={handleOpenFolder} 
                    onEdit={onEdit} 
                    onClick={handleIconClick}
                    isDeleteMode={isDeleteMode}
                    onDelete={handleDelete}
                  />
                  <div className="grid-item-text">{item.text}</div>
                </div>
              </DroppableContainer>
            );
          } else {
            placeholders.push(
              <DroppableContainer
                key={`item-${item.id}`}
                id={`item-${item.id}`}
                className="grid-item"
                gridX={x}
                gridY={y}
                gridId={"main"}
                main_id={gridId}
                common_id={0}
                folder_id={0}
                type={item.type}
              >
                <DraggableItem 
                  item={item} 
                  onOpenFolder={handleOpenFolder} 
                  onEdit={onEdit} 
                  onClick={handleIconClick}
                  isDeleteMode={isDeleteMode}
                  onDelete={handleDelete}
                />
              </DroppableContainer>
            );
          }
        } else {
          placeholders.push(
            <DroppableContainer
              key={`placeholder-${x}-${y}`}
              id={`placeholder-${x}-${y}`}
              className={`grid-item placeholder ${isDragging ? "dragging" : ""}`}
              gridX={x}
              gridY={y}
              gridId={"main"}
              main_id={gridId}
              common_id={0}
              folder_id={0}
              type="placeholder"
            >
              <PlaceholderDropZone
                gridX={x}
                gridY={y}
                gridId={"main"}
                main_id={gridId}
                common_id={0}
                folder_id={0}
                reloadItems={() => setItems(prev => [...prev])}
              />
            </DroppableContainer>
          );
        }
      }
    }

    return placeholders;
  };

  return <>{renderGridItems()}</>;
};

export default GridItems;
//working 080724
