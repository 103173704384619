import React, { useState, useEffect, useRef } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { itemsState, gridsState, currentGridIdState } from "../state/state";
import { userState } from "../state/appState";
import db, { addItemWithSync } from "../db/db";
import "../css/AddItemForm.css";
import { importAllImages } from "../utils/importImages";
import { ChromePicker } from "react-color";
import closeButton from "../images/app-buttons/close.png";
import { resolveConflicts } from "./DndProvider";
import { Popover } from "@mui/material";
import { commonColors, colorNames } from "../utils/commonColors";

const AddItemForm = ({ 
  showForm, 
  setShowForm, 
  initialUrl,
  initialName, 
  initialGridX, 
  initialGridY, 
  reloadItems,
  main_id,
  folder_id = 0,
  common_id = 0
}) => {
  // State Variables
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [iconOption, setIconOption] = useState("defaultIcon");
  const [uploadedIcon, setUploadedIcon] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [badgeText, setBadgeText] = useState("");
  const [borderColor, setBorderColor] = useState("#000000");
  const [newTabUrl, setNewTabUrl] = useState(true);
  const [selectedGrid, setSelectedGrid] = useState(null);
  const [availableImages, setAvailableImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [matchingImages, setMatchingImages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [bgColorAnchor, setBgColorAnchor] = useState(null);
  const [textColorAnchor, setTextColorAnchor] = useState(null);
  const [itemType, setItemType] = useState("button");

  // Refs
  const containerRef = useRef(null);
  const imageMatchesRef = useRef(null);

  // Recoil State
  const setItems = useSetRecoilState(itemsState);
  const grids = useRecoilValue(gridsState);
  const currentGridId = useRecoilValue(currentGridIdState);
  const user = useRecoilValue(userState);

  const effectiveMainId = main_id !== undefined ? main_id : currentGridId;

  // Initialize URL and Title
  useEffect(() => {
    if (initialUrl) setUrl(initialUrl);
    if (initialName) setTitle(initialName);
    if (folder_id > 0) setItemType("folder");
  }, [initialUrl, initialName, folder_id]);

  useEffect(() => {
    setSelectedGrid(currentGridId);
  }, [currentGridId]);

  // Set Form Container Height
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.maxHeight = "80%";
    }
  }, [showForm]);

  // Load Available Images
  useEffect(() => {
    const images = importAllImages(
      require.context("../images/new-button-images", false, /\.(webp|png|jpe?g|svg)$/)
    );
    setAvailableImages(images);
  }, []);

  // Update Matching Images Based on Search Text
  useEffect(() => {
    if (searchText) {
      const matches = availableImages.filter((image) =>
        image.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setMatchingImages(matches);
    } else {
      setMatchingImages(availableImages);
    }
  }, [searchText, availableImages]);

  // Adjust Dynamic Height for Matching Images
  useEffect(() => {
    const adjustHeight = () => {
      const modal = containerRef.current;
      const imageMatches = imageMatchesRef.current;

      if (!modal || !imageMatches) return;

      requestAnimationFrame(() => {
        try {
          const modalHeight = modal.clientHeight;
          if (!modalHeight) return;

          const headerHeight = 48;
          const inputsHeight = 48;
          const buttonsHeight = 48;

          const availableHeight = modalHeight - (headerHeight + inputsHeight + buttonsHeight + 30);
          if (availableHeight > 0) {
            imageMatches.style.height = `${availableHeight}px`;
          }
        } catch (error) {
          console.error('Error adjusting height:', error);
        }
      });
    };

    if (showForm) {
      const timeout = setTimeout(adjustHeight, 100);
      window.addEventListener("resize", adjustHeight);

      return () => {
        clearTimeout(timeout);
        window.removeEventListener("resize", adjustHeight);
      };
    }
  }, [showForm, iconOption]);

  // Handle Image Selection
  const handleImageSelection = (url) => {
    const imagePath = require(`../images/new-button-images/${url}`);
    setSelectedImage({ src: url, path: imagePath });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const nextPosition = initialGridX !== undefined && initialGridY !== undefined
        ? { gridX: initialGridX, gridY: initialGridY }
        : await findNextAvailablePositionInGrid(
            selectedGrid || main_id || 1,
            common_id,
            folder_id
          );

      let newItem;
      
      if (itemType === "folder") {
        newItem = {
          id: Date.now(),
          title: title || "Folder",
          type: "folder",
          visible: true,
          main_id: selectedGrid || main_id || 1,
          common_id: common_id || 0,
          folder_id: folder_id || 0,
          gridX: nextPosition.gridX,
          gridY: nextPosition.gridY,
          lastModified: new Date().toISOString()
        };
      } else {
        newItem = {
          id: Date.now(),
          title,
          url,
          type: "button",
          iconOption,
          icon: null,
          iconImage: selectedImage?.path || null,
          iconFile: uploadedIcon,
          backgroundColor,
          borderColor,
          badgeText,
          newTabUrl: true,
          visible: true,
          main_id: selectedGrid || main_id || 1,
          common_id: common_id || 0,
          folder_id: folder_id || 0,
          gridX: nextPosition.gridX,
          gridY: nextPosition.gridY,
          lastModified: new Date().toISOString()
        };
      }

      
      await addItemWithSync(newItem, user);

      const updatedItems = await db.items.toArray();
      
      const validatedItems = updatedItems.map(item => ({
        ...item,
        gridX: parseInt(item.gridX) || 0,
        gridY: parseInt(item.gridY) || 0,
        main_id: parseInt(item.main_id) || 0,
        folder_id: parseInt(item.folder_id) || 0,
        common_id: parseInt(item.common_id) || 0
      }));

      const MAX_ATTEMPTS = 45;
      const resolvedItems = resolveConflicts(validatedItems, MAX_ATTEMPTS);

      await db.items.bulkPut(resolvedItems);
      setItems(resolvedItems);

      if (reloadItems) reloadItems();
      setShowForm(false);
    } catch (error) {
      console.error("Error adding new item:", error);
      if (error.message.includes('premium')) {
        alert("Sync is only available for premium users. Your item was saved locally.");
      } else {
        alert("Error adding new item: " + error.message);
      }
    }
  };

  // Find Next Available Position in Grid
  const findNextAvailablePositionInGrid = async (main_id, common_id, folder_id) => {
    const gridItems = await db.items
      .where("[main_id+common_id+folder_id]")
      .equals([main_id, common_id, folder_id])
      .toArray();

    const positions = new Set(gridItems.map((item) => `${item.gridX}-${item.gridY}`));

    for (let y = 0; y < 5; y++) {
      for (let x = 0; x < 9; x++) {
        if (!positions.has(`${x}-${y}`)) {
          return { gridX: x, gridY: y };
        }
      }
    }

    return { gridX: 0, gridY: 0 };
  };

  return (
    showForm && (
      <div className="add-item-form" ref={containerRef}>
        <div className="app-header">
          <h3 className="form-title">Add New Item</h3>
          <button type="button" onClick={() => setShowForm(false)} className="btn-close">
            <img src={closeButton} alt="Close" />
          </button>
        </div>

        <div className="item-inputs">
          <div className="input-label">
            <p>Item Title</p>
            <input
              type="text"
              value={title}
              className="edit-input"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
            />
          </div>

          <div className="input-label">
            <p>Item Url</p>
            <input
              type="text"
              value={url}
              className="edit-input"
              onChange={(e) => setUrl(e.target.value)}
              placeholder="URL"
            />
          </div>

          <div className="input-label">
            <p>Select Grid:</p>
            <select
              id="grid-selection"
              className="icon-select"
              value={selectedGrid}
              onChange={(e) => setSelectedGrid(parseInt(e.target.value))}
            >
              {grids.map((grid) => (
                <option key={grid.gridId} value={grid.gridId}>
                  {grid.title}
                </option>
              ))}
            </select>
          </div>
          {iconOption === "defaultIcon" && (
            <div className="input-label">
              <p>Search for Icons </p>
              <input
                type="text"
                value={searchText}
                className="edit-input"
                style={{ color: "red" }}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search icons"
              />
            </div>
          )}

          <div className="input-label">
            <p>Icon Style</p>
            <select
              value={iconOption}
              onChange={(e) => setIconOption(e.target.value)}
              className="icon-select"
            >
              <option value="defaultIcon">Default Icon</option>
              <option value="backgroundColor">Background Color</option>
              <option value="textBadge">Text Badge</option>
              <option value="uploadedIcon">Upload New Icon</option>
              {/* <option value="none">None</option> */}
            </select>
          </div>
        </div>

        {iconOption === "defaultIcon" && matchingImages.length > 0 && (
          <div className="matching-images-container">
            <div className="image-matches" ref={imageMatchesRef}>
              {matchingImages.map((image) => {
                const imagePath = require(`../images/new-button-images/${image.src}`);
                return (
                  <img
                    key={`${image.name}-${imagePath}`}
                    src={imagePath}
                    alt={image.name}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "5px",
                      border:
                        selectedImage && selectedImage.src === image.src ? "2px solid blue" : "none",
                    }}
                    onClick={() => handleImageSelection(image.src)}
                  />
                );
              })}
            </div>
          </div>
        )}

        {iconOption === "uploadedIcon" && (
          <div className="upload">
            <input type="file" onChange={(e) => setUploadedIcon(e.target.files[0])} />
            {uploadedIcon && (
              <img
                src={URL.createObjectURL(uploadedIcon)}
                alt="Icon preview"
                style={{ width: "50px", height: "50px" }}
              />
            )}
          </div>
        )}

        {iconOption === "textBadge" && (
          <div className="form-group">
            <div className="input-label" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
              <label>Badge Text</label>
              <input
                type="text"
                value={badgeText}
                onChange={(e) => setBadgeText(e.target.value.slice(0, 5))}
                maxLength={5}
                placeholder="Badge"
                style={{ width: "80px", textAlign: "center" }}
              />
            </div>

            <div style={{ width: "100%", marginBottom: "20px" }}>
              <label style={{ display: "block", textAlign: "center", marginBottom: "10px" }}>Background Color</label>
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "5px", marginBottom: "10px" }}>
                {commonColors.map((color) => (
                  <div
                    key={color}
                    title={colorNames[color]}
                    onClick={() => setBackgroundColor(color)}
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: color,
                      border: backgroundColor === color ? "2px solid #000" : "1px solid #ccc",
                      borderRadius: "4px",
                      cursor: "pointer",
                      boxShadow: backgroundColor === color ? "0 0 5px rgba(0,0,0,0.5)" : "none"
                    }}
                  />
                ))}
                <div
                  title="Custom Color"
                  onClick={(e) => setBgColorAnchor(e.currentTarget)}
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "linear-gradient(45deg, #f00 0%, #ff0 20%, #0f0 40%, #0ff 60%, #00f 80%, #f0f 100%)",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <span style={{ fontSize: "16px", color: "#fff", textShadow: "0 0 2px #000" }}>+</span>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <label style={{ display: "block", textAlign: "center", marginBottom: "10px" }}>Text Color</label>
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "5px", marginBottom: "10px" }}>
                {commonColors.map((color) => (
                  <div
                    key={color}
                    title={colorNames[color]}
                    onClick={() => setBorderColor(color)}
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: color,
                      border: borderColor === color ? "2px solid #000" : "1px solid #ccc",
                      borderRadius: "4px",
                      cursor: "pointer",
                      boxShadow: borderColor === color ? "0 0 5px rgba(0,0,0,0.5)" : "none"
                    }}
                  />
                ))}
                <div
                  title="Custom Color"
                  onClick={(e) => setTextColorAnchor(e.currentTarget)}
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "linear-gradient(45deg, #f00 0%, #ff0 20%, #0f0 40%, #0ff 60%, #00f 80%, #f0f 100%)",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <span style={{ fontSize: "16px", color: "#fff", textShadow: "0 0 2px #000" }}>+</span>
                </div>
              </div>
            </div>

            <Popover
              open={Boolean(bgColorAnchor)}
              anchorEl={bgColorAnchor}
              onClose={() => setBgColorAnchor(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{ padding: "10px" }}>
                <ChromePicker
                  color={backgroundColor}
                  onChangeComplete={(color) => {
                    setBackgroundColor(color.hex);
                    setBgColorAnchor(null);
                  }}
                  disableAlpha={true}
                />
              </div>
            </Popover>

            <Popover
              open={Boolean(textColorAnchor)}
              anchorEl={textColorAnchor}
              onClose={() => setTextColorAnchor(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{ padding: "10px" }}>
                <ChromePicker
                  color={borderColor}
                  onChangeComplete={(color) => {
                    setBorderColor(color.hex);
                    setTextColorAnchor(null);
                  }}
                  disableAlpha={true}
                />
              </div>
            </Popover>
          </div>
        )}

        {iconOption === "backgroundColor" && (
          <div className="form-group color-picker-container color-picker-container-main">
            <label htmlFor="backgroundColor">Background Color:</label>
            <ChromePicker
              color={backgroundColor}
              onChangeComplete={(color) => setBackgroundColor(color.hex)}
              disableAlpha={true}
            />
          </div>
        )}

        <div className="form-buttons" style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <button style={{ padding: '8px 16px' }} onClick={handleSubmit}>Add Item</button>
          <button style={{ padding: '8px 16px' }} onClick={(e) => { 
            e.preventDefault(); 
            const newItem = {
              id: Date.now(),
              title: title || "New Folder",
              type: "folder",
              visible: true,
              main_id: selectedGrid || main_id || 1,
              common_id: common_id || 0,
              folder_id: folder_id || 0,
              gridX: initialGridX,
              gridY: initialGridY,
              lastModified: new Date().toISOString()
            };
            addItemWithSync(newItem, user)
              .then(() => {
                return db.items.toArray();
              })
              .then(updatedItems => {
                const validatedItems = updatedItems.map(item => ({
                  ...item,
                  gridX: parseInt(item.gridX) || 0,
                  gridY: parseInt(item.gridY) || 0,
                  main_id: parseInt(item.main_id) || 0,
                  folder_id: parseInt(item.folder_id) || 0,
                  common_id: parseInt(item.common_id) || 0
                }));
                const MAX_ATTEMPTS = 45;
                const resolvedItems = resolveConflicts(validatedItems, MAX_ATTEMPTS);
                return db.items.bulkPut(resolvedItems).then(() => resolvedItems);
              })
              .then(resolvedItems => {
                setItems(resolvedItems);
                if (reloadItems) reloadItems();
                setShowForm(false);
              })
              .catch(error => {
                console.error("Error adding new folder:", error);
                if (error.message.includes('premium')) {
                  alert("Sync is only available for premium users. Your item was saved locally.");
                } else {
                  alert("Error adding new folder: " + error.message);
                }
              });
          }}>Add Folder</button>
          <button style={{ padding: '8px 16px' }} onClick={() => setShowForm(false)}>Cancel</button>
        </div>
      </div>
    )
  );
};

export default AddItemForm;
