import React, { useState, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { itemsState, widgetVisibilityState } from "../state/state";
import DraggableCloseableHeader from "./DraggableCloseableHeader";
import db from "../db/db";
import "../css/WidgetContainer.css";

const WidgetContainer = ({ item, handleCreateItem }) => {
  const [, setItems] = useRecoilState(itemsState);
  const [widgetVisibility, setWidgetVisibility] = useRecoilState(widgetVisibilityState);
  const [position, setPosition] = useState({ x: item.screenX, y: item.screenY });
  const [WidgetComponent, setWidgetComponent] = useState(null);
  const widgetRef = useRef(null);
  const dragOffset = useRef({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  // useEffect(() => {
  //   console.log(`WidgetContainer mounted for item ${item.id}`);
  //   return () => {
  //     console.log(`WidgetContainer unmounted for item ${item.id}`);
  //   };
  // }, []);

  useEffect(() => {
    if (item.activationCode) {
      const adjustedActivationCode = item.activationCode.replace(/^(\.\/|widgets\/)/, "");
      import(`../widgets/${adjustedActivationCode}`)
        .then(({ default: Component }) => {
          setWidgetComponent(() => Component);
        })
        .catch((err) => console.error("Failed to load widget:", err));
    }
  }, [item.activationCode]);

  const handleMouseDown = (e) => {
    const rect = widgetRef.current.getBoundingClientRect();
    dragOffset.current = { x: e.clientX - rect.left, y: e.clientY - rect.top };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    setIsDragging(true);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    const newX = e.clientX - dragOffset.current.x;
    const newY = e.clientY - dragOffset.current.y;
    setPosition({ x: newX, y: newY });
  };

  const handleMouseUp = async () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    setIsDragging(false);

    const updatedItem = { ...item, screenX: position.x, screenY: position.y };
    setItems((prevItems) => prevItems.map((i) => (i.id === item.id ? updatedItem : i)));
    await db.items.put(updatedItem);
  };

  const handleClose = async () => {
    const updatedItem = { ...item, widgetVisible: false };
    setItems((prevItems) => prevItems.map((i) => (i.id === item.id ? updatedItem : i)));
    await db.items.put(updatedItem);
    setWidgetVisibility((prevVisibility) => ({
      ...prevVisibility,
      [item.id]: false
    }));
  };

  if (!widgetVisibility[item.id]) {
    return null;
  }

  const WidgetProps = {
    item,
    handleCreateItem
    // ...other props if needed
  };

  return (
    <div ref={widgetRef} className={`widget-container ${isDragging ? "dragging" : ""}`} style={{ left: `${position.x}px`, top: `${position.y}px` }}>
      <DraggableCloseableHeader title={item.title} onMouseDown={handleMouseDown} onClose={handleClose} />
      <div className="widget-content">{WidgetComponent ? <WidgetComponent {...WidgetProps} /> : <p>Loading widget...</p>}</div>
    </div>
  );
};

export default WidgetContainer;
