import React, { useState } from "react";
import "../css/UploadGrids.css";

const UploadGrid = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newGrid = { title, description };

    // Send grid data to the backend (or directly to Firestore)
    const response = await fetch("/api/upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newGrid)
    });

    if (response.ok) {
      alert("Grid uploaded successfully!");
    } else {
      alert("Failed to upload grid.");
    }
  };

  return (
    <div className="upload-grids">
      <h2>Upload a New Grid</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title:</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div>
          <label>Description:</label>
          <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </div>
        <button type="submit">Upload Grid</button>
      </form>
    </div>
  );
};

export default UploadGrid;
