import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { itemsState } from "../state/state";
import { deleteMode, userState } from "../state/appState";
import db, { deleteItemWithSync } from "../db/db";
import DraggableItem from "./DraggableItem";
import DroppableContainer from "./DroppableContainer";
import PlaceholderDropZone from "./PlaceholderDropZone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import closeButton from "../images/app-buttons/close.png";
import TransientMessage from "../components/TransientMessage";
import "../css/Folder.css";

const Folder = ({ folder, onClose, onEdit, isDragging }) => {
  const [title, setTitle] = useState(folder.title);
  const [items, setItems] = useRecoilState(itemsState);
  const [folderItems, setFolderItems] = useState([]);
  const [isDeleteMode] = useRecoilState(deleteMode);
  const user = useRecoilValue(userState);  // Move hook to component level
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchFolderItems = async () => {
      const allItems = await db.items.toArray();
      const folderItems = allItems.filter(
        (item) => item.folder_id?.toString() === folder.id.toString()
      );
      setFolderItems(folderItems);
    };

    fetchFolderItems();
  }, [folder.id, items]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleClose = async () => {
    await db.items.update(folder.id, { title });
    const updatedItems = items.map((item) => (item.id === folder.id ? { ...item, title } : item));
    setItems(updatedItems);
    onClose();
  };

  const handleDelete = async () => {
    try {
      // First, check if there are any items in the folder
      const itemsInFolder = await db.items
        .where('folder_id')
        .equals(parseInt(folder.id))
        .count();
      
      if (itemsInFolder > 0) {
        setMessage("Remove all items before deleting folder");
        setShowMessage(true);
        return;
      }
      
      // Only if folder is empty, proceed with deletion
      await db.items.delete(folder.id);
      setItems(prevItems => prevItems.filter(item => item.id !== folder.id));
      onClose(); // Close folder first
      
      // Then show the transient message
      const transientMessage = document.createElement('div');
      transientMessage.style.position = 'fixed';
      transientMessage.style.top = '20px';
      transientMessage.style.left = '50%';
      transientMessage.style.transform = 'translateX(-50%)';
      transientMessage.style.zIndex = '9999';
      
      const messageContent = document.createElement('div');
      messageContent.textContent = "Folder deleted";
      messageContent.style.backgroundColor = '#333';
      messageContent.style.color = 'white';
      messageContent.style.padding = '10px 20px';
      messageContent.style.borderRadius = '4px';
      messageContent.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)';
      
      transientMessage.appendChild(messageContent);
      document.body.appendChild(transientMessage);
      
      setTimeout(() => {
        document.body.removeChild(transientMessage);
      }, 2000);
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  const handleDeleteItem = async (item) => {
    try {
      // Delete with sync
      await deleteItemWithSync(item.id, user);
      
      // Update the Recoil state to remove the item
      setItems(currentItems => currentItems.filter(i => i.id !== item.id));
      
      // Update local folder items state
      setFolderItems(currentFolderItems => currentFolderItems.filter(i => i.id !== item.id));
      
      // Show success message
      const transientMessage = document.createElement("div");
      transientMessage.className = "transient-message";
      const messageContent = document.createTextNode("Item deleted successfully");
      transientMessage.appendChild(messageContent);
      document.body.appendChild(transientMessage);
      
      setTimeout(() => {
        document.body.removeChild(transientMessage);
      }, 2000);
    } catch (error) {
      console.error("Error deleting item:", error);
      
      // Show error message
      const transientMessage = document.createElement("div");
      transientMessage.className = "transient-message error";
      const messageContent = document.createTextNode("Failed to delete item");
      transientMessage.appendChild(messageContent);
      document.body.appendChild(transientMessage);
      
      setTimeout(() => {
        document.body.removeChild(transientMessage);
      }, 2000);
    }
  };

  const renderFolderItems = () => {
    const placeholders = [];

    for (let y = 0; y < 4; y++) {
      for (let x = 0; x < 6; x++) {
        const item = folderItems.find((item) => item.gridX === x && item.gridY === y);
        if (item) {
          placeholders.push(
            <DraggableItem 
              key={item.id} 
              item={item} 
              onEdit={onEdit}
              isDeleteMode={isDeleteMode}
              onDelete={handleDeleteItem}
            />
          );
        } else {
          placeholders.push(
            <DroppableContainer
              key={`folder-placeholder-${x}-${y}`}
              id={`folder-placeholder-${x}-${y}`}
              className={`grid-item placeholder ${isDragging ? "dragging" : ""}`}
              gridX={x}
              gridY={y}
              gridId={"folder"}
              main_id={0}
              common_id={0}
              folder_id={folder.id}
              type="placeholder"
            >
              <PlaceholderDropZone
                gridX={x}
                gridY={y}
                gridId={"folder"}
                main_id={0}
                common_id={0}
                folder_id={folder.id}
                reloadItems={() => setItems(prev => [...prev])}
              />
            </DroppableContainer>
          );
        }
      }
    }

    return placeholders;
  };

  return (
    <div className="folder-frame">
      <div className="folder-container">
        {showMessage && (
          <TransientMessage
            message={message}
            onClose={() => setShowMessage(false)}
          />
        )}
        <div className="folder-header">
          <input
            className="folder-title-input"
            type="text"
            value={title}
            onChange={handleTitleChange}
            onBlur={handleClose}
            style={{ padding: "0px" }}
          />
          <button onClick={handleDelete} className="delete-icon-button">
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </button>
          <button className="btn-close" onClick={handleClose}>
            <img src={closeButton} alt="Close" />
          </button>
        </div>
        <div className="folder-grid">{renderFolderItems()}</div>
      </div>
    </div>
  );
};

export default Folder;
