import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db as firebaseDB } from "../../firebaseConfig";
import EditableTable from "./EditableTable";
import "../../css/ManageButtons.css";
import { initialItems } from "../InitializeData";
import db from "../db";
import { itemsState, widgetVisibilityState } from "../../state/state";
import { resolveConflicts } from "../../grid-components/DndProvider";

const ManageWidgets = () => {
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [items, setItems] = useRecoilState(itemsState);
  const [widgetVisibility, setWidgetVisibility] = useRecoilState(widgetVisibilityState);

  const fetchWidgets = async () => {
    try {
      // Get all widget items from IndexedDB
      const dbWidgets = await db.items
        .where('type')
        .equals('widget')
        .toArray();

      // Get widget visibility from IndexedDB
      const dbWidgetVisibility = {};
      for (const widget of dbWidgets) {
        dbWidgetVisibility[widget.id] = widget.widgetVisible || false;
      }

      // Update Recoil state with visibility from DB
      setWidgetVisibility(dbWidgetVisibility);

      // Map them to our display format
      const widgetItems = dbWidgets.map(widget => ({
        id: widget.id,
        title: widget.title || "",
        description: widget.description || "",
        visible: widget.visible ?? true,
        isOpen: widget.widgetVisible ?? false, // Use widgetVisible from DB
        state: widget.state || "active",
        type: "widget"
      }));

      setWidgets(widgetItems);
    } catch (error) {
      console.error("Error fetching widgets: ", error);
      setError("Error fetching widgets");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWidgets();
  }, []);

  const handleUpdateWidget = async (id, updatedWidget) => {
    try {
      const updates = {};
      
      // Handle grid icon visibility
      if ('visible' in updatedWidget) {
        updates.visible = updatedWidget.visible;

        // If we're showing the icon, validate its position
        if (updatedWidget.visible) {
          const currentWidget = items.find(item => item.id === id);
          if (currentWidget) {
            const tempItems = items.map(item => 
              item.id === id ? { ...item, visible: true } : item
            );

            const resolvedItems = resolveConflicts(tempItems);
            const resolvedWidget = resolvedItems.find(item => item.id === id);
            
            if (resolvedWidget) {
              if (resolvedWidget.gridX !== currentWidget.gridX || 
                  resolvedWidget.gridY !== currentWidget.gridY) {
                updates.gridX = resolvedWidget.gridX;
                updates.gridY = resolvedWidget.gridY;
              }
            }
          }
        }
      }

      // Handle widget window visibility
      if ('isOpen' in updatedWidget) {
        const newVisibility = updatedWidget.isOpen;
        updates.widgetVisible = newVisibility; // Store in IndexedDB
        
        // Update Recoil state
        setWidgetVisibility(prev => ({
          ...prev,
          [id]: newVisibility
        }));
      }

      // Update state if changed
      if ('state' in updatedWidget) {
        updates.state = updatedWidget.state;
      }

      // Update IndexedDB if we have updates
      if (Object.keys(updates).length > 0) {
        await db.items.update(id, updates);
        
        // Update Recoil items state
        setItems(currentItems => 
          currentItems.map(item => 
            item.id === id ? { ...item, ...updates } : item
          )
        );

        // Refresh the widgets list to show updated state
        await fetchWidgets();
      }
    } catch (error) {
      console.error("Error updating widget: ", error);
      setError("Error updating widget");
    }
  };

  const widgetColumns = [
    { field: "title", label: "Title", width: "100px", editable: true },
    { field: "description", label: "Description", width: "150px", editable: true },
    { field: "visible", label: "Show Icon", width: "40px", type: "checkbox", editable: true },
    { field: "isOpen", label: "Show Widget", width: "40px", type: "checkbox", editable: true },
  ];

  return (
    <div>
      <div className="flexRow">
        <h3>Server Widgets</h3>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="admin-edit-widget-table">
          <EditableTable
            data={widgets}
            columns={widgetColumns}
            onUpdate={handleUpdateWidget}
            showDelete={false}
          />
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default ManageWidgets;
