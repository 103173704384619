import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./firebaseConfig";
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore"; // Add getDoc here
import "./css/RegisterLogin.css";
import { updateOrCreateCustomer, sendWelcomeEmail } from "./grooveServce";

import googleIcon from "./icons-app/Google.svg"; // Add your Google icon path

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Function to create Firestore user document with default permissions
  const createFirestoreUserWithDefaults = async (user) => {
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      await setDoc(userDocRef, {
        email: user.email,
        role: "user",
        createdAt: new Date(),
        isWeatherWidgetInstalled: false,
        canAccessWeatherWidget: false,
        canAccessChatbot: false,
        isPayingClient: false // Default to false for new users
      });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const ticketNumber = 12345; // Temporary static ticket number for testing

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create Firestore user with default permissions
      await createFirestoreUserWithDefaults(user);

      // Example: Update Groove customer and send welcome email
      await updateOrCreateCustomer(user.email, "User Name", { custom: { role: "user" } });
      await sendWelcomeEmail(ticketNumber, user.email);

      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleRegister = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User registered with Google:", user);

      // Create Firestore user with default permissions if not already created
      await createFirestoreUserWithDefaults(user);

      // Example: Update Groove customer and send welcome email
      await updateOrCreateCustomer(user.email, user.displayName || "Google User", { custom: { role: "user" } });
      const ticketNumber = 12345; // You may want to generate this dynamically
      await sendWelcomeEmail(ticketNumber, user.email);

      navigate("/");
    } catch (error) {
      console.error("Google Sign-Up error:", error.message);
      setError(error.message);
    }
  };

  return (
    <div className="register-container">
      <h1>Register to get started!</h1>
      <form onSubmit={handleRegister}>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
        <button type="submit">Register</button>
        <div className="google-row">
          <img src={googleIcon} alt="Google Icon" className="googleIcon" />
          <button type="button" onClick={handleGoogleRegister}>
            Register with Google
          </button>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </form>
    </div>
  );
};

export default Register;
