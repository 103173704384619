// button item
import React, { useState, useRef, useCallback, useEffect } from "react";
import "../css/ButtonItem.css";
import "../css/TextBadge.css";

const ButtonItem = ({ item }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [fallbackIcon, setFallbackIcon] = useState(null);
  const [currentSrc, setCurrentSrc] = useState(null);
  const clickTimeout = useRef(null);
  const startPosition = useRef({ x: 0, y: 0 });
  const dragThreshold = 5;

  const handleInteractionStart = (x, y) => {
    clickTimeout.current = setTimeout(() => setIsDragging(true), 200);
    startPosition.current = { x, y };
  };

  const handleInteractionMove = (x, y) => {
    const distance = Math.sqrt(
      Math.pow(x - startPosition.current.x, 2) + Math.pow(y - startPosition.current.y, 2)
    );
    if (distance > dragThreshold) {
      clearTimeout(clickTimeout.current);
      setIsDragging(true);
    }
  };

  const handleInteractionEnd = () => {
    clearTimeout(clickTimeout.current);
    if (!isDragging && item.url) {
      // if (item.newTab) {
        window.open(item.url, "_blank", "noopener,noreferrer");
      //   console.log("Opening URL in a new tab:", item.url);
      // } else {
      //   // OPEN IN A NEW TAB
      //   window.open(item.url, "_blank", "noopener,noreferrer");
      //   window.location.href = item.url;
      //   console.log("Navigating to:", item.url, "in the same tab - button item");
      // }
    }
    setIsDragging(false);
  };

  const handleMouseDown = (e) => handleInteractionStart(e.clientX, e.clientY);
  const handleMouseMove = (e) => handleInteractionMove(e.clientX, e.clientY);
  const handleMouseUp = () => handleInteractionEnd();

  const handleTouchStart = (e) =>
    handleInteractionStart(e.touches[0].clientX, e.touches[0].clientY);
  const handleTouchMove = (e) => handleInteractionMove(e.touches[0].clientX, e.touches[0].clientY);
  const handleTouchEnd = () => handleInteractionEnd();

  const buttonStyle =
    item.iconOption === "backgroundColor" ? { backgroundColor: item.backgroundColor } :
    item.iconOption === "textBadge" ? {
      backgroundColor: item.backgroundColor
    } : {};

  const iconStyle = {
    display: (item.iconOption === "backgroundColor" || item.iconOption === "textBadge") ? "none" : "block",
    width: "60px",
    height: "60px",
    objectFit: "contain",
  };

  // Reset state when item changes
  useEffect(() => {
    setImageError(false);
    setFallbackIcon(null);
    setCurrentSrc(null);
  }, [item.icon]);

  // Handle image source changes
  useEffect(() => {
    const loadImageSource = () => {
      try {
        if (item.iconImage) {
          setCurrentSrc(item.iconImage);
          return;
        }
        if (!item.icon) {
          setCurrentSrc(require('../images/app-buttons/widget-icon.svg'));
          return;
        }
        if (item.icon.startsWith('data:') || item.icon.startsWith('blob:')) {
          setCurrentSrc(item.icon);
          return;
        }
        if (item.icon.startsWith('/static/media/')) {
          setCurrentSrc(item.icon);
          return;
        }
        setCurrentSrc(item.icon);
      } catch (error) {
        console.warn(`Failed to load image: ${item.icon}, using default icon`);
        setCurrentSrc(require('../images/app-buttons/widget-icon.svg'));
      }
    };

    loadImageSource();
  }, [item.icon, item.iconImage]);

  const handleImageError = useCallback(() => {
    console.warn(`Image failed to load: ${currentSrc}, using default icon`);
    setImageError(true);
    setFallbackIcon(require('../images/app-buttons/widget-icon.svg'));
  }, [currentSrc]);

  const imageSrc = !imageError
    ? item.iconImage || currentSrc || fallbackIcon
    : fallbackIcon || require('../images/app-buttons/widget-icon.svg');

  return (
    <div
      className="button-item"
      style={buttonStyle}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {item.iconOption === "textBadge" ? (
        <div className="text-badge-container">
          <span className="text-badge" style={{ color: item.iconColor || '#000000' }}>
            {item.icon}
          </span>
        </div>
      ) : (
        <img
          src={imageSrc}
          alt={item.title}
          style={iconStyle}
          className="button-image"
          onError={handleImageError}
        />
      )}
      <div className="button-title">{item.title}</div>
    </div>
  );
};

export default ButtonItem;
