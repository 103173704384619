// gridlayout.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import DndProvider from "./DndProvider";
import GridNavigation from "./GridNavigation";
import Folder from "./Folder";
import NavigationButtons from "./NavigationButtons";
import GridItems from "./GridItems";
import CommonGridItems from "./CommonGridItems";
import DroppableContainer from "./DroppableContainer";
import EditModal from "./EditModal/EditModal";
import SettingsPanel from "./SettingsPanel";
import EditButtons from "./EditButtons";
import AddItemForm from "./AddItemForm";
import WidgetContainer from "./WidgetContainer";
import nextSvg from "../icons-app/next.svg";
import prevSvg from "../icons-app/prev.svg";
import ScreenSizeDisplay from "./ScreenSizeDisplay";
import DropLinkArea from "./DropLinkArea";
import UploadGrids from "./UploadGrids";
import DropdownMenu from "./DropDownMenu";
import ReportIssueFormModal from "./ReportIssueFormModal";
import TransientMessage from "../components/TransientMessage";
import EditWidgetsModal from "./EditWidgetsModal";
import { SyncService } from "../services/SyncService";

import db, { deleteItemWithSync } from "../db/db";
import {
  itemsState,
  gridsState,
  gridIdState,
  reloadMainGridState,
  openFolderState,
  currentGridIdState,
  widgetVisibilityState,
} from "../state/state";
import { deleteMode } from "../state/appState";

import "../css/GridLayout.css";
import "../css/ResponsiveCSS.css";
import "../css/Folder.css";

const GridLayout = ({ user, isAdmin, handleLogout }) => {
  const [items, setItems] = useRecoilState(itemsState);
  const [widgetVisibility, setWidgetVisibility] = useRecoilState(widgetVisibilityState);
  const [isDeleteMode, setIsDeleteMode] = useRecoilState(deleteMode);
  const [showTransientMessage, setShowTransientMessage] = useState(null);
  const gridsLoadable = useRecoilValueLoadable(gridsState);
  const [currentGrid, setCurrentGrid] = useState(null);
  const [openFolder, setOpenFolder] = useRecoilState(openFolderState);
  const setGridId = useSetRecoilState(gridIdState);
  const setReloadMainGridState = useSetRecoilState(reloadMainGridState);
  const [, setCurrentGridId] = useRecoilState(currentGridIdState);
  const [initialGridId, setInitialGridId] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isEditButtonsModalOpen, setIsEditButtonsModalOpen] = useState(false);
  const [isEditWidgetsModalOpen, setIsEditWidgetsModalOpen] = useState(false);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [showScreenSizeDisplay, setShowScreenSizeDisplay] = useState(false);
  const location = useLocation();
  const [showReportIssueModal, setShowReportIssueModal] = useState(false);
  const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });

  // Define placeholder functions
  const toggleReportIssueModal = () => {
    setShowReportIssueModal((prev) => !prev);
  };

  const handleCreateItem = (newItem) => {
    setItems((prevItems) => [...prevItems, newItem]);
  };

  const handleAddItem = useCallback(() => {
    setShowAddItemForm(true);
  }, []);

  const handleCloseAddItem = useCallback(() => {
    setShowAddItemForm(false);
  }, []);

  const toggleScreenSizeDisplay = () => {
    setShowScreenSizeDisplay((prev) => !prev);
  };

  const toggleSettings = () => {
    setIsSettingsOpen((prev) => !prev);
    setIsEditButtonsModalOpen(false);
  };

  const toggleDragging = () => {
    setIsDragging((prev) => !prev);
  };

  const openEditButtonsModal = () => {
    setIsSettingsOpen(false);
    setIsEditButtonsModalOpen(true);
  };

  const openEditWidgetsModal = () => {
    setIsEditWidgetsModalOpen(true);
  };

  // Right-click handler
  const handleRightClick = (event) => {
    const isButton = event.target.tagName.toLowerCase() === "button"; // Check if the right-click is on a button
    const isModal = event.target.closest(".modal"); // Check if the right-click is on a modal

    if (isButton || isModal) {
      return; // If it's a button or modal, don't trigger the AddItemForm
    }

    event.preventDefault(); // Prevent the default context menu only when it's not a button or modal
    setRightClickPosition({ x: event.clientX, y: event.clientY });
    setShowAddItemForm(true); // Show the form only for desktop areas
  };

  useEffect(() => {
    // Add the right-click event listener
    document.addEventListener("contextmenu", handleRightClick);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("contextmenu", handleRightClick);
    };
  }, []);

  const handleSaveEdit = async (updatedItem) => {
    try {
      // Update in IndexedDB
      await db.items.put(updatedItem);
      
      // Force a re-render by creating a new array
      setItems(currentItems => {
        const newItems = currentItems.map(item => 
          item.id === updatedItem.id ? { ...updatedItem, key: Date.now() } : item
        );
        return [...newItems];
      });
      
      setEditingItem(null);
    } catch (error) {
      console.error("Error saving item:", error);
    }
  };

  const handleClose = () => {
    setEditingItem(null);
  };

  const handleIconClick = async (itemId) => {
    try {
      // Get the current item
      const item = await db.items.get(itemId);
      
      // Check if widget exists and is allowed to open
      if (!item) {
        console.error('Widget not found:', itemId);
        return;
      }

      // Get current visibility state
      const isCurrentlyVisible = widgetVisibility[itemId] || false;
      const newVisibility = !isCurrentlyVisible;

      // Update both IndexedDB and Recoil state
      await db.items.update(itemId, { widgetVisible: newVisibility });
      
      setWidgetVisibility(prev => ({
        ...prev,
        [itemId]: newVisibility
      }));

      console.log(`Widget ${itemId} visibility set to:`, newVisibility);
    } catch (error) {
      console.error('Error toggling widget visibility:', error);
    }
  };

  const handleDeleteItem = async (item) => {
    try {
      // Delete with sync
      await deleteItemWithSync(item.id, user);
      
      // Update local state
      const updatedItems = items.filter((i) => i.id !== item.id);
      setItems(updatedItems);
      
      // Clear editing state
      setEditingItem(null);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      const allItems = await db.items.toArray();
      const normalizedItems = allItems.map((item) => ({
        ...item,
        gridX: Number(item.gridX), // Ensure gridX is a number
        gridY: Number(item.gridY), // Ensure gridY is a number
      }));
      setItems(normalizedItems);
      const visibilityState = normalizedItems.reduce((acc, item) => {
        acc[item.id] = item.widgetVisible !== undefined ? item.widgetVisible : false;
        return acc;
      }, {});
      setWidgetVisibility(visibilityState);
    };

    fetchItems();
  }, [setItems, setWidgetVisibility]);

  useEffect(() => {
    if (user) {
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.type === "link" && item.text === "Register" ? { ...item, visible: false } : item
        )
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.type === "link" && item.text === "Register" ? { ...item, visible: true } : item
        )
      );
    }
  }, [user, setItems]);

  const reloadItems = useCallback(async () => {
    const allItems = await db.items.toArray();
    setItems(allItems);
  }, [setItems]);

  useEffect(() => {
    if (gridsLoadable.state === "hasValue" && gridsLoadable.contents.length > 0) {
      const initialGrid = gridsLoadable.contents[0];
      setCurrentGrid(initialGrid);
      setGridId(initialGrid.gridId.toString());
      setCurrentGridId(initialGrid.gridId.toString());
      setInitialGridId(initialGrid.gridId.toString());
    } else if (gridsLoadable.state === "hasError") {
      console.error("Error loading grids:", gridsLoadable.contents);
    }
  }, [gridsLoadable.state, gridsLoadable.contents, setGridId, setCurrentGridId]);

  const handleGridSelect = useCallback(
    (selectedGridId) => {
      const selectedGrid = gridsLoadable.contents.find(
        (grid) => grid.gridId.toString() === selectedGridId.toString()
      );
      setCurrentGrid(selectedGrid);
      setGridId(selectedGridId.toString());
      setReloadMainGridState((prev) => !prev); // Fixing reloadMainGrid variable name
      setCurrentGridId(selectedGridId.toString());
    },
    [gridsLoadable.contents, setGridId, setCurrentGridId]
  );

  const handleOpenFolder = (folder) => {
    setOpenFolder(folder);
  };

  const handleCloseFolder = () => {
    setOpenFolder(null);
  };

  const handleNextGrid = () => {
    if (currentGrid) {
      const currentIndex = gridsLoadable.contents.findIndex(
        (grid) => grid.gridId === currentGrid.gridId
      );
      if (currentIndex < gridsLoadable.contents.length - 1) {
        const nextGrid = gridsLoadable.contents[currentIndex + 1];
        setCurrentGrid(nextGrid);
        setGridId(nextGrid.gridId.toString());
        setCurrentGridId(nextGrid.gridId.toString());
      } else {
        const firstGrid = gridsLoadable.contents[0];
        setCurrentGrid(firstGrid);
        setGridId(firstGrid.gridId.toString());
        setCurrentGridId(firstGrid.gridId.toString());
      }
    }
  };

  const handlePrevGrid = () => {
    if (currentGrid) {
      const currentIndex = gridsLoadable.contents.findIndex(
        (grid) => grid.gridId === currentGrid.gridId
      );
      if (currentIndex > 0) {
        const previousGrid = gridsLoadable.contents[currentIndex - 1];
        setCurrentGrid(previousGrid);
        setGridId(previousGrid.gridId.toString());
        setCurrentGridId(previousGrid.gridId.toString());
      } else {
        const lastGrid = gridsLoadable.contents[gridsLoadable.contents.length - 1];
        setCurrentGrid(lastGrid);
        setGridId(lastGrid.gridId.toString());
        setCurrentGridId(lastGrid.gridId.toString());
      }
    }
  };

  const handleNextGridDragOver = () => {
    if (currentGrid) {
      const currentIndex = gridsLoadable.contents.findIndex(
        (grid) => grid.gridId === currentGrid.gridId
      );
      if (currentIndex < gridsLoadable.contents.length - 1) {
        const nextGrid = gridsLoadable.contents[currentIndex + 1];
        setCurrentGrid(nextGrid);
        setGridId(nextGrid.gridId.toString());
        setCurrentGridId(nextGrid.gridId.toString());
      }
    }
  };

  const handlePreviousGridDragOver = () => {
    if (currentGrid) {
      const currentIndex = gridsLoadable.contents.findIndex(
        (grid) => grid.gridId === currentGrid.gridId
      );
      if (currentIndex > 0) {
        const previousGrid = gridsLoadable.contents[currentIndex - 1];
        setCurrentGrid(previousGrid);
        setGridId(previousGrid.gridId.toString());
        setCurrentGridId(previousGrid.gridId.toString());
      }
    }
  };

  const handleEdit = useCallback((item) => {
    setEditingItem(item);
  }, [setEditingItem]);

  const handleShowMessage = (message) => {
    console.log("handleShowMessage called with:", message);
    setShowTransientMessage({ id: Date.now(), message });
    console.log("showTransientMessage set to:", message);
    setTimeout(() => {
      console.log("Clearing message");
      setShowTransientMessage(null);
    }, 3000);
  };

  useEffect(() => {
    console.log("showTransientMessage changed to:", showTransientMessage);
  }, [showTransientMessage]);

  // Add event listener for grid refresh
  useEffect(() => {
    const handleRefreshGridItems = async () => {
      const allItems = await db.items.toArray();
      const normalizedItems = allItems.map((item) => ({
        ...item,
        gridX: Number(item.gridX), // Ensure gridX is a number
        gridY: Number(item.gridY), // Ensure gridY is a number
      }));
      setItems(normalizedItems);
      const visibilityState = normalizedItems.reduce((acc, item) => {
        acc[item.id] = item.widgetVisible !== undefined ? item.widgetVisible : false;
        return acc;
      }, {});
      setWidgetVisibility(visibilityState);
    };

    window.addEventListener('refreshGridItems', handleRefreshGridItems);
    return () => window.removeEventListener('refreshGridItems', handleRefreshGridItems);
  }, [setItems, setWidgetVisibility]);

  return (
    <DndProvider
      items={items}
      setItems={setItems}
      handleNextGridDragOver={handleNextGridDragOver}
      handlePreviousGridDragOver={handlePreviousGridDragOver}
      setIsDragging={setIsDragging}
      handleIconClick={handleIconClick}
      user={user}
      onShowMessage={handleShowMessage}
    >
      {showTransientMessage && (
        <TransientMessage
          message={showTransientMessage.message}
          onClose={() => setShowTransientMessage(null)}
        />
      )}
      <DropLinkArea reloadItems={reloadItems} />
      <div className="grid-layout">
        <DropdownMenu
          currentPath={location.pathname}
          toggleReportIssueModal={toggleReportIssueModal}
          handleLogout={handleLogout}
          isAdmin={isAdmin}
          toggleSettings={toggleSettings}
          openEditButtonsModal={openEditButtonsModal}
          openEditWidgetsModal={openEditWidgetsModal}
          toggleScreenSizeDisplay={toggleScreenSizeDisplay}
          reloadItems={reloadItems}
          handleAddItem={handleAddItem}
          handleCloseAddItem={handleCloseAddItem}
          showAddItemForm={showAddItemForm}
        />
        
        {showReportIssueModal && <ReportIssueFormModal onClose={toggleReportIssueModal} />}
        {showAddItemForm && (
          <AddItemForm
            showForm={showAddItemForm}
            setShowForm={handleCloseAddItem}
            reloadItems={reloadItems}
            main_id={currentGrid?.gridId || 0}
            folder_id={openFolder?.id || 0}
            common_id={0}
          />
        )}
        <UploadGrids />
        {showScreenSizeDisplay && <ScreenSizeDisplay />}
        <button onClick={handlePrevGrid} className="move-prev-grid">
          <img src={prevSvg} alt="Previous" style={{ width: "30px", height: "30px" }} />
        </button>
        <button onClick={handleNextGrid} className="move-next-grid">
          <img src={nextSvg} alt="Next" style={{ width: "30px", height: "30px" }} />
        </button>
      </div>
      {initialGridId && (
        <GridNavigation
          grids={gridsLoadable.contents}
          initialGridId={initialGridId}
          onGridSelect={handleGridSelect}
        />
      )}
      <NavigationButtons />
      <div className="grid-manager-container">
        {openFolder && (
          <Folder
            folder={openFolder}
            onClose={handleCloseFolder}
            onEdit={handleEdit}
            isDragging={isDragging}
            onClick={handleIconClick}
          />
        )}
        {currentGrid && (
          <div className="main-grid">
            <DroppableContainer
              id={`main-${currentGrid.gridId}`}
              className={`main-grid-container ${isDragging ? "dragging" : ""}`}
              gridId={currentGrid.gridId.toString()}
            >
              <GridItems
                items={items}
                gridId={currentGrid.gridId.toString()}
                handleOpenFolder={handleOpenFolder}
                onEdit={handleEdit}
                isDragging={isDragging}
                handleIconClick={handleIconClick}
                showTransientMessage={handleShowMessage}
                user={user}
              />
            </DroppableContainer>
          </div>
        )}
        <div className="common-grid-frame">
          <DroppableContainer
            id="common"
            className={`common-grid-container ${isDragging ? "dragging" : ""}`}
            gridId="common"
          >
            <CommonGridItems
              items={items}
              handleOpenFolder={handleOpenFolder}
              isDragging={isDragging}
              handleEdit={handleEdit}
              handleIconClick={handleIconClick}
              isDeleteMode={isDeleteMode}
              handleDelete={handleDeleteItem}
              user={user}
            />
          </DroppableContainer>
        </div>
      </div>
      {editingItem && (
        <EditModal
          open={!!editingItem}
          handleClose={handleClose}
          item={editingItem}
          onSave={handleSaveEdit}
          onDelete={handleDeleteItem}
        />
      )}
      {isSettingsOpen && (
        <SettingsPanel
          onClose={toggleSettings}
          isDragging={isDragging}
          toggleDragging={toggleDragging}
        />
      )}

      {/* <AddItemForm showForm={showAddItemForm} setShowForm={setShowAddItemForm} /> */}
      {items.map(
        (item) =>
          item.type === "widget" &&
          widgetVisibility[item.id] && (
            <WidgetContainer key={item.id} item={item} handleCreateItem={handleCreateItem} />
          )
      )}
      {isEditButtonsModalOpen && (
        <EditButtons closeModal={() => setIsEditButtonsModalOpen(false)} onSave={reloadItems} />
      )}
      <EditWidgetsModal 
        isOpen={isEditWidgetsModalOpen} 
        onRequestClose={() => setIsEditWidgetsModalOpen(false)} 
      />
      <ReportIssueFormModal
        showModal={showReportIssueModal} // Log to track modal rendering
        onClose={toggleReportIssueModal}
      />
    </DndProvider>
  );
};

export default GridLayout;
// WORKING 080824
