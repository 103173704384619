// state.js
import { atom, selector } from "recoil";
import db from "../db/db";
import { getUserPreference } from "../db/db";
import { validateItemPosition } from '../utils/gridValidation';

export const currentGridIdState = atom({
  key: "currentGridIdState",
  default: 1
});

export const gridsState = atom({
  key: "gridsState",
  default: selector({
    key: "gridsState/default",
    get: async () => {
      const grids = await db.desktopGrids.toArray();
      return grids;
    }
  })
});

// Utility function to normalize item fields
const normalizeItemFields = (item) => {
  return {
    ...item,
    gridX: item.gridX !== undefined ? Number(item.gridX) : null,
    gridY: item.gridY !== undefined ? Number(item.gridY) : null,
    main_id: item.main_id !== undefined ? Number(item.main_id) : null,
    common_id: item.common_id !== undefined ? Number(item.common_id) : null,
    folder_id: item.folder_id !== undefined ? Number(item.folder_id) : null
  };
};

// Selector to get items from the database and normalize them
export const itemsState = atom({
  key: "itemsState",
  default: selector({
    key: "itemsState/default",
    get: async () => {
      const items = await db.items.toArray();
      const normalizedItems = items.map(normalizeItemFields);
      
      // Validate all items on initial load
      const validatedItems = normalizedItems.map(item => {
        try {
          const validatedItem = validateItemPosition(item, normalizedItems);
          if (JSON.stringify(validatedItem) !== JSON.stringify(item)) {
            // Update in IndexedDB if position changed
            db.items.update(item.id, validatedItem).catch(error => {
              console.error(`Error updating item ${item.id} in DB:`, error);
            });
          }
          return validatedItem;
        } catch (error) {
          console.error(`Initial validation error for item ${item.id}:`, error);
          return item;
        }
      });
      
      return validatedItems;
    }
  })
});

export const openFolderState = atom({
  key: "openFolderState",
  default: null
});

// Additional atom definitions as needed
export const gridItemsState = atom({
  key: "gridItemsState",
  default: []
});

export const activeDragItemState = atom({
  key: "activeDragItemState",
  default: null
});

export const currentFolderIdState = atom({
  key: "currentFolderIdState",
  default: null
});

export const buttonState = atom({
  key: "allButtons",
  default: []
});

export const folderState = atom({
  key: "allFolders",
  default: []
});

export const widgetState = atom({
  key: "allWidgets",
  default: []
});

export const folderContentsState = atom({
  key: "folderContents",
  default: []
});

export const showUseEffectMessagesState = atom({
  key: "showUseEffectMessages",
  default: false
});

export const overState = atom({
  key: "overState",
  default: null
});

export const activeItemTypeState = atom({
  key: "activeItemType",
  default: null
});

export const activeItemIdState = atom({
  key: "activeItemId",
  default: null
});

export const gridState = atom({
  key: "allGrids",
  default: []
});

export const commonGridItemsState = atom({
  key: "commonGridItems",
  default: []
});

export const gridIdState = atom({
  key: "gridIdState",
  default: 1
});

export const iconSizeSelectedState = atom({
  key: "iconSizeSelected",
  default: "Medium"
});

export const buttonTextHorizontalOffsetState = atom({
  key: "buttonTextHorizontalOffset",
  default: 72
});

export const folderTextHorizontalOffsetState = atom({
  key: "folderTextHorizontalOffset",
  default: 42
});

export const itemLabelSizeState = atom({
  key: "itemLabelSize",
  default: 1
});

export const commonGridPaddingBottomState = atom({
  key: "commonGridPaddingBottom",
  default: 35
});

export const highlightedGridState = atom({
  key: "highlightedGridState",
  default: false
});

export const setActiveDragItemState = atom({
  key: "setActiveDragItemState",
  default: null
});

export const gridSizeXState = atom({
  key: "gridSizeX",
  default: 9
});

export const gridSizeYState = atom({
  key: "gridSizeY",
  default: 5
});

export const reloadMainGridState = atom({
  key: "reloadMainGridState",
  default: 0
});

export const reloadCommonGridState = atom({
  key: "reloadCommonGridState",
  default: 0
});

export const reloadFolderGridState = atom({
  key: "reloadFolderGridsState",
  default: 0
});

export const activeWidgetDisplayState = atom({
  key: "activeWidgetDisplayState",
  default: {
    widgetId: null,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    visible: false
  }
});

export const overDataState = atom({
  key: "overDataState",
  default: null
});

export const isEditModeEnabledState = atom({
  key: "isEditModeEnabled",
  default: false
});

export const activeButtonDataState = atom({
  key: "activeButtonData",
  default: null
});

export const isItemDraggingState = atom({
  key: "isItemDragging",
  default: false
});

export const draggingItemState = atom({
  key: "draggingItem",
  default: null
});

export const draggingItemIdState = atom({
  key: "draggingItemId",
  default: null
});

export const defaultItemHeightState = atom({
  key: "defaultItemHeight",
  default: 70
});

export const defaultItemWidthState = atom({
  key: "defaultItemWidth",
  default: 70
});

export const defaultGridGapState = atom({
  key: "defaultGridGap",
  default: 20
});

export const defaultRowGapState = atom({
  key: "defaultRowGap",
  default: 25
});

export const defaultItemRadiusState = atom({
  key: "defaultItemRadius",
  default: 20
});

export const defaultPreviewItemHeightState = atom({
  key: "defaultPreviewItemHeight",
  default: 20
});

export const defaultPreviewItemWidthState = atom({
  key: "defaultPreviewItemWidth",
  default: 20
});

export const defaultItemTextSizeState = atom({
  key: "defaultItemTextSize",
  default: 16
});

export const calculatedGridRightState = atom({
  key: "calculatedGridRight",
  default: 800
});

export const calculatedGridHeightState = atom({
  key: "calculatedGridHeight",
  default: 500
});

export const defaultGridPaddingState = atom({
  key: "defaultGridPadding",
  default: 50
});

export const showSettingsState = atom({
  key: "showSettings",
  default: false
});

export const backgroundImageUrlState = atom({
  key: "backgroundImageUrl",
  default: "/static/media/bg-artboard-18.7e4b626297accfcc0981.jpg"
});

export const imageBlurState = atom({
  key: "imageBlur",
  default: 0
});

export const imageFadeState = atom({
  key: "imageFade",
  default: 0
});

export const activeWidgetState = atom({
  key: "activeWidget",
  default: ""
});

export const widgetsState = atom({
  key: "widgetsState",
  default: {}
});

export const showGridState = atom({
  key: "showGrid",
  default: false
});

export const gridBorderState = atom({
  key: "gridBorder",
  default: 0
});

export const gridContainerRefState = atom({
  key: "gridContainerRefState",
  default: { current: null }
});

export const goToNextGridState = atom({
  key: "goToNextGridState",
  default: false
});

export const goToPrevGridState = atom({
  key: "goToPrevGridState",
  default: false
});

export const effectPreferencesState = atom({
  key: "effectPreferencesState",
  default: {
    effect: "slide",
    duration: 750
  }
});

export const activeItemState = atom({
  key: "activeItemState",
  default: { id: null, type: null }
});

// Widget visibility state with persistence
export const widgetVisibilityState = atom({
  key: "widgetVisibilityState",
  default: selector({
    key: "widgetVisibilityState/default",
    get: async () => {
      try {
        // Get all widgets from IndexedDB
        const widgets = await db.items
          .where('type')
          .equals('widget')
          .toArray();

        // Create visibility map
        const visibilityMap = {};
        widgets.forEach(widget => {
          visibilityMap[widget.id] = widget.widgetVisible || false;
        });

        return visibilityMap;
      } catch (error) {
        console.error('Error loading widget visibility state:', error);
        return {};
      }
    }
  }),
  effects: [
    ({ onSet }) => {
      onSet(async (newValue) => {
        try {
          // Update each widget's visibility in IndexedDB
          const updates = Object.entries(newValue).map(([id, isVisible]) => 
            db.items.update(parseInt(id), { widgetVisible: isVisible })
          );
          await Promise.all(updates);
        } catch (error) {
          console.error('Error persisting widget visibility:', error);
        }
      });
    }
  ]
});

export const activeTabState = atom({
  key: "activeTabState",
  default: "background"
});

// const getInitialBackgroundSource = () => {
//   const savedSource = localStorage.getItem("backgroundSource");
//   return savedSource ? savedSource : "standard";
// };

export const backgroundSourceState = atom({
  key: "backgroundSourceState",
  default: "standard",
  effects_UNSTABLE: [
    ({ setSelf }) => {
      const loadPersisted = async () => {
        const persisted = await getUserPreference("backgroundSource");
        if (persisted) setSelf(persisted);
      };
      loadPersisted();
    }
  ]
});

export const imageTypeState = atom({
  key: "imageTypeState",
  default: "nature",
  effects_UNSTABLE: [
    ({ setSelf }) => {
      const loadPersisted = async () => {
        const persisted = await getUserPreference("imageType");
        if (persisted) setSelf(persisted);
      };
      loadPersisted();
    }
  ]
});

export const imageSizeState = atom({
  key: "imageSizeState",
  default: "cover"
});
