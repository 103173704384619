import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { useRecoilValue } from "recoil";
import { itemsState } from "../state/state";

const DroppableContainer = ({ id, children, className, gridX, gridY, gridId, main_id, common_id, folder_id, type, onDragOver, ...props }) => {
  const items = useRecoilValue(itemsState);
  
  // Check if there's a folder at this position
  const hasFolderAtPosition = items?.some(item => 
    item.type === "folder" && 
    item.gridX === gridX && 
    item.gridY === gridY &&
    item.main_id?.toString() === main_id?.toString() &&
    item.common_id?.toString() === "0" &&
    item.folder_id?.toString() === "0"
  );

  const { isOver, setNodeRef } = useDroppable({
    id,
    data: {
      id,
      gridX,
      gridY,
      gridId,
      main_id,
      common_id,
      folder_id,
      type
    },
    disabled: hasFolderAtPosition && type !== "folder", // Disable dropping if there's a folder (unless we're dropping into the folder)
    ...props
  });

  return (
    <div ref={setNodeRef} className={`${className} ${isOver ? "is-over" : ""}`} {...props}>
      {children}
    </div>
  );
};

export default DroppableContainer;
