import db from './db';

const updateTimeTrackingWidget = async () => {
  try {
    // Update the widget
    await db.items.where('id').equals(1736697326366).modify({
      title: "Time Tracking",
      description: "Track time for different clients",
      activationCode: "TimeTrackingWidget.js",
      iconCode: "AccessTimeIcon",
      widgetVisible: true,
      visible: true,
      state: "active"
    });
    
    console.log('Time Tracking widget updated successfully');
  } catch (error) {
    console.error('Error updating Time Tracking widget:', error);
  }
};

export default updateTimeTrackingWidget;
