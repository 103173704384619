import React, { useState } from "react";
import AddItemForm from "./AddItemForm";
import "../css/DropLinkArea.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const DropLinkArea = ({ reloadItems }) => {
  const [showAddItemForm, setShowAddItemForm] = useState(false); // Control AddItemForm visibility
  const [dropUrl, setDropUrl] = useState(""); // Store the dropped URL

  // const handleDragEnter = (e) => {
  //   e.preventDefault();
  //   e.currentTarget.classList.add('drag-over');
  // };
  
  // const handleDragLeave = (e) => {
  //   e.preventDefault();
  //   e.currentTarget.classList.remove('drag-over');
  // };

  // Handle the drop event
  const handleDrop = (e) => {
    e.preventDefault();
    const url = e.dataTransfer.getData("URL"); // Get the dropped URL

    if (url) {
      setDropUrl(url); // Store the URL in state
      setShowAddItemForm(true); // Open the AddItemForm
    } else {
      console.log("No URL found in the drop data.");
    }
  };

  return (
    <div className="drop-link-area" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
      <FontAwesomeIcon icon={faLink} className="menu-icon" />
      {/* Render AddItemForm when a URL is dropped */}
      {showAddItemForm && (
        <AddItemForm
          showForm={showAddItemForm}
          setShowForm={setShowAddItemForm}
          initialUrl={dropUrl}
          reloadItems={reloadItems}
        />
      )}
    </div>
  );
};

export default DropLinkArea;
