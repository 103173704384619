import React, { useState, useEffect } from 'react';
import '../css/TransientMessage.css';

const TransientMessage = ({ message, duration = 2000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!isVisible) return null;

  return (
    <div className="transient-message">
      {message}
    </div>
  );
};

export default TransientMessage;
