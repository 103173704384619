import React, { useEffect } from 'react';

const BuildInfo = () => {
  const buildVersion = process.env.REACT_APP_BUILD_VERSION || 'Development Build';
  
  useEffect(() => {
    console.log('Build Version:', process.env.REACT_APP_BUILD_VERSION);
    console.log('All env vars:', process.env);
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '8px',
        right: '8px',
        opacity: 0.7,
        fontSize: '14px',
        color: 'white',
        fontFamily: 'monospace',
        pointerEvents: 'none',
        zIndex: 9999,
        // backgroundColor: '#c9c9c9',        
        padding: '4px 8px',
        borderRadius: '4px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
      }}
    >
      {buildVersion}
    </div>
  );
};

export default BuildInfo;
