// EDIT MODAL COMPONENT
import React, { useState, useEffect, useRef } from "react";
import { Modal, Popover } from "@mui/material";
import { ChromePicker } from "react-color";
import "../../css/EditModal.css";
import log from "loglevel";
import closeButton from "../../images/app-buttons/close.png";
import { importAllImages } from "../../utils/importImages";
import { commonColors, colorNames } from "../../utils/commonColors";

const EditModal = ({ open, handleClose, item, onSave, onDelete }) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [iconOption, setIconOption] = useState("defaultIcon");
  const [uploadedIcon, setUploadedIcon] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [newTabUrl, setNewTabUrl] = useState(false);
  const [availableImages, setAvailableImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [matchingImages, setMatchingImages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isAdjusted, setIsAdjusted] = useState(false);
  const [badgeText, setBadgeText] = useState("");
  const [textColor, setTextColor] = useState("#000000");
  const [bgColorAnchor, setBgColorAnchor] = useState(null);
  const [textColorAnchor, setTextColorAnchor] = useState(null);

  // Add refs for all elements we need to measure
  const modalRef = useRef(null);
  const imageMatchesRef = useRef(null);
  const headerRef = useRef(null);
  const inputsRef = useRef(null);
  const buttonsRef = useRef(null);

  useEffect(() => {
    try {
      const images = importAllImages(
        require.context("../../images/new-button-images", false, /\.(webp|png|jpe?g|svg)$/)
      );
      console.log("Imported images:", images);
      setAvailableImages(
        images.map((img) => ({
          ...img,
          src: img.path, // Use the webpack path directly
        }))
      );
    } catch (error) {
      console.error("Error importing images:", error);
    }
  }, []);

  useEffect(() => {
    if (item && open) {
      setTitle(item.title);
      setUrl(item.url);
      setIconOption(item.iconOption || "defaultIcon");
      setBackgroundColor(item.backgroundColor || "#FFFFFF");
      setNewTabUrl(!!item.newTab);
      setImageData(item.icon || null);
      // Initialize badge text from title for text badges, or from existing badge text if it exists
      setBadgeText(item.iconOption === "textBadge" ? item.icon : (item.title || "").slice(0, 5));
      setTextColor(item.iconColor || "#000000");
    }
  }, [item, open, availableImages]);

  useEffect(() => {
    if (item?.icon && open) {
      const match = availableImages.find((img) => img.src === item.icon);
      if (match) {
        setSelectedImage(match);
      } else {
        setSelectedImage({ name: "Custom Icon", src: item.icon });
      }
    }
  }, [item?.icon, open, availableImages]);

  useEffect(() => {
    if (!searchText && open) {
      setMatchingImages(availableImages);
    } else {
      const matches = availableImages.filter((image) =>
        image.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setMatchingImages(matches);
    }
  }, [searchText, availableImages, open]);

  useEffect(() => {
    if (selectedImage) {
      setImageData(selectedImage.src);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (uploadedIcon) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(uploadedIcon);
    }
  }, [uploadedIcon]);

  const handleSave = () => {
    const updatedItem = {
      ...item,
      title,
      url,
      newTab: newTabUrl,
      icon:
        iconOption === "textBadge"
          ? badgeText
          : iconOption !== "backgroundColor"
          ? selectedImage?.src
          : null,
      iconImage: iconOption === "uploadedIcon" ? imageData : selectedImage?.src,
      iconOption,
      backgroundColor: iconOption === "backgroundColor" ? backgroundColor : backgroundColor,
      iconColor: iconOption === "textBadge" ? textColor : null,
    };

    console.log("Saving updated item:", updatedItem);
    onSave(updatedItem);
    handleClose();
  };

  // Dynamically adjust the height of the image-matches container
  useEffect(() => {
    const adjustHeight = () => {
      if (!open) return;

      requestAnimationFrame(() => {
        try {
          const modal = modalRef.current;
          const imageMatches = imageMatchesRef.current;
          const header = headerRef.current;
          const inputs = inputsRef.current;
          const buttons = buttonsRef.current;

          if (!modal || !imageMatches || !header || !inputs || !buttons) return;

          const modalHeight = modal.clientHeight;
          const headerHeight = header.clientHeight;
          const inputsHeight = inputs.clientHeight;
          const buttonsHeight = buttons.clientHeight;

          if (!modalHeight || !headerHeight || !inputsHeight || !buttonsHeight) return;

          const availableHeight = modalHeight - (headerHeight + inputsHeight + buttonsHeight + 30);
          if (availableHeight > 0) {
            imageMatches.style.height = `${availableHeight}px`;
          }
        } catch (error) {
          console.error("Error adjusting height:", error);
        }
      });
    };

    if (open) {
      const timeout = setTimeout(adjustHeight, 100);
      window.addEventListener("resize", adjustHeight);

      return () => {
        clearTimeout(timeout);
        window.removeEventListener("resize", adjustHeight);
      };
    }
  }, [open, iconOption]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="edit-modal" ref={modalRef}>
        <div className="app-header" ref={headerRef}>
          <h3 className="form-title">Edit Item</h3>
          <button className="btn-close" onClick={handleClose}>
            <img src={closeButton} alt="Close" />
          </button>
        </div>

        <div className="item-inputs" ref={inputsRef}>
          <div className="input-label">
            <p>Item Title</p>
            <input
              type="text"
              value={title}
              className="edit-input"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
            />
          </div>

          <div className="input-label">
            <p>Item Url</p>
            <input
              type="text"
              value={url}
              className="edit-input"
              onChange={(e) => setUrl(e.target.value)}
              placeholder="URL"
            />
          </div>

          <div className="input-label">
            <p>Icon Style</p>
            <select
              className="icon-select"
              value={iconOption}
              onChange={(e) => setIconOption(e.target.value)}
            >
              <option value="defaultIcon">Default Icon</option>
              <option value="backgroundColor">Color</option>
              <option value="textBadge">Text Badge</option>
              <option value="uploadedIcon">Upload...</option>
            </select>
          </div>

          {iconOption === "defaultIcon" && (
            <div className="input-label">
              <p>Search for Icons </p>
              <input
                type="text"
                value={searchText}
                className="edit-input"
                style={{ color: "red" }}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search icons"
              />
            </div>
          )}

          {iconOption === "defaultIcon" && selectedImage && (
            <div className="selected-icon">
              <p>Selected Icon </p>
              <img
                src={selectedImage.src}
                alt="Selected Icon"
                style={{ width: "50px", height: "50px" }}
              />
            </div>
          )}
        </div>

        {iconOption === "defaultIcon" && matchingImages.length > 0 && (
          <div className="matching-images-container">
            <div className="image-matches" ref={imageMatchesRef}>
              {matchingImages.map((image, index) => (
                <img
                  key={`${image.name}-${index}`}
                  src={image.src}
                  alt={image.name}
                  style={{
                    width: "50px",
                    height: "50px",
                    marginLeft: "5px",
                    border:
                      selectedImage && selectedImage.src === image.src ? "2px solid blue" : "none",
                  }}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
            </div>
          </div>
        )}

        {iconOption === "textBadge" && (
          <div
            className="form-group"
            style={{
              width: "100%",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="input-label"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <p>Badge Text</p>
              <input
                type="text"
                value={badgeText}
                className="edit-input"
                onChange={(e) => setBadgeText(e.target.value.slice(0, 5))}
                maxLength={5}
                placeholder="Badge"
                style={{ width: "80px", textAlign: "center" }}
              />
            </div>

            <div style={{ width: "100%", marginBottom: "20px" }}>
              <p style={{ textAlign: "center", marginBottom: "10px" }}>Background Color</p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "5px",
                  marginBottom: "10px",
                }}
              >
                {commonColors.map((color) => (
                  <div
                    key={color}
                    title={colorNames[color]}
                    onClick={() => setBackgroundColor(color)}
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: color,
                      border: backgroundColor === color ? "2px solid #000" : "1px solid #ccc",
                      borderRadius: "4px",
                      cursor: "pointer",
                      boxShadow: backgroundColor === color ? "0 0 5px rgba(0,0,0,0.5)" : "none",
                    }}
                  />
                ))}
                <div
                  title="Custom Color"
                  onClick={(e) => setBgColorAnchor(e.currentTarget)}
                  style={{
                    width: "25px",
                    height: "25px",
                    background:
                      "linear-gradient(45deg, #f00 0%, #ff0 20%, #0f0 40%, #0ff 60%, #00f 80%, #f0f 100%)",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontSize: "16px", color: "#fff", textShadow: "0 0 2px #000" }}>
                    +
                  </span>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <p style={{ textAlign: "center", marginBottom: "10px" }}>Text Color</p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "5px",
                  marginBottom: "10px",
                }}
              >
                {commonColors.map((color) => (
                  <div
                    key={color}
                    title={colorNames[color]}
                    onClick={() => setTextColor(color)}
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: color,
                      border: textColor === color ? "2px solid #000" : "1px solid #ccc",
                      borderRadius: "4px",
                      cursor: "pointer",
                      boxShadow: textColor === color ? "0 0 5px rgba(0,0,0,0.5)" : "none",
                    }}
                  />
                ))}
                <div
                  title="Custom Color"
                  onClick={(e) => setTextColorAnchor(e.currentTarget)}
                  style={{
                    width: "25px",
                    height: "25px",
                    background:
                      "linear-gradient(45deg, #f00 0%, #ff0 20%, #0f0 40%, #0ff 60%, #00f 80%, #f0f 100%)",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontSize: "16px", color: "#fff", textShadow: "0 0 2px #000" }}>
                    +
                  </span>
                </div>
              </div>
            </div>

            <Popover
              open={Boolean(bgColorAnchor)}
              anchorEl={bgColorAnchor}
              onClose={() => setBgColorAnchor(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ padding: "10px" }}>
                <ChromePicker
                  color={backgroundColor}
                  onChangeComplete={(color) => {
                    setBackgroundColor(color.hex);
                    setBgColorAnchor(null);
                  }}
                  disableAlpha={true}
                />
              </div>
            </Popover>

            <Popover
              open={Boolean(textColorAnchor)}
              anchorEl={textColorAnchor}
              onClose={() => setTextColorAnchor(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ padding: "10px" }}>
                <ChromePicker
                  color={textColor}
                  onChangeComplete={(color) => {
                    setTextColor(color.hex);
                    setTextColorAnchor(null);
                  }}
                  disableAlpha={true}
                />
              </div>
            </Popover>
          </div>
        )}

        {iconOption === "backgroundColor" && (
          <div className="form-group color-picker-container">
            <label htmlFor="backgroundColor">Background Color:</label>
            <ChromePicker
              color={backgroundColor}
              onChangeComplete={(color) => setBackgroundColor(color.hex)}
              disableAlpha={true}
            />
          </div>
        )}

        {iconOption === "uploadedIcon" && (
          <div className="form-group">
            <input type="file" onChange={(e) => setUploadedIcon(e.target.files[0])} />
            {uploadedIcon && (
              <img
                src={URL.createObjectURL(uploadedIcon)}
                alt="Icon preview"
                style={{ width: "50px", height: "50px" }}
              />
            )}
          </div>
        )}

        <div className="action-buttons-container" ref={buttonsRef}>
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
          <button className="del-button" onClick={() => onDelete(item)}>
            Delete this item!
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
