// NotLoggedInPopup.js
import React from "react";
import PropTypes from "prop-types";
import "../css/NotLoggedInPopup.css";

const NotLoggedInPopup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Not Logged In</h2>
        <p>You need to register and log in to report an issue.</p>
        {/* Buttons for register and login */}
        <div className="popup-buttons">
          <button onClick={() => window.location.href = '/register'}>
            Register
          </button>
          <button onClick={() => window.location.href = '/login'}>
            Login
          </button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

NotLoggedInPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NotLoggedInPopup;
