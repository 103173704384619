import React from "react";
import CommonGridList from "../db/admin/CommonGridList.js";
import ItemsList from "../db/admin/ItemsList.js";
// import { useRecoilState } from "recoil";
// import { uiState } from "../state/appState";
import "../css/SettingsPage.css";

const SettingsPage = ({ refreshData, setRefreshData, setTableDataUpdated, tableDataUpdated }) => {
  // const [ui, setUi] = useRecoilState(uiState);

  // const handleSetupGridItems = async (setupFunction) => {
  //   await setupFunction();
  //   setRefreshData((prev) => !prev);
  // };

  return (
    <div className="settings-page">
      {/* <div className="setup-buttons"> */}
      {/* <button onClick={() => handleSetupGridItems(setupCommonGridItems)}>Setup Common Grid Items</button> */}
      {/* <button onClick={() => handleSetupGridItems(setupFolderGridItems)}>Setup Folder Grid Items</button>
        <button onClick={() => handleSetupGridItems(setupMainGridItems)}>Setup Main Grid Items</button> */}
      {/* </div> */}
      <ItemsList refreshData={refreshData} setRefreshData={setRefreshData} setTableDataUpdated={setTableDataUpdated} tableDataUpdated={tableDataUpdated} />
      <CommonGridList />
      {/* <WidgetList /> */}
    </div>
  );
};

export default SettingsPage;
