import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { backgroundImageUrlState, imageBlurState, imageTypeState } from "../state/state";
import "../css/RandomImage.css"; // Import the CSS file

const RandomImage = () => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setBackgroundImageUrl] = useRecoilState(backgroundImageUrlState);
  const blur = useRecoilValue(imageBlurState);
  // const fade = useRecoilValue(imageFadeState);
  const imageType = useRecoilValue(imageTypeState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const fetchRandomImage = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.unsplash.com/photos/random", {
        headers: {
          Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
        },
        params: {
          query: imageType
        }
      });
      setImageData(response.data);
      setBackgroundImageUrl(response.data.urls.regular); // Update backgroundImageUrl in recoil state

      // Trigger a download event for Unsplash tracking
      await axios.get(response.data.links.download_location, {
        headers: {
          Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
        }
      });
    } catch (error) {
      console.error("Error fetching the image from Unsplash:", error);
    } finally {
      setLoading(false);
    }
  }, [imageType, setBackgroundImageUrl]);

  useEffect(() => {
    fetchRandomImage();
  }, [fetchRandomImage]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        imageData && (
          <>
            <div
              className="background-container"
              style={{
                backgroundImage: `url(${imageData.urls.regular})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: `blur(${blur}px)`, // Apply blur effect here
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                position: "fixed",
                zIndex: -1
              }}
            >
              <p className="attribution">
                Photo by{" "}
                <a href={`${imageData.user.links.html}?utm_source=your_app_name&utm_medium=referral`} target="_blank" rel="noopener noreferrer">
                  {imageData.user.name}
                </a>{" "}
                on{" "}
                <a href="https://unsplash.com?utm_source=your_app_name&utm_medium=referral" target="_blank" rel="noopener noreferrer">
                  Unsplash
                </a>
              </p>
            </div>
            {isDialogOpen && (
              <div className="dialog">
                <p>Do you want to save this image?</p>
                {/* <button onClick={handleSaveImage}>Yes</button> */}
                <button onClick={() => setIsDialogOpen(false)}>No</button>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};

export default RandomImage;
