import React, { createContext, useState, useEffect, useCallback, useContext } from "react";
import {
  getWeatherData,
  saveWeatherData,
  clearOldWeatherData,
  incrementApiAccessCount,
} from "../db/db";
import db from "../db/db";

const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;
const WeatherContext = createContext();

export const WeatherProvider = ({ children }) => {
  const [weatherData, setWeatherData] = useState({});
  const [forecastData, setForecastData] = useState([]);
  const [city, setCityState] = useState("Ottawa");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiAccessCount, setApiAccessCount] = useState(0);

  const setCity = (newCity) => {
    // console.log("setCity called with:", newCity);
    setCityState(newCity);
  };

  const fetchWeatherData = useCallback(async (searchCity, isRefresh = false) => {
    // console.log("Entered fetchWeatherData function.");
    // console.log("fetchWeatherData called with city:", searchCity, "isRefresh:", isRefresh);

    if (!searchCity) {
      setError("Please open the weather widget and enter a city.");
      // console.log("Error: City not provided.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      // console.log("Checking cache for city:", searchCity);
      const cachedData = await getWeatherData(searchCity);
      const now = new Date().getTime();
      const oneDay = 6 * 60 * 60 * 1000;

      if (cachedData && now - cachedData.timestamp < oneDay && !isRefresh) {
        // console.log("Using cached data for city:", searchCity);
        setWeatherData(cachedData.weatherData || {});
        setForecastData(cachedData.forecastData || []);
      } else {
        // console.log("Fetching new data from API for city:", searchCity);
        await incrementApiAccessCount();
        const weatherUrl = `https://api.openweathermap.org/data/2.5/weather?q=${searchCity}&units=metric&appid=${API_KEY}`;
        // console.log("Weather API URL:", weatherUrl);
        const weatherResponse = await fetch(weatherUrl);
        // console.log("Weather response status:", weatherResponse.status);

        if (!weatherResponse.ok) throw new Error("Failed to fetch weather data");
        const weatherData = await weatherResponse.json();
        // console.log("Weather data received:", weatherData);

        const forecastUrl = `https://api.openweathermap.org/data/2.5/onecall?lat=${weatherData.coord.lat}&lon=${weatherData.coord.lon}&exclude=minutely,hourly,alerts&units=metric&appid=${API_KEY}`;
        // console.log("Forecast API URL:", forecastUrl);
        const forecastResponse = await fetch(forecastUrl);
        // console.log("Forecast response status:", forecastResponse.status);
        const forecastData = await forecastResponse.json();
        // console.log("Forecast data received:", forecastData);

        setWeatherData(weatherData || {});
        setForecastData(forecastData.daily || []);

        await saveWeatherData({
          city: searchCity,
          weatherData: weatherData || {},
          forecastData: forecastData.daily || [],
          timestamp: now,
        });
        // console.log("Data saved to cache for city:", searchCity);
      }

      localStorage.setItem("lastCity", searchCity);
      // console.log("Last city set in localStorage:", searchCity);
    } catch (error) {
      setError(error.message);
      // console.error("Error fetching weather data:", error);
    } finally {
      setLoading(false);
      // console.log("Loading state set to false");
    }
  }, []);

  useEffect(() => {
    const fetchLastCityWeather = async () => {
      const lastCity = localStorage.getItem("lastCity");
      // console.log("Last city from localStorage:", lastCity);

      if (lastCity) {
        setCity(lastCity);
        await fetchWeatherData(lastCity);
      } else {
        await fetchWeatherData("Ottawa");
      }
    };

    const fetchApiAccessCount = async () => {
      try {
        const record = await db.apiAccessCount.get(1);
        // console.log("API access count record:", record);

        if (record) {
          setApiAccessCount(record.count);
        } else {
          await db.apiAccessCount.add({ id: 1, count: 0 });
          setApiAccessCount(0);
        }
      } catch (error) {
        // console.error("Failed to fetch API access count:", error);
      }
    };

    const fetchData = async () => {
      // console.log("Fetching last city weather and API access count...");
      await fetchLastCityWeather();
      await fetchApiAccessCount();
    };

    fetchData();
  }, [fetchWeatherData]);

  const handleRefresh = useCallback(
    (e) => {
      e?.preventDefault(); // Optional chaining in case `e` is undefined
      // console.log("Refreshing weather data for city:", city);
      clearOldWeatherData();
      fetchWeatherData(city, true); // Force refresh by passing true
    },
    [city, fetchWeatherData]
  );

  return (
    <WeatherContext.Provider
      value={{
        weatherData,
        forecastData,
        city,
        setCity,
        loading,
        error,
        apiAccessCount,
        fetchWeatherData,
        handleRefresh, // Include the handleRefresh function here
      }}
    >
      {children}
    </WeatherContext.Provider>
  );
};

export const useWeather = () => {
  const context = useContext(WeatherContext);
  if (!context) {
    throw new Error("useWeather must be used within a WeatherProvider");
  }
  return context;
};
