import React from "react";
import DroppableContainer from "./DroppableContainer";

const NavigationButtons = () => {
  return (
    <div className="navigation-buttons">
      <DroppableContainer id="previous-grid" className="prev-grid-button">
        {/* Prev */}
      </DroppableContainer>
      <DroppableContainer id="next-grid" className="next-grid-button">
        {/* Next */}
      </DroppableContainer>
    </div>
  );
};

export default NavigationButtons;
