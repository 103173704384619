import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { auth } from "../firebaseConfig";
import { userState } from "./appState";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { SyncService } from "../services/SyncService";

function AuthListener() {
  const setUser = useSetRecoilState(userState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Fetch user permissions from Firestore
          const userDoc = await getDoc(doc(db, "users", user.uid));
          const userData = userDoc.exists() ? userDoc.data() : {};
          
          // Create full user object
          const fullUser = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            isWeatherWidgetInstalled: userData.isWeatherWidgetInstalled || false,
            canAccessWeatherWidget: userData.canAccessWeatherWidget || false,
            canAccessChatbot: userData.canAccessChatbot || false,
            isPayingClient: userData.isPayingClient || false
          };
          
          // Set user state
          setUser(fullUser);
          
          // If user is a paying client, sync their data
          if (fullUser.isPayingClient) {
            try {
              await SyncService.syncOnLogin(fullUser);
            } catch (error) {
              console.error("Error syncing data on login:", error);
              // Don't throw - we still want the user to be logged in even if sync fails
            }
          }
        } catch (error) {
          console.error("Error fetching user permissions:", error);
          // Set basic user data if there's an error
          setUser({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName
          });
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [setUser]);

  return null;
}

export default AuthListener;
