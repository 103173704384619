import React from "react";

const DownloadTemplateButton = ({ fileName, fileUrl }) => {
  return (
    <a href={fileUrl} download={fileName} className="btn-download-template">
      Download the '{fileName}'
    </a>
  );
};

export default DownloadTemplateButton;
