import React, { useState } from "react";
import ReactDOM from "react-dom";
import AddItemForm from "./AddItemForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const PlaceholderDropZone = ({ gridX, gridY, gridId, main_id, common_id, folder_id, reloadItems }) => {
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [dropUrl, setDropUrl] = useState("");
  
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add('drag-over');
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    const url = e.dataTransfer.getData("URL");

    if (url) {
      setDropUrl(url);
      setShowAddItemForm(true);
    } else {
      console.log("No URL found in the drop data.");
    }
  };

  const handleAddItemFormClose = () => {
    setShowAddItemForm(false);
    setDropUrl("");
  };

  const handleClick = () => {
    setShowAddItemForm(true);
  };

  return (
    <>
      <div 
        className="grid-item placeholder"
        onDrop={handleDrop} 
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onClick={handleClick}
        data-gridx={gridX}
        data-gridy={gridY}
        style={{ width: "100%", height: "100%", cursor: "pointer" }}
      >
        {/* <FontAwesomeIcon icon={faLink} className="menu-icon" /> */}
      </div>
      {showAddItemForm && 
        ReactDOM.createPortal(
          <AddItemForm
            showForm={showAddItemForm}
            setShowForm={handleAddItemFormClose}
            initialUrl={dropUrl}
            reloadItems={reloadItems}
            initialGridX={gridX}
            initialGridY={gridY}
            main_id={main_id}
            folder_id={folder_id}
            common_id={common_id}
            gridId={gridId}
          />,
          document.body
        )
      }
    </>
  );
};

export default PlaceholderDropZone;
