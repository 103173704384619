import React from "react";
import Modal from "react-modal";
import WidgetList from "../db/firebaseAdmin/ManageWidgets";
import closeButton from "../images/app-buttons/close.png";
import "../css/EditWidgets.css";

const EditWidgetsModal = ({ isOpen, onRequestClose }) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} ariaHideApp={false} className="modal-content" overlayClassName="modal-overlay">
      <div onClick={stopPropagation} className="edit-widgets-modal">
        <div className="app-header">
          <h3>Edit Widget List</h3>
          <button onClick={onRequestClose} className="btn-close">
            <img src={closeButton} alt="Close" />
          </button>
        </div>
        <WidgetList />
      </div>
    </Modal>
  );
};

export default EditWidgetsModal;
