import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import { authState, profileState } from "../state/appState";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import "../css/ProfilePage.css"; // Ensure the CSS file is imported

const ProfilePage = () => {
  const [auth] = useRecoilState(authState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ displayName: "", email: "" });
  const [profileImage, setProfileImage] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (auth.user) {
        const userDocRef = doc(db, "users", auth.user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setProfile(userData);
          setFormData({ displayName: userData.displayName, email: userData.email });
          if (userData.profileImageUrl) {
            setProfileImage(userData.profileImageUrl);
          }
        }
      }
      setLoading(false);
    };
    fetchProfileData();
  }, [auth.user, setProfile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    if (auth.user) {
      const userDocRef = doc(db, "users", auth.user.uid);
      await updateDoc(userDocRef, formData);
      setProfile(formData);
      setIsEditing(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file && auth.user) {
      const storageRef = ref(storage, `profileImages/${auth.user.uid}`);
      setUploadingImage(true);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      await updateDoc(doc(db, "users", auth.user.uid), { profileImageUrl: imageUrl });
      setProfileImage(imageUrl);
      setUploadingImage(false);
    }
  };

  if (auth.loading || loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page">
      <h1>Profile Page</h1>
      <div className="profile-container">
        <div className="profile-image-container">
          <img src={profileImage || "default-profile.png"} alt="Profile" className="profile-image" />
          <input type="file" onChange={handleImageUpload} className="file-input" />
          {uploadingImage && <p>Uploading...</p>}
        </div>
        {isEditing ? (
          <div className="profile-edit">
            <label>
              Display Name:
              <input type="text" name="displayName" value={formData.displayName} onChange={handleInputChange} />
            </label>
            <label>
              Email:
              <input type="email" name="email" value={formData.email} onChange={handleInputChange} />
            </label>
            <button onClick={handleSave} className="btn-save">
              Save
            </button>
            <button onClick={() => setIsEditing(false)} className="btn-cancel">
              Cancel
            </button>
          </div>
        ) : (
          <div className="profile-view">
            <p>
              <strong>Display Name:</strong> {profile.displayName}
            </p>
            <p>
              <strong>Email:</strong> {profile.email}
            </p>
            <button onClick={() => setIsEditing(true)} className="btn-edit">
              Edit Profile
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
