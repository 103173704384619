import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

console.log("Initializing Firebase with the following config:", firebaseConfig);

let app;
let auth;
let db;
let storage;

try {
  app = initializeApp(firebaseConfig);
  console.log("Firebase App successfully initialized:", app);
} catch (error) {
  console.error("Error initializing Firebase App:", error);
}

try {
  auth = getAuth(app);
  console.log("Firebase Auth initialized:", auth);
} catch (error) {
  console.error("Error initializing Firebase Auth:", error);
}

try {
  db = getFirestore(app);
  console.log("Firestore initialized:", db);
} catch (error) {
  console.error("Error initializing Firestore:", error);
}

try {
  storage = getStorage(app);
  console.log("Firebase Storage initialized:", storage);
} catch (error) {
  console.error("Error initializing Firebase Storage:", error);
}

export { auth, db, storage };
