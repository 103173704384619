import React, { useState } from "react";
import { saveAs } from "file-saver";
import { initialItems } from "../InitializeData";
import "../../css/ItemTable.css";

const ItemTable = () => {
  const [items, setItems] = useState(initialItems.filter((item) => item.type !== "widget"));
  const [editingItemId, setEditingItemId] = useState(null);

  const handleFieldChange = (id, field, value) => {
    setItems(items.map((item) => (item.id === id ? { ...item, [field]: value } : item)));
  };

  const handleSaveEdit = (id) => {
    setEditingItemId(null);
  };

  const handleEdit = (id) => {
    setEditingItemId(id);
  };

  const handleDelete = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleAddItem = () => {
    const newItem = {
      id: items.length + 1,
      title: "",
      url: "",
      gridX: 0,
      gridY: 0,
      main_id: 0,
      folder_id: 0,
      common_id: 1,
      newTab: true,
      icon: "",
      iconFile: "",
      iconOption: "defaultIcon",
      backgroundColor: "#000000",
      visible: true,
      state: "active",
      type: "button",
    };
    setItems([...items, newItem]);
  };

  const handleSaveToFile = () => {
    const blob = new Blob([JSON.stringify(items, null, 2)], { type: "application/json" });
    saveAs(blob, "InitializeData.json");
  };

  return (
    <div className="expiremental">
      <div className="flexRow">
        <h2>Expirement to add items and create a json file - wip</h2>

        <button className="width150" onClick={handleAddItem}>
          Add Item
        </button>

        <button className="width150" onClick={handleSaveToFile}>
          Save to File
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>URL</th>
            <th>GridX</th>
            <th>GridY</th>
            <th>Main ID</th>
            <th>Folder ID</th>
            <th>Common ID</th>
            <th>New Tab</th>
            <th>Icon</th>
            <th>Background Color</th>
            <th>Visible</th>
            <th>State</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="text"
                    value={item.title}
                    onChange={(e) => handleFieldChange(item.id, "title", e.target.value)}
                  />
                ) : (
                  item.title
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="text"
                    value={item.description || ""}
                    onChange={(e) => handleFieldChange(item.id, "description", e.target.value)}
                  />
                ) : (
                  item.description
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="text"
                    value={item.url}
                    onChange={(e) => handleFieldChange(item.id, "url", e.target.value)}
                  />
                ) : (
                  item.url
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="number"
                    value={item.gridX}
                    onChange={(e) => handleFieldChange(item.id, "gridX", parseInt(e.target.value))}
                  />
                ) : (
                  item.gridX
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="number"
                    value={item.gridY}
                    onChange={(e) => handleFieldChange(item.id, "gridY", parseInt(e.target.value))}
                  />
                ) : (
                  item.gridY
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="number"
                    value={item.main_id}
                    onChange={(e) =>
                      handleFieldChange(item.id, "main_id", parseInt(e.target.value))
                    }
                  />
                ) : (
                  item.main_id
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="number"
                    value={item.folder_id}
                    onChange={(e) =>
                      handleFieldChange(item.id, "folder_id", parseInt(e.target.value))
                    }
                  />
                ) : (
                  item.folder_id
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="number"
                    value={item.common_id}
                    onChange={(e) =>
                      handleFieldChange(item.id, "common_id", parseInt(e.target.value))
                    }
                  />
                ) : (
                  item.common_id
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="checkbox"
                    checked={item.newTab}
                    onChange={(e) => handleFieldChange(item.id, "newTab", e.target.checked)}
                  />
                ) : item.newTab ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="text"
                    value={item.icon}
                    onChange={(e) => handleFieldChange(item.id, "icon", e.target.value)}
                  />
                ) : (
                  item.icon
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="text"
                    value={item.backgroundColor}
                    onChange={(e) => handleFieldChange(item.id, "backgroundColor", e.target.value)}
                  />
                ) : (
                  item.backgroundColor
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="checkbox"
                    checked={item.visible}
                    onChange={(e) => handleFieldChange(item.id, "visible", e.target.checked)}
                  />
                ) : item.visible ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </td>
              <td>
                {editingItemId === item.id ? (
                  <input
                    type="text"
                    value={item.state}
                    onChange={(e) => handleFieldChange(item.id, "state", e.target.value)}
                  />
                ) : (
                  item.state
                )}
              </td>
              <td style={{ width: "200px" }}>
                <div className="flexRow">
                  {editingItemId === item.id ? (
                    <button
                      className="button-1"
                      style={{ width: "70px" }}
                      onClick={() => handleSaveEdit(item.id)}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="button-1"
                      style={{ width: "70px" }}
                      onClick={() => handleEdit(item.id)}
                    >
                      Edit
                    </button>
                  )}
                  <button
                    className="button-1"
                    style={{ width: "80px" }}
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItemTable;
