// HomePage.js
import React from "react";
import { useRecoilState } from "recoil";
import { authState } from "../state/appState";
// import Chatbot from "../chatbot/Chatbot";
import "../css/HomePage.css";
import { testFindCustomer, testUpdateOrCreateCustomer } from "../grooveServce";

const HomePage = () => {
  const [auth] = useRecoilState(authState);
  const [browserType, setBrowserType] = React.useState("");
  const [osType, setOsType] = React.useState("");

  React.useEffect(() => {
    const getBrowserType = () => {
      const ua = navigator.userAgent;
      if (/chrome|crios|crmo/i.test(ua)) {
        return "Chrome";
      } else if (/firefox|iceweasel|fxios/i.test(ua)) {
        return "Firefox";
      } else if (/safari/i.test(ua) && !/chrome|crios|crmo/i.test(ua)) {
        return "Safari";
      } else if (/msie|trident/i.test(ua)) {
        return "Internet Explorer";
      } else if (/edg|edge/i.test(ua)) {
        return "Edge";
      } else {
        return "Unknown";
      }
    };

    const getOsType = () => {
      const ua = navigator.userAgent;
      if (/windows nt/i.test(ua)) {
        return "Windows";
      } else if (/macintosh|mac os x/i.test(ua)) {
        return "MacOS";
      } else if (/linux/i.test(ua)) {
        return "Linux";
      } else if (/android/i.test(ua)) {
        return "Android";
      } else if (/ios|iphone|ipad/i.test(ua)) {
        return "iOS";
      } else {
        return "Unknown";
      }
    };

    setBrowserType(getBrowserType());
    setOsType(getOsType());
  }, []);

  if (auth.loading) {
    return <div>Loading...</div>;
  }

  const handleTestGrooveService = async () => {
    console.log("Testing Groove Service...");
    await testFindCustomer(); // Test find customer function
    await testUpdateOrCreateCustomer(); // Test update or create customer function
  };

  return (
    <div className="home-page">
      <header className="home-header">
        <h1>Welcome to MyHomePage.ai</h1>
        {auth.user && (
          <div className="user-info">
            <p>Hello, {auth.user.displayName}</p>
            <img src={auth.user.photoURL} alt="User Profile" className="profile-pic" />
            <p>{auth.user.email}</p>
          </div>
        )}
      </header>
      <section className="home-content">
        <div className="section-card welcome-message">
          <h2>Welcome to our app!</h2>
          <p>Here you can manage your tasks, track your progress, and much more.</p>
        </div>
        <div className="section-card documentation">
          <h2>Documentation</h2>
          <section>
            <h3>Overview</h3>
            <p>This app can be used as your home page to improve your productivity. its beautiful background images are meant to inspire or calm you.</p>
            <p>The main grid is 9 X 5.</p>
            <p>You can add and customize new button items for your favorite and most used website links.</p>
            <p>There are four pages provided so you can store a lot of links.</p>
            <p>
              There is a common grid that's common to all pages so move your most used websites to that common area. There is a menu hidden in the top left
              corner to navigate to other parts of the app, open settings, add new button items, login, register, and logout.
            </p>
          </section>
          <section>
            <h3>Usage</h3>
            <p>Instructions on how to use the app...</p>
            <button onClick={handleTestGrooveService} style={{ margin: "20px 0" }}>
              Test Groove Service
            </button>
          </section>
        </div>
        <div className="section-card navigation-links">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <a href="/grid">Dashboard</a>
            </li>
            <li>
              <a href="/settings">Settings</a>
            </li>
            <li>
              <a href="/profile">Profile</a>
            </li>
          </ul>
        </div>
        <div className="section-card recent-activity">
          <h3>Recent Activity</h3>
          <p>No recent activity to show.</p>
        </div>
        <div className="section-card news-widget">
          <h3>Latest News</h3>
          <p>News item 1...</p>
          <p>News item 2...</p>
        </div>
        <div className="section-card set-homepage-instructions">
          <h3>Set as Homepage Instructions</h3>
          {browserType === "Chrome" && osType === "Windows" && (
            <ol>
              <li>Open the Chrome menu (three vertical dots in the upper-right corner).</li>
              <li>Select "Settings".</li>
              <li>Under "On startup", select "Open a specific page or set of pages".</li>
              <li>Click "Add a new page" and enter the URL of this app.</li>
              <li>Click "Add".</li>
            </ol>
          )}
          {browserType === "Firefox" && osType === "Windows" && (
            <ol>
              <li>Open the Firefox menu (three horizontal lines in the upper-right corner).</li>
              <li>Select "Options".</li>
              <li>Under "Home", select "Custom URLs" for your homepage and new windows.</li>
              <li>Enter the URL of this app.</li>
            </ol>
          )}
          {browserType === "Safari" && osType === "MacOS" && (
            <ol>
              <li>Open Safari.</li>
              <li>Click "Safari" in the menu bar and select "Preferences".</li>
              <li>Go to the "General" tab.</li>
              <li>In the "Homepage" field, enter the URL of this app.</li>
            </ol>
          )}
          {browserType === "Edge" && osType === "Windows" && (
            <ol>
              <li>Open the Edge menu (three horizontal dots in the upper-right corner).</li>
              <li>Select "Settings".</li>
              <li>Under "On startup", select "Open a specific page or pages".</li>
              <li>Click "Add a new page" and enter the URL of this app.</li>
              <li>Click "Add".</li>
            </ol>
          )}
          {(!["Chrome", "Firefox", "Safari", "Edge"].includes(browserType) || !["Windows", "MacOS"].includes(osType)) && (
            <p>Please refer to your browser's settings to set this app as your homepage.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
