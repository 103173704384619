import React, { useState } from "react";
import Papa from "papaparse";
import db from "../db/db";
import { useRecoilState } from "recoil";
import { buttonState } from "../state/state";
import "../css/UploadCSV.css";

function CSVUpload({ onUploadComplete }) {
  const [uploadStatus, setUploadStatus] = useState("");
  const [buttons, setButtons] = useRecoilState(buttonState);
  // const folders = useRecoilValue(folderState);
  // const widgets = useRecoilValue(widgetState);
  // const activeGridId = useRecoilValue(gridIdState);
  // const mainColumnWidth = useRecoilValue(gridSizeXState);
  // const mainRowHeight = useRecoilValue(gridSizeYState);

  // const findAvailablePosition = useCallback(() => {
  //   const occupied = new Set([
  //     ...folders.filter((f) => f.gridD === activeGridId).map((f) => `${f.gridX},${f.gridY}`),
  //     ...widgets.filter((w) => w.gridD === activeGridId).map((w) => `${w.gridX},${w.gridY}`),
  //     ...buttons.filter((b) => b.gridD === activeGridId).map((b) => `${b.gridX},${b.gridY}`)
  //   ]);

  //   for (let y = 0; y < mainRowHeight; y++) {
  //     for (let x = 0; x < mainColumnWidth; x++) {
  //       const key = `${x},${y}`;
  //       if (!occupied.has(key)) return { x, y };
  //     }
  //   }
  //   return null;
  // }, [activeGridId, buttons, folders, widgets, mainRowHeight, mainColumnWidth]);

  function handleFileChange(event) {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;
        let newButtons = [];

        for (const item of data) {
          if (!item.title || !item.url) {
            console.log("Skipping empty or incomplete record:", item);
            continue;
          }

          const existingButton = buttons.find((b) => b.id === item.id);

          const newButtonData = {
            ...existingButton,
            title: item.title,
            url: item.url,
            newTab: item.newTab === "true",
            gridX: Number(item.gridX),
            gridY: Number(item.gridY),
            main_id: item.main_id,
            folder_id: Number(item.folder_id),
            common_id: Number(item.common_id),
            iconOption: item.iconOption,
            icon: item.icon,
            iconFile: item.iconFile,
            iconColor: item.iconColor,
            backgroundColor: item.backgroundColor || generateRandomColor(),
            type: "button",
            visible: true
          };

          if (existingButton) {
            await db.items.update(existingButton.id, newButtonData);
          } else {
            const key = await db.items.add(newButtonData);
            newButtonData.id = key;
          }

          newButtons.push(newButtonData);
        }

        setButtons((prev) =>
          prev.map((button) => newButtons.find((nb) => nb.id === button.id) || button).concat(newButtons.filter((nb) => !prev.find((b) => b.id === nb.id)))
        );

        setUploadStatus(`Uploaded ${newButtons.length} buttons successfully.`);

        if (onUploadComplete) {
          onUploadComplete();
        }
      }
    });
  }

  function generateRandomColor() {
    return `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")}`;
  }

  return (
    <div id="csv-upload-widget">
      <input className="btn-file-input" type="file" accept=".csv" onChange={handleFileChange} id="file-upload" />
      <label className="btn-upload" htmlFor="file-upload">
        Upload CSV
      </label>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
}

export default CSVUpload;
