import React, { useState, useEffect } from "react";
import db from "../db";
import ItemsListButtons from "./ItemsListButtons";
import ItemsListWidgets from "./ItemsListWidgets";
import "../../css/ItemsList.css";

function ItemsList({ refreshData, setRefreshData, setTableDataUpdated, tableDataUpdated }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    loadItems();
  }, [refreshData]);

  const loadItems = async () => {
    const allItems = await db.items.toArray();
    setItems(allItems);
  };

  const handleAddItem = async () => {
    console.log("Adding new item...");
    try {
      await db.items.add({
        title: "",
        gridX: 0,
        gridY: 0,
        main_id: 1,
        url: "https://",
        newTab: true,
        iconOption: "",
        icon: "",
        iconFile: "",
        folder_id: 0,
        common_id: 0,
        visible: true,
        widgetVisible: true, // Ensure this field is included in the initial item
        state: "active",
        type: "button",
        backgroundColor: ""
      });
      loadItems();
    } catch (error) {
      console.error("Error adding new item:", error);
    }
  };

  const handleChange = async (itemId, field, value) => {
    console.log("Updating:", field, "with:", value, "for ID:", itemId);
    if (typeof itemId === "undefined" || itemId === null) {
      console.error("Invalid ID provided for update:", itemId);
      return;
    }

    // Check if the field is gridX, gridY, or main_id and convert value to a number
    const isGridField = ["gridX", "gridY", "main_id", "folder_id", "common_id"].includes(field);
    const updatedValue = isGridField ? Number(value) : value;

    try {
      await db.items.update(itemId, { [field]: updatedValue });
      const updatedItems = items.map((item) => (item.id === itemId ? { ...item, [field]: updatedValue } : item));
      setItems(updatedItems); // Update the state immediately after changing the value
    } catch (error) {
      console.error("Error updating record:", error);
    }
  };

  const handleDelete = async (id) => {
    await db.items.delete(id);
    loadItems(); // Reload items to reflect the deletion
  };

  return (
    <div>
      <div className="flexRow">
        <h4>Your button admin</h4>
        <button className="add-item-button" onClick={handleAddItem}>
          Add Button
        </button>
      </div>
      <h3>Client buttons</h3>
      <ItemsListButtons items={items.filter((item) => item.type === "button")} handleChange={handleChange} handleDelete={handleDelete} />
      <h3>Client buttons</h3>
      <ItemsListWidgets items={items.filter((item) => item.type === "widget")} handleChange={handleChange} handleDelete={handleDelete} />
    </div>
  );
}

export default ItemsList;
