import React, { useEffect, useState } from "react";
import { db } from "../../firebaseConfig"; // Ensure Timestamp is imported
import {
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";
import { useRecoilValue } from "recoil";
import { isAdminState } from "../../state/appState";
import "../../css/AdminIssueReports.css";

const AdminIssueReports = () => {
  const isAdmin = useRecoilValue(isAdminState);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newReply, setNewReply] = useState({}); // Store the new reply for each report

  const fetchReports = async () => {
    if (!isAdmin) return;
    setLoading(true);
    try {
      const q = query(collection(db, "issueReports"));
      const querySnapshot = await getDocs(q);
      const reportsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        showDetails: false, // Add showDetails field to track visibility
      }));
      setReports(reportsList);
    } catch (error) {
      console.error("Error fetching issue reports:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [isAdmin]);

  const handleDelete = async (id) => {
    try {
      const docRef = doc(db, "issueReports", id);
      await deleteDoc(docRef);
      setReports(reports.filter((report) => report.id !== id)); // Update state after delete
    } catch (error) {
      console.error("Error deleting issue report:", error);
    }
  };

  const handleManageClick = (id) => {
    // Toggle the showDetails field for the clicked report
    setReports((prevReports) =>
      prevReports.map((report) =>
        report.id === id
          ? { ...report, showDetails: !report.showDetails } // Toggle visibility
          : report
      )
    );
  };

  const handleReplySubmit = async (reportId) => {
    if (!newReply[reportId] || !newReply[reportId].trim()) return; // Ensure the reply is not empty

    try {
      const reportRef = doc(db, "issueReports", reportId);
      const newReplyData = {
        message: newReply[reportId],
        timestamp: Timestamp.now(), // Use Firestore's Timestamp to ensure consistency
        userType: "admin", // Mark as an admin reply
        userName: "Admin", // You can replace this with the actual admin's name or email if available
      };

      // Add the new reply to the "replies" array in Firestore
      await updateDoc(reportRef, {
        replies: arrayUnion(newReplyData),
      });

      // Update the local state to reflect the new reply
      setReports((prevReports) =>
        prevReports.map((report) =>
          report.id === reportId
            ? { ...report, replies: [...(report.replies || []), newReplyData] }
            : report
        )
      );

      // Clear the reply input after submission
      setNewReply((prevReplies) => ({ ...prevReplies, [reportId]: "" }));
    } catch (error) {
      console.error("Error submitting reply:", error);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "Unknown Date";
    if (timestamp instanceof Timestamp) {
      return new Date(timestamp.seconds * 1000).toLocaleString();
    } else if (timestamp instanceof Date) {
      return timestamp.toLocaleString();
    }
    return "Unknown Date";
  };

  const getLatestCustomerReply = (replies) => {
    if (!replies || replies.length === 0) return "No reply yet";

    // Filter replies to find only those from customers
    const customerReplies = replies.filter((reply) => reply.userType !== "admin");

    // If there are no customer replies, return "No reply yet"
    if (customerReplies.length === 0) return "No reply yet";

    // Get the latest customer reply based on the timestamp
    const latestReply = customerReplies.reduce((latest, reply) => {
      return new Date(reply.timestamp.seconds * 1000) > new Date(latest.timestamp.seconds * 1000)
        ? reply
        : latest;
    });

    return latestReply.message;
  };

  if (loading) return <p>Loading reports...</p>;
  if (reports.length === 0) return <p>No issue reports found.</p>;

  return (
    <div className="report-page">
      <div className="report-header">
        <h2>Issue Reports</h2>
        <button onClick={fetchReports} className="refresh-btn">
          Refresh
        </button>
      </div>
      <table className="report-table">
        <thead>
          <tr>
            <th style={{ width: "150px" }}>Reported At</th>
            <th style={{ width: "150px" }}>Reported By</th>
            <th>Description</th>
            <th>Latest Customer Reply</th>
            <th style={{ width: "100px" }}>Manage</th>
            <th style={{ width: "100px" }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report) => (
            <React.Fragment key={report.id}>
              <tr>
                <td style={{ width: "150px" }}>{formatDate(report.createdAt)}</td>
                <td style={{ width: "150px" }}>{report.userEmail || "Anonymous"}</td>
                <td>{report.description}</td>
                <td>{getLatestCustomerReply(report.replies)}</td>
                <td style={{ width: "100px" }}>
                  <button onClick={() => handleManageClick(report.id)} className="manage-btn">
                    {report.showDetails ? "Hide" : "Manage"}
                  </button>
                </td>
                <td style={{ width: "100px" }}>
                  <button onClick={() => handleDelete(report.id)} className="delete-btn">
                    Delete
                  </button>
                </td>
              </tr>
              {report.showDetails && (
                <tr>
                  <td colSpan={6}>
                    <div className="report-details">
                      <table className="reply-history-table">
                        <thead>
                          <tr>
                            <th className="header-date">Date</th>
                            <th>Reply History</th>
                            <th className="header-from">From</th>
                          </tr>
                        </thead>
                        <tbody>
                          {report.replies &&
                            report.replies
                              .sort(
                                (a, b) =>
                                  new Date(b.timestamp.seconds * 1000) -
                                  new Date(a.timestamp.seconds * 1000)
                              ) // Newest first
                              .map((reply, index) => (
                                <tr key={index}>
                                  <td>{formatDate(reply.timestamp)}</td>
                                  <td>{reply.message}</td>
                                  <td>{reply.userType === "admin" ? "Admin" : reply.userName}</td>
                                </tr>
                              ))}
                        </tbody>
                      </table>

                      {/* Admin Reply Section */}
                      <textarea
                        value={newReply[report.id] || ""}
                        onChange={(e) =>
                          setNewReply((prevReplies) => ({
                            ...prevReplies,
                            [report.id]: e.target.value,
                          }))
                        }
                        placeholder="Write a reply..."
                        rows={4}
                      />

                      <button
                        onClick={() => handleReplySubmit(report.id)}
                        className="submit-reply-btn"
                      >
                        Submit Reply
                      </button>

                      <hr />
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminIssueReports;
