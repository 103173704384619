// Common color palette for the application
export const commonColors = [
  // Grayscale
  "#000000", "#333333", "#666666", "#999999", "#CCCCCC", "#FFFFFF",
  
  // Primary Colors
  "#FF0000", "#00FF00", "#0000FF",
  
  // Secondary Colors
  "#FFFF00", "#FF00FF", "#00FFFF",
  
  // Material Design-inspired
  "#F44336", // Red
  "#E91E63", // Pink
  "#9C27B0", // Purple
  "#673AB7", // Deep Purple
  "#3F51B5", // Indigo
  "#2196F3", // Blue
  "#03A9F4", // Light Blue
  "#00BCD4", // Cyan
  "#009688", // Teal
  "#4CAF50", // Green
  "#8BC34A", // Light Green
  "#CDDC39", // Lime
  "#FFEB3B", // Yellow
  "#FFC107", // Amber
  "#FF9800", // Orange
  "#FF5722", // Deep Orange
  "#795548", // Brown
  "#9E9E9E", // Grey
  "#607D8B", // Blue Grey
];

// Color names for tooltips
export const colorNames = {
  "#000000": "Black",
  "#333333": "Dark Gray",
  "#666666": "Gray",
  "#999999": "Light Gray",
  "#CCCCCC": "Very Light Gray",
  "#FFFFFF": "White",
  "#FF0000": "Red",
  "#00FF00": "Green",
  "#0000FF": "Blue",
  "#FFFF00": "Yellow",
  "#FF00FF": "Magenta",
  "#00FFFF": "Cyan",
  "#F44336": "Material Red",
  "#E91E63": "Material Pink",
  "#9C27B0": "Material Purple",
  "#673AB7": "Material Deep Purple",
  "#3F51B5": "Material Indigo",
  "#2196F3": "Material Blue",
  "#03A9F4": "Material Light Blue",
  "#00BCD4": "Material Cyan",
  "#009688": "Material Teal",
  "#4CAF50": "Material Green",
  "#8BC34A": "Material Light Green",
  "#CDDC39": "Material Lime",
  "#FFEB3B": "Material Yellow",
  "#FFC107": "Material Amber",
  "#FF9800": "Material Orange",
  "#FF5722": "Material Deep Orange",
  "#795548": "Material Brown",
  "#9E9E9E": "Material Grey",
  "#607D8B": "Material Blue Grey",
};
