import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { currentGridIdState, gridsState as recoilGridsState } from "../state/state";
import db from "../db/db"; // Make sure to import the db module
import "../css/GridNavigation.css"; // Import the CSS file

const GridNavigation = ({ grids, initialGridId, onGridSelect }) => {
  const [currentGridId, setCurrentGridId] = useRecoilState(currentGridIdState);
  const [editGridId, setEditGridId] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [gridsState, setGridsState] = useRecoilState(recoilGridsState);

  // Helper function to find grid by title
  const findGridByTitle = (title) => {
    return grids.find(grid => grid.title.toLowerCase() === title.toLowerCase());
  };

  // Helper function to get grid title by ID
  const getGridTitleById = (id) => {
    const grid = grids.find(grid => grid.gridId === id);
    return grid ? grid.title : '';
  };

  useEffect(() => {
    // Check URL hash on component mount
    const hashValue = window.location.hash.slice(1); // Remove the # symbol
    
    if (hashValue) {
      // Try to find grid by title first
      const gridByTitle = findGridByTitle(hashValue);
      if (gridByTitle) {
        setCurrentGridId(gridByTitle.gridId);
        onGridSelect(gridByTitle.gridId);
      }
    } else if (initialGridId) {
      setCurrentGridId(initialGridId);
      onGridSelect(initialGridId);
      // Set hash for initial grid
      const initialTitle = getGridTitleById(initialGridId);
      if (initialTitle) {
        window.location.hash = initialTitle;
      }
    }

    // Listen for hash changes
    const handleHashChange = () => {
      const newHashValue = window.location.hash.slice(1);
      if (newHashValue) {
        const gridByTitle = findGridByTitle(newHashValue);
        if (gridByTitle) {
          setCurrentGridId(gridByTitle.gridId);
          onGridSelect(gridByTitle.gridId);
        }
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, [initialGridId, setCurrentGridId, onGridSelect, grids]);

  const handleRightClick = (event, gridId, title) => {
    event.preventDefault();
    // if (gridId && title !== "folder") {
    if (gridId && title !== "widget" && title !== "folder") {
      // Prevent editing if the item is a widget or folder
      setEditGridId(gridId);
      setEditTitle(title);
    }
  };

  const handleTitleChange = (event) => {
    setEditTitle(event.target.value);
  };

  const handleTitleSubmit = async (gridId) => {
    const updatedGrids = gridsState.map((grid) =>
      grid.gridId === gridId ? { ...grid, title: editTitle } : grid
    );

    setGridsState(updatedGrids);

    // Update the title in the database
    await db.desktopGrids.update(gridId, { title: editTitle });

    setEditGridId(null);
    setEditTitle("");
  };

  return (
    <div className="grid-navigation-container">
      <div className="grid-navigation-buttons">
        {grids.map((grid, index) => (
          <div key={`${grid.gridId}-${index}`}>
            {editGridId === grid.gridId ? (
              <input
                type="text"
                value={editTitle}
                onChange={handleTitleChange}
                onBlur={() => handleTitleSubmit(grid.gridId)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleTitleSubmit(grid.gridId);
                  }
                }}
                className="edit-input" // Apply the new class
                autoFocus
              />
            ) : (
              <button
                onClick={() => {
                  onGridSelect(grid.gridId);
                  setCurrentGridId(grid.gridId);
                  window.location.hash = grid.title;
                }}
                onContextMenu={(e) => handleRightClick(e, grid.gridId, grid.title)}
                className={`navbutton ${
                  grid.gridId.toString() === currentGridId.toString() ? "active" : "inactive"
                }`}
              >
                {grid.title}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridNavigation;
