import React, { useState, useEffect } from "react";
import db from "../db/db";
import CSVUpload from "./CSVUpload";
import CSVDownloadWidget from "./CSVDownloadWidget";
import DownloadTemplateButton from "./DownloadTemplateButton";
import "../css/EditButtons.css";
import closeButton from "../images/app-buttons/close.png";

const EditButtons = ({ closeModal, onSave }) => {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    const allItems = await db.items.toArray();
    const buttonItems = allItems.filter((item) => item.type === "button");
    setItems(buttonItems);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedItems = [...items].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setItems(sortedItems);
  };

  const handleAddItem = async () => {
    try {
      await db.items.add({
        title: "",
        gridX: 0,
        gridY: 0,
        main_id: 1,
        url: "https://",
        newTab: true,
        iconOption: "defaultIcon",
        icon: "",
        iconFile: "",
        folder_id: 0,
        common_id: 0,
        backgroundColor: "",
        type: "button",
        visible: true,
      });
      loadItems();
    } catch (error) {
      console.error("Error adding new item:", error);
    }
  };

  const handleChange = async (itemId, field, value) => {
    const isGridField = ["gridX", "gridY", "main_id", "folder_id", "common_id"].includes(field);
    const updatedValue = isGridField ? Number(value) : value;

    try {
      await db.items.update(itemId, { [field]: updatedValue });
      const updatedItems = items.map((item) =>
        item.id === itemId ? { ...item, [field]: updatedValue } : item
      );
      setItems(updatedItems);
      if (onSave) onSave(); // Call onSave to update parent components
    } catch (error) {
      console.error("Error updating record:", error);
    }
  };

  const handleFileUpload = async (itemId, file) => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        // Get the base64 data URL
        const fileUrl = e.target.result;
        
        // Validate that we have a proper data URL
        if (!fileUrl.startsWith('data:image/')) {
          throw new Error('Invalid image format');
        }

        // Store both the file name and the image data
        await db.items.update(itemId, { 
          iconFile: file.name,
          iconImage: fileUrl, // Store actual image data in iconImage
          iconOption: 'customIcon',
          icon: null // Clear old icon field to avoid confusion
        });

        // Update the local state
        const updatedItems = items.map((item) =>
          item.id === itemId ? { 
            ...item, 
            iconFile: file.name,
            iconImage: fileUrl,
            iconOption: 'customIcon',
            icon: null
          } : item
        );
        setItems(updatedItems);
        if (onSave) onSave(); // Call onSave to update parent components
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload image. Please try again with a different image.");
      }
    };

    // Read the file as a data URL (base64)
    reader.readAsDataURL(file);
  };

  const handleDelete = async (id) => {
    await db.items.delete(id);
    loadItems();
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedItems(items.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (e, id) => {
    if (e.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  return (
    <div className="edit-button-list">
      <div className="app-header">
        <h3>Edit Button List</h3>
        <button
          onClick={() => {
            onSave();
            closeModal();
          }}
          className="btn-close"
        >
          <img src={closeButton} alt="Close" />
        </button>
      </div>
      <div className="button-table-container">
        <div className="button-table-wrapper">
          
          <table>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                </th>
                <th onClick={() => handleSort("title")} className="sortable-header">
                  Title{" "}
                  {sortConfig.key === "title" ? (sortConfig.direction === "asc" ? "↑" : "↓") : "↑"}
                </th>
                <th onClick={() => handleSort("url")} className="sortable-header">
                  Url{" "}
                  {sortConfig.key === "url" ? (sortConfig.direction === "asc" ? "↑" : "↓") : "↑"}
                </th>
                <th onClick={() => handleSort("main_id")} className="sortable-header">
                  Main{" "}
                  {sortConfig.key === "main_id"
                    ? sortConfig.direction === "asc"
                      ? "↑"
                      : "↓"
                    : "↑"}
                </th>
                <th onClick={() => handleSort("folder_id")} className="sortable-header">
                  Folder{" "}
                  {sortConfig.key === "folder_id"
                    ? sortConfig.direction === "asc"
                      ? "↑"
                      : "↓"
                    : "↑"}
                </th>
                <th onClick={() => handleSort("common_id")} className="sortable-header">
                  Common{" "}
                  {sortConfig.key === "common_id"
                    ? sortConfig.direction === "asc"
                      ? "↑"
                      : "↓"
                    : "↑"}
                </th>
                <th onClick={() => handleSort("gridX")} className="sortable-header">
                  X{" "}
                  {sortConfig.key === "gridX" ? (sortConfig.direction === "asc" ? "↑" : "↓") : "↑"}
                </th>
                <th onClick={() => handleSort("gridY")} className="sortable-header">
                  Y{" "}
                  {sortConfig.key === "gridY" ? (sortConfig.direction === "asc" ? "↑" : "↓") : "↑"}
                </th>
                <th onClick={() => handleSort("icon")} className="sortable-header">
                  Icon File{" "}
                  {sortConfig.key === "icon" ? (sortConfig.direction === "asc" ? "↑" : "↓") : "↑"}
                </th>
                <th onClick={() => handleSort("iconOption")} className="sortable-header">
                  Icon Style{" "}
                  {sortConfig.key === "iconOption" ? (sortConfig.direction === "asc" ? "↑" : "↓") : "↑"}
                </th>
                <th>Badge Text</th>
                <th onClick={() => handleSort("backgroundColor")} className="sortable-header">
                  Background{" "}
                  {sortConfig.key === "backgroundColor"
                    ? sortConfig.direction === "asc"
                      ? "↑"
                      : "↓"
                    : "↑"}
                </th>
                <th>Text Color</th>
                {/* <th>NewTab</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      className="width30"
                      type="checkbox"
                      checked={selectedItems.includes(item.id)}
                      onChange={(e) => handleSelectItem(e, item.id)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="text-input width150"
                      value={item.title || ""}
                      onChange={(e) => handleChange(item.id, "title", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="text-input width150"
                      value={item.url || ""}
                      onChange={(e) => handleChange(item.id, "url", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="number-input"
                      value={item.main_id || 0}
                      onChange={(e) => handleChange(item.id, "main_id", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      className="number-input"
                      type="number"
                      value={item.folder_id || 0}
                      onChange={(e) => handleChange(item.id, "folder_id", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      className="number-input"
                      type="number"
                      value={item.common_id || 0}
                      onChange={(e) => handleChange(item.id, "common_id", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      className="number-input"
                      type="number"
                      value={item.gridX || 0}
                      onChange={(e) => handleChange(item.id, "gridX", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      className="number-input"
                      type="number"
                      value={item.gridY || 0}
                      onChange={(e) => handleChange(item.id, "gridY", e.target.value)}
                    />
                  </td>

                  <td>
                    <div className="custom-file-upload-container">
                    <label className="custom-file-upload">
                      Upload...
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileUpload(item.id, e.target.files[0])}
                      />
                    </label>
                    <input
                      type="text"
                      className="text-input width100"
                      value={item.iconFile || ""}
                      readOnly
                    /></div>
                  </td>
                  <td>
                    <select
                      className="text-input width150"
                      value={item.iconOption || "defaultIcon"}
                      onChange={(e) => handleChange(item.id, "iconOption", e.target.value)}
                    >
                      <option value="defaultIcon">Default Icon</option>
                      <option value="backgroundColor">Background Color</option>
                      <option value="textBadge">Text Badge</option>
                      <option value="uploadedIcon">Custom Icon</option>
                    </select>
                  </td>
                  <td>
                    {item.iconOption === "textBadge" && (
                      <input
                        type="text"
                        className="text-input width50"
                        value={item.icon || ""}
                        onChange={(e) => handleChange(item.id, "icon", e.target.value.slice(0, 3).toUpperCase())}
                        maxLength={3}
                        placeholder="ABC"
                      />
                    )}
                  </td>
                  <td>
                    <input
                      type="color"
                      className="text-input width50"
                      value={item.backgroundColor || "#FFFFFF"}
                      onChange={(e) => handleChange(item.id, "backgroundColor", e.target.value)}
                      style={{ padding: "0", height: "25px" }}
                    />
                  </td>
                  <td>
                    {item.iconOption === "textBadge" && (
                      <input
                        type="color"
                        className="text-input width50"
                        value={item.iconColor || "#000000"}
                        onChange={(e) => handleChange(item.id, "iconColor", e.target.value)}
                        style={{ padding: "0", height: "25px" }}
                      />
                    )}
                  </td>
                  {/* <td>
                    <select
                      value={item.newTab}
                      onChange={(e) => handleChange(item.id, "newTab", e.target.value === "true")}
                      className="text-input width80"
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </td> */}
                  <td>
                    <button onClick={() => handleDelete(item.id)} className="edit-button-delete">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
      </div>
      
      <div className="edit-buttons-footer">
        <div className="flexRow">
          Select all or individual buttons above to download them as a CSV file.
          <CSVDownloadWidget selectedButtons={selectedItems} buttons={items} />
          <button type="button" onClick={handleAddItem} className="edit-button-add">
            Add a new button
          </button>
        </div>
      </div>

      <div className="import-export-container">
        <div className="option-box">
          <h5>Upload buttons in a CSV file.</h5>
          <CSVUpload onUploadComplete={loadItems} />
        </div>
        <div className="option-box">
          <h5>Download a template CSV file.</h5>
          <DownloadTemplateButton
            fileName="buttons_template.csv"
            fileUrl={`${process.env.PUBLIC_URL}/csv-files/buttons_template.csv`}
          />
        </div>
      </div>
    </div>
  );
};

export default EditButtons;
