import React from "react";
import Switch from "./Switch";
import ScreenSizeDisplay from "./ScreenSizeDisplay";

const Settings = ({ isDragging, toggleDragging }) => {
  return (
    <div className="settings-content">
      <div className="setting-item group">
        <label>Show Placeholders:</label>
        <Switch isChecked={isDragging} onChange={toggleDragging} />
      </div>
      <ScreenSizeDisplay />
    </div>
  );
};

export default Settings;
