import React, { useState, useEffect } from "react";
import { useDraggable } from "@dnd-kit/core";
import db from "../db/db"; // Assuming you have a db module to fetch items
import ButtonItem from "./ButtonItem";
import FolderItem from "./FolderItem";
import DynamicIcon from "./DynamicIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const DraggableItem = ({ item, onOpenFolder, onEdit, onClick, isDeleteMode, onDelete, isDragging }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item.id,
    data: {
      ...item,
      type: item.type,
      folder_id: item.folder_id,
      main_id: item.main_id,
      common_id: item.common_id,
    }
  });

  const [folderItems, setFolderItems] = useState([]);
  
  useEffect(() => {
    const fetchFolderItems = async () => {
      if (item.type === "folder") {
        const allItems = await db.items.toArray();
        const itemsInFolder = allItems.filter((i) => {
          const isMatchingFolder = i.folder_id?.toString() === item.id.toString();
          const isRelevantType = ["button", "folder", "widget"].includes(i.type);
          const isVisible = i.visible !== false; // Show if visible is true or undefined
          return isMatchingFolder && isRelevantType && isVisible;
        });
        setFolderItems(itemsInFolder.slice(0, 6)); // Limit to 6 items
      }
    };

    fetchFolderItems();
  }, [item]);

  const style = {
    position: 'relative',
    cursor: isDragging ? "grabbing" : "grab",
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'translate3d(0, 0, 0)',
    zIndex: isDragging ? 1001 : item.type === "folder" ? 100 : 1,
    touchAction: 'none',
    // willChange: 'transform',
    // WebkitTransform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'translate3d(0, 0, 0)',
    opacity: 1,
    // transition: 'transform 0.15s ease-in'
  };

  const handleClick = () => {
    console.log("Item clicked:", item);
    if (item.type === "folder" && onOpenFolder) {
      onOpenFolder(item);
    } else if (item.type === "widget" && onClick) {
      onClick(item.id);
    } else if (item.type === "button") {
      console.log("Button clicked:", item.id);
      if (item.url) {
        // if (item.newTab) {
          // Open URL in a new tab with enhanced security
          const newWindow = window.open(item.url, "_blank", "noopener,noreferrer");
        //   if (newWindow) newWindow.opener = null; // Ensure security
        // } else {
        //   // Direct navigation in the same tab
        //   console.log("Navigating to:", item.url, "in the same tab - draggable item");
        //   window.location.href = item.url;
        // }
      }
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Always prevent default context menu
    event.stopPropagation(); // Always stop propagation
    
    if (item.type === "button") {
      onEdit(item); // Only open edit modal for buttons
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent triggering other click events
    if (onDelete) {
      onDelete(item);
    }
  };

  // Check visibility for all items
  if (!item.visible) {
    return null;
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`grid-item ${item.type === "folder" ? "folder-item" : ""}`}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
    >
      {isDeleteMode && (item.type === "button" || item.common_id?.toString() === "1") && (
        <div 
          className="item-delete-button"
          onClick={handleDelete}
          style={{
            position: "absolute",
            top: "-8px",
            right: "-8px",
            cursor: "pointer",
            zIndex: 10,
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "#ff4444" }} />
        </div>
      )}
      {item.type === "button" ? (
        <ButtonItem item={item} />
      ) : item.type === "folder" ? (
        <FolderItem item={item} folderItems={folderItems} />
      ) : item.type === "widget" ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <DynamicIcon iconCode={item.iconCode} />
          <div className="widget-item-title">{item.title}</div>
        </div>
      ) : (
        <div className="button-title">{item.title}</div>
      )}
    </div>
  );
};

export default DraggableItem;
