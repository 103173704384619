export const initialItems = [
  {
    id: 1,
    title: "Clock",
    description: "A clock widget",
    activationCode: "ClockWidget.js",
    iconCode: "ClockIcon",
    allgrids: true,
    icon: "",
    gridX: 0,
    gridY: 1,
    screenX: 100,
    screenY: 100,
    main_id: 4,
    common_id: 0,
    folder_id: 0,
    settings: {},
    type: "widget",
    visible: true,
    state: "active",
  },
  {
    id: 2,
    title: "Calendar",
    description: "A calendar widget",
    activationCode: "CalendarWidget.js",
    iconCode: "CalendarIcon",
    allgrids: true,
    icon: "",
    gridX: 1,
    gridY: 1,
    screenX: 120,
    screenY: 120,
    main_id: 4,
    folder_id: 0,
    common_id: 0,
    settings: {},
    type: "widget",
    visible: true,
    state: "active",
  },
  {
    id: 3,
    title: "Calculator",
    description: "A calculator widget",
    activationCode: "CalculatorWidget.js",
    iconCode: "CalculatorIcon",
    allgrids: true,
    icon: "",
    gridX: 2,
    gridY: 1,

    screenX: 140,
    screenY: 140,
    main_id: 4,
    folder_id: 0,
    common_id: 0,

    settings: {},
    type: "widget",
    visible: true,
    state: "active",
  },
  {
    id: 1736697326366,
    title: "Time Tracking",
    description: "Track time for different clients",
    activationCode: "TimeTrackingWidget.js",
    iconCode: "AccessTimeIcon",
    allgrids: true,
    icon: "",
    gridX: 2,
    gridY: 2,
    screenX: 150,
    screenY: 150,
    main_id: 4,
    folder_id: 0,
    common_id: 0,
    settings: {},
    type: "widget",
    visible: true,
    widgetVisible: true,
    state: "active",
  },
  {
    id: 7,
    title: "Bing Search",
    description: "A Bing search widget",
    activationCode: "BingSearchWidget.js",
    iconCode: "BingSearchIcon",
    allgrids: true,
    icon: "",
    gridX: 4,
    gridY: 1,
    screenX: 160,
    screenY: 160,
    main_id: 4,
    folder_id: 0,
    common_id: 0,
    settings: {},
    type: "widget",
    visible: true,
    state: "active",
  },
  {
    id: 6,
    title: "Quick Links",
    description: "Save and use your favorite links",
    activationCode: "QuickLinksWidget.js",
    iconCode: "QuickLinksIcon",
    allgrids: true,
    icon: "",
    gridX: 8,
    gridY: 3,
    screenX: "300px",
    screenY: "100px",
    main_id: 1,
    folder_id: 0,
    common_id: 0,
    settings: {},
    type: "widget",
    visible: true,
    state: "active",
  },
  {
    id: 4,
    title: "Google Search+",
    description: "A Google Plus Search widget",
    activationCode: "GoogleSearchPlusWidget.js",
    iconCode: "GoogleSearchPlusIcon",
    allgrids: true,
    icon: "",
    gridX: 5,
    gridY: 2,
    screenX: 100,
    screenY: 100,
    main_id: 4,
    folder_id: 0,
    common_id: 0,
    settings: {},
    type: "widget",
    visible: true,
    state: "active",
  },
  {
    id: 5,
    title: "Google Search",
    description: "A Google Search widget",
    activationCode: "GoogleSearchWidget.js",
    iconCode: "GoogleSearchIcon",
    allgrids: true,
    icon: "",
    gridX: 5,
    gridY: 1,
    screenX: 100,
    screenY: 100,
    main_id: 4,
    folder_id: 0,
    common_id: 0,
    settings: {},
    type: "widget",
    visible: true,
    state: "active",
  },
  {
    id: 10,
    title: "Google Mail",
    gridX: 3,
    gridY: 1,
    main_id: 0,
    folder_id: 0,
    common_id: 1,
    url: "https://mail.google.com/mail/u/0/#inbox",
    newTab: true,
    icon: "images/svg_icons/gmail.svg",
    iconFile: "",
    iconOption: "defaultIcon",
    svg: null,
    backgroundColor: "#000000",
    style: "default",
    visible: true,
    state: "active",
    type: "button",
  },
  {
    id: 11,
    title: "MyHomePage.ai",
    gridX: 8,
    gridY: 1,
    main_id: 1,
    folder_id: 0,
    common_id: 0,
    url: "https://myhomepage.ai",
    newTab: true,
    icon: "images/svg_icons/MyHome.png",
    iconFile: "",
    iconOption: "defaultIcon",
    svg: null,
    backgroundColor: "#000000",
    style: "default",
    visible: true,
    state: "active",
    type: "button",
    icon_code: null,
    icon_file_name: null,
  },
{  
    id: 31,
    title: "Documentation",
    gridX: 8,
    gridY: 2,
    main_id: 1,
    folder_id: 0,
    common_id: 0,
    url: "https://myhomepage.ai/documentation",
    newTab: true,
    icon: "images/svg_icons/mhp-docs.png",
    iconFile: "",
    iconOption: "defaultIcon",
    svg: null,
    backgroundColor: "#000000",
    style: "default",
    visible: true,
    state: "active",
    type: "button",
    icon_code: null,
    icon_file_name: null,
    allow_resize: null,
  },
  {
    id:13,
    title: "Startup Widget",
    description: "Add websites from predefined categories",
    activationCode: "StartupWidget.js",
    iconCode: "StartupIcon",
    allgrids: true,
    icon: "",
    gridX: 3,
    gridY: 3,
    main_id: 4,
    folder_id: 0,
    common_id: 0,
    settings: {},
    type: "widget",
    visible: true,
    state: "active"
  },
  {
    id: 32,
    title: "Icon8 - Icons",
    gridX: 8,
    gridY: 4,
    main_id: 1,
    folder_id: 0,
    common_id: 0,
    url: "https://icons8.com/",
    newTab: true,
    icon: "images/svg_icons/icon8.svg",
    iconFile: "",
    iconOption: "defaultIcon",
    svg: null,
    backgroundColor: "#000000",
    style: "default",
    visible: true,
    state: "active",
    type: "button",
    icon_code: null,
    icon_file_name: null,
    allow_resize: null,
  },
];

export const initialDesktopGrids = [
  {
    title: "Home",
    sizeX: 9,
    sizeY: 5,
    parent: 0,
    style: "default",
    visible: true,
    state: "active",
    settings: "{}",
    type: "primary",
  },
  {
    title: "Favorites",
    sizeX: 9,
    sizeY: 5,
    parent: 1,
    style: "compact",
    visible: true,
    state: "active",
    settings: "{}",
    type: "secondary",
  },
  {
    title: "Social",
    sizeX: 9,
    sizeY: 5,
    parent: 1,
    style: "workspace",
    visible: true,
    state: "active",
    settings: "{}",
    type: "workspace",
  },
  {
    title: "Widgets",
    sizeX: 9,
    sizeY: 5,
    parent: 1,
    style: "Widgets",
    visible: true,
    state: "active",
    settings: "{}",
    type: "Widgets",
  },
];

// common grids
export const initialCommonGrids = [
  {
    title: "Global",
    sizeX: 8,
    sizeY: 1,
    style: "compact",
    visible: true,
    state: "active",
    settings: "{}",
    type: "commonBar",
  },
];

export const initialEffects = [
  {
    name: "Fade",
    description: "A simple fade effect",
    cssClass: "fade-effect",
  },
  {
    name: "Zoom",
    description: "A zoom effect",
    cssClass: "zoom-effect",
  },
  {
    name: "Slide",
    description: "A sliding effect",
    cssClass: "slide-effect",
  },
];

export const initialWebsiteCategories = [
  {
    id: 1,
    name: "Social Media",
    order: 0,
    websites: [
      {
        title: "Facebook",
        url: "https://facebook.com",
        iconUrl: "https://www.facebook.com/favicon.ico"
      },
      {
        title: "Twitter",
        url: "https://twitter.com",
        iconUrl: "https://twitter.com/favicon.ico"
      }
    ]
  },
  {
    id: 2,
    name: "Productivity",
    order: 1,
    websites: [
      {
        title: "Gmail",
        url: "https://gmail.com",
        iconUrl: "https://www.google.com/gmail/about/static/images/favicon.ico"
      },
      {
        title: "Google Drive",
        url: "https://drive.google.com",
        iconUrl: "https://ssl.gstatic.com/docs/doclist/images/drive_2022q3_32dp.png"
      }
    ]
  },
  {
    id: 3,
    name: "Development",
    order: 2,
    websites: [
      {
        title: "GitHub",
        url: "https://github.com",
        iconUrl: "https://github.com/favicon.ico"
      },
      {
        title: "Stack Overflow",
        url: "https://stackoverflow.com",
        iconUrl: "https://cdn.sstatic.net/Sites/stackoverflow/Img/favicon.ico"
      }
    ]
  }
];
