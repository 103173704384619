import React, { useState, useEffect } from "react";
// import { useRecoilValue } from "recoil";
// import { isAdminState } from "../state/appState";
import "../css/ScreenSizeDisplay.css";

const ScreenSizeDisplay = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [deviceType, setDeviceType] = useState("");
  const [browserType, setBrowserType] = useState("");
  const [osType, setOsType] = useState("");
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  // const isAdmin = useRecoilValue(isAdminState);

  // Use the build timestamp or a default value
  console.log("Build Time:", process.env.REACT_APP_BUILD_TIME);
  const buildTimestamp = process.env.REACT_APP_BUILD_TIME || "Development Build";

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    const getDeviceType = () => {
      const ua = navigator.userAgent;
      if (/mobile/i.test(ua)) {
        return "Mobile";
      } else if (/tablet/i.test(ua)) {
        return "Tablet";
      } else {
        return "Desktop";
      }
    };

    const getBrowserType = () => {
      const ua = navigator.userAgent;
      if (/chrome|crios|crmo/i.test(ua)) {
        return "Chrome";
      } else if (/firefox|iceweasel|fxios/i.test(ua)) {
        return "Firefox";
      } else if (/safari/i.test(ua) && !/chrome|crios|crmo/i.test(ua)) {
        return "Safari";
      } else if (/msie|trident/i.test(ua)) {
        return "Internet Explorer";
      } else if (/edg|edge/i.test(ua)) {
        return "Edge";
      } else {
        return "Unknown";
      }
    };

    const getOsType = () => {
      const ua = navigator.userAgent;
      if (/android/i.test(ua)) {
        return "Android";
      } else if (/windows nt/i.test(ua)) {
        return "Windows";
      } else if (/macintosh|mac os x/i.test(ua)) {
        return "MacOS";
      } else if (/linux/i.test(ua) && !/android/i.test(ua)) {
        return "Linux";
      } else if (/ios|iphone|ipad/i.test(ua)) {
        return "iOS";
      } else {
        return "Unknown";
      }
    };

    setDeviceType(getDeviceType());
    setBrowserType(getBrowserType());
    setOsType(getOsType());

    window.addEventListener("resize", handleResize);

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true);
    });

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("beforeinstallprompt", (e) => {
        setDeferredPrompt(null);
      });
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);
      });
    }
  };

  return (
    <div className="about-you group">
      <p>Build Time: {buildTimestamp}</p>
      <p>Device: {deviceType} </p>
      <p>
        Window: {width} x {height}{" "}
      </p>
      <p>Browser: {browserType} </p>
      <p>OS: {osType}</p>
      <p>{showInstallButton && <button onClick={handleInstallClick}>Add to Home Screen</button>}</p>
    </div>
  );
};

export default ScreenSizeDisplay;
